import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import {
  AddLocationApiProps,
  UpdateLocationContainerApiProps,
} from "../../../../../types/apiType";

interface FormProps {
  closeForm: () => void;
  addData: (data: AddLocationApiProps) => void;
  isUpdate: boolean;
  dataUpdate: UpdateLocationContainerApiProps;
  updateData: (data: UpdateLocationContainerApiProps) => Promise<void>;
}

export const FormAdd = (props: FormProps) => {
  const formSchema = Yup.object().shape({
    rowContainerName: Yup.string().required("*Dữ liệu bắt buộc!"),
    pickUpDirection: Yup.string().required("*Dữ liệu bắt buộc!"),
    rowNumber: Yup.number()
      .required("*Dữ liệu bắt buộc!")
      .min(0, "Số cột phải lớn hơn 0!")
      .positive("Giá trị phải là số dương!"),
    tierNumber: Yup.number()
      .required("*Dữ liệu bắt buộc!")
      .min(0, "Số lớp phải lớn hơn 0!")
      .positive("Giá trị phải là số dương!"),
  });

  const formUpdateSchema = Yup.object().shape({
    rowContainerName: Yup.string().required("*Dữ liệu bắt buộc!"),
    pickUpDirection: Yup.string().required("*Dữ liệu bắt buộc!"),
  });

  const [rowContainerName, setRowContainerName] = useState("");
  const [pickUpDirection, setPickUpDirection] = useState("");
  const [rowNumber] = useState(0);
  const [tierNumber] = useState(0);

  // get props to index components
  const { closeForm, addData, isUpdate, dataUpdate, updateData } = props;

  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate
  useEffect(() => {
    focus();
    if (isUpdate) {
      if (dataUpdate) {
        if (dataUpdate.pickUpDirection !== undefined) {
          setPickUpDirection(dataUpdate.pickUpDirection);
        }
        if (dataUpdate.rowContainerName !== undefined) {
          setRowContainerName(dataUpdate.rowContainerName);
        }
      }
    }
  }, [dataUpdate]);
  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  const handleAddData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let addUser: AddLocationApiProps = {
      rowContainerName: formik.values.rowContainerName,
      pickUpDirection: formik.values.pickUpDirection,
      rowNumber: formik.values.rowNumber,
      tierNumber: formik.values.tierNumber,
    };
    addData(addUser);
  };

  const handleUpdateData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let dataUpdateNew: UpdateLocationContainerApiProps = {
      rowContainerName: formik.values.rowContainerName,
      pickUpDirection: formik.values.pickUpDirection,
      _id: dataUpdate._id,
    };
    // console.log("🚀 ~ FormAdd ~ dataUpdateNew:", dataUpdateNew);
    updateData(dataUpdateNew);
  };

  // check show button action
  const showButtonAction = () => {
    if (isUpdate) {
      return (
        <button
          type="submit"
          onClick={(e) => handleUpdateData(e)}
          className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Cập nhật
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          onClick={(e) => handleAddData(e)}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Lưu
        </button>
      );
    }
  };

  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rowContainerName,
      pickUpDirection,
      rowNumber,
      tierNumber,
    },
    validationSchema: isUpdate ? formUpdateSchema : formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">
          {isUpdate ? "Cập nhật vị trí" : "Thêm vị trí"}
        </p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>
        <form>
          <div className="flex flex-col w-full lg:mr-1 mr-0 mt-4 lg:mt-0 mb-6 lg:mb-8">
            <div className="relative z-0 group border border-gray-300 rounded-md ">
              <input
                type="text"
                name="floating_rowContainerName"
                id="floating_rowContainerName"
                className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={formik.values.rowContainerName}
                onChange={formik.handleChange("rowContainerName")}
                onBlur={formik.handleBlur("rowContainerName")}
                ref={inputRef}
              />
              <label
                htmlFor="floating_rowContainerName"
                className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
              >
                Tên hàng container <span className="text-red-500">*</span>
              </label>
            </div>
            <div className="text-red-400 mb-2">
              {formik.touched.rowContainerName &&
                formik.errors.rowContainerName}
            </div>
          </div>
          <div className="flex flex-col w-full lg:mr-1 mr-0 mt-4 lg:mt-0 mb-6 lg:mb-8">
            <div className="relative z-0 group border border-gray-300 rounded-md ">
              <input
                type="text"
                name="floating_pickUpDirection"
                id="floating_pickUpDirection"
                className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={formik.values.pickUpDirection}
                onChange={formik.handleChange("pickUpDirection")}
                onBlur={formik.handleBlur("pickUpDirection")}
                ref={inputRef}
              />
              <label
                htmlFor="floating_pickUpDirection"
                className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
              >
                Hướng lấy container <span className="text-red-500">*</span>
              </label>
            </div>
            <div className="text-red-400 mb-2">
              {formik.touched.pickUpDirection && formik.errors.pickUpDirection}
            </div>
          </div>

          {!isUpdate && (
            <>
              <div className="flex flex-col w-full lg:mr-1 mr-0 mt-4 lg:mt-0 mb-6 lg:mb-8">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="number"
                    name="floating_rowNumber"
                    id="floating_rowNumber"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.rowNumber}
                    onChange={formik.handleChange("rowNumber")}
                    onBlur={formik.handleBlur("rowNumber")}
                  />
                  <label
                    htmlFor="floating_rowNumber"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Số cột <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.rowNumber && formik.errors.rowNumber}
                </div>
              </div>

              <div className="flex flex-col w-full lg:mr-1 mr-0 mt-4 lg:mt-0 mb-6 lg:mb-8">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="number"
                    name="floating_tierNumber"
                    id="floating_tierNumber"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.tierNumber}
                    onChange={formik.handleChange("tierNumber")}
                    onBlur={formik.handleBlur("tierNumber")}
                  />
                  <label
                    htmlFor="floating_tierNumber"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Số lớp <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.tierNumber && formik.errors.tierNumber}
                </div>
              </div>
            </>
          )}

          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
