import React, { useState } from "react";
//icons
import { HiSearch } from "react-icons/hi";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
//types
import { ContainerApiParams, ContainerApiProps } from "../../../types/apiType";
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import { Reload } from "../../../components/Reload";
import { dataNumberOfLimit } from "../../../constants/dropdown";
//redux
import { useSelector } from "react-redux";
import {
  getContainerGateInAction,
  updateManyAction,
} from "../../../redux/slices/Container/containerSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
//libraries
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import { dateConvertHelper, dateHelper } from "../../../utils/DateHelper";
import { Socket } from "socket.io-client";
interface SearchProps {
  handleSearch: (
    keySearch: string,
    contStatus: number,
    numberOfLimit: number,
    startDate: string,
    endDate: string
  ) => void;
  socket: Socket;
}
export const Search = (props: SearchProps) => {
  const dispatch = useAppDispatch();
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const [keySearch, setKeySearch] = useState("");
  const [contStatus] = useState(5);
  const [numberOfLimit] = useState(5);

  const today = new Date();

  // Calculate the difference between today and the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffDays = today.getDay() - 1; // Assuming Monday is the first day of the week

  // Calculate the date range to cover the current week from Monday to today
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - diffDays);

  // Use the calculated start of the week in useState
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfWeek,
    today,
  ]);
  const [startDate, endDate] = dateRange;

  const { handleSearch, socket } = props;

  const handleUpdate = (msg: any) => {
    if (endDate && startDate !== null) {
      const params: ContainerApiParams = {
        keyword: "",
        offset: 0,
        limit: 5,
        contStatus: 5,
        startDate: dateConvertHelper(startDate),
        endDate: dateConvertHelper(endDate),
      };
      dispatch(getContainerGateInAction(params));
    }
  };

  useSocketEventHandler(socket, "update-receive", handleUpdate);

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contStatus,
      numberOfLimit,
    },
    onSubmit: () => {},
  });

  // search event
  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (endDate && startDate !== null) {
      handleSearch(
        keySearch.trim(),
        formik.values.contStatus,
        formik.values.numberOfLimit,
        dateConvertHelper(startDate),
        dateConvertHelper(endDate)
      );
    } else {
      handleSearch(
        keySearch.trim(),
        formik.values.contStatus,
        formik.values.numberOfLimit,
        dateHelper(today),
        dateHelper(today)
      );
    }
  };
  const handleOpenFormUpdateMany = async () => {
    dispatch(clearSelectionAction());
    const dataUpdateMany: ContainerApiProps = {
      containerIds: selected,
      contStatus: 2,
    };
    const action = await dispatch(updateManyAction(dataUpdateMany));

    if (updateManyAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, "Lỗi hệ thống");
    }
  };
  return (
    <div className="h-28 flex items-center w-full bg-slate-100 mt-3 overflow-hidden overflow-x-auto">
      <select
        id="ward"
        className="bg-white mx-4 block w-55 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        value={formik.values.numberOfLimit}
        onChange={formik.handleChange("numberOfLimit")}
        onBlur={formik.handleBlur("numberOfLimit")}
      >
        <option value={5}>{`5`}</option>
        {dataNumberOfLimit?.map((item, index) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <form className="">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Tìm
        </label>
        <div className="relative w-64 flex-shrink-0">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-64 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline "
            placeholder="Nhập số container ..."
            required
            onChange={(e) => setKeySearch(e.target.value)}
            value={keySearch}
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
            onClick={handleClickSearch}
          >
            Tìm
          </button>
        </div>
      </form>
      {/* <select
        id="ward"
        className="bg-white ml-4 block w-55 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        value={formik.values.contStatus}
        onChange={formik.handleChange("contStatus")}
        onBlur={formik.handleBlur("contStatus")}
      >
        <option value="5">{`-- Chọn trạng thái container --`}</option>
        {dataSearchContStatus?.map((item, index) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select> */}

      <div>
        <DatePicker
          dateFormat="dd-MM-yyyy"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          // isClearable={true}
          className=" bg-white ml-4 block w-52 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <Reload />
    </div>
  );
};
