import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllNotificationAction,
  getByIdAction,
  selectUser,
  updatePasswordAction,
  updateUserAction,
} from "../../redux/slices/User/UserSlice";
import { openForm, closeForm } from "../../redux/slices/formSlices";
import { Form } from "./Form";
import { Transition } from "@headlessui/react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { UserApiProps } from "../../types/apiType";
import ToastSuccess from "../../components/Toast/success";
import ToastError from "../../components/Toast/error";
import { FaKey, FaUserAlt } from "react-icons/fa";
import DateFormatter from "../../utils/DateFormatter";
import userImage from "../../img/user.png";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import LogoutConfirmation from "../../components/Logout/LogoutConfirmation";
import { Socket } from "socket.io-client";
import useSocketJoinRoom from "../../hooks/useSocketJoinRoom";
import { paramsNotification } from "../../utils/paramsHelper";
import useSocketEventHandler from "../../hooks/useSocketHandler";
interface SocketProps {
  socket: Socket;
}
export const ProfileUser = ({ socket }: SocketProps) => {
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = () => {
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  //redux
  const dispatch = useAppDispatch();
  const { id } = useParams();
  //History
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Trang cá nhân";
  const [isUpdate, setIsUpdate] = useState(false);

  const getData = () => {
    document.title = title;
    if (id !== undefined) {
      userAuth
        ? dispatch(getByIdAction(id))
        : Swal.fire({
            title: "Bạn cần đăng nhập để thực hiện thao tác này",
            showDenyButton: true,
            confirmButtonText: "Đăng nhập ngay",
            denyButtonText: `Hủy`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              navigate("/login");
            } else {
              navigate("/");
            }
          });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //get data from redux
  const user = useSelector(selectUser);
  const { serverError, profile, appError, userAuth } = user;

  const isLoginUser = userAuth?._id === profile?._id;

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (_id: string | undefined, data: UserApiProps) => {
    const dataUpdate = {
      _id,
      passwordCurrent: data.passwordCurrent,
      password: data.password,
    };
    setFormStatusState(false);

    const action = await dispatch(updatePasswordAction(dataUpdate));
    if (updatePasswordAction.fulfilled.match(action)) {
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // update data event
  const handleUpdateData = async (
    id: string | undefined,
    data: UserApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      id,
      userName: data.userName,
      currentName: data.currentName,
      password: data.password,
      role: data.role,
    };
    // console.log("dataUpdate", dataUpdate);
    const action = await dispatch(updateUserAction(dataUpdate));

    if (updateUserAction.fulfilled.match(action)) {
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };
  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          addData={handleAddData}
          updateData={handleUpdateData}
          profile={profile}
        />
      );
    }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  return (
    <>
      {isLoginUser && (
        <div className="bg-blue-100 h-screen ">
          <Transition
            show={formStatusState}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {displayForm()}
          </Transition>

          <div className="relative max-w-7xl mx-auto px-4">
            <div className="min-h-screen bg-blue-50 flex justify-center items-center">
              <div className="h-screen flex overflow-hidden bg-white w-full rounded-md border-2 border-slate-100">
                {/* Static sidebar for desktop */}

                <div className="flex flex-col min-w-0 flex-1 overflow-hidden ">
                  <div className="flex-1 relative z-0 flex overflow-hidden">
                    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                      <article>
                        {/* Profile header */}
                        <div>
                          <div>
                            <img
                              className="h-32 w-full object-cover lg:h-48"
                              src={userImage}
                              alt={""}
                            />
                          </div>
                          <div className="max-w-5xl mx-auto px-4 lg:px-6 xl:px-8">
                            <div className="-mt-12 lg:-mt-16 lg:flex lg:items-end lg:space-x-5">
                              <div className="flex -mt-20">
                                <img
                                  className="h-24 w-24 rounded-full ring-4 ring-white lg:h-32 lg:w-32"
                                  src={userImage}
                                  alt={""}
                                />
                              </div>
                              <div className="mt-6 lg:flex-1 lg:min-w-0 lg:flex lg:items-center lg:justify-end lg:space-x-6 lg:py-1">
                                <div className=" flex flex-col 2xl:block mt-10 min-w-0 flex-1">
                                  <h1 className="text-2xl font-bold text-gray-900 ">
                                    {profile?.currentName}
                                  </h1>
                                  <p className="text-sm">
                                    <time>
                                      Tham gia: {""}
                                      <DateFormatter
                                        date={profile?.createdAt}
                                      />
                                    </time>
                                  </p>
                                </div>

                                <div className="mt-9 flex flex-col justify-stretch space-y-3 lg:flex-row lg:space-y-0 lg:space-x-4">
                                  {/* Update Profile */}

                                  <div
                                    onClick={() => handleOpenFormUpdate()}
                                    className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                  >
                                    <FaUserAlt
                                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span>Cập nhật hồ sơ</span>
                                  </div>
                                </div>
                                <div className="mt-9 flex flex-col justify-stretch space-y-3 lg:flex-row lg:space-y-0 lg:space-x-4">
                                  {/* Update Profile */}

                                  <div
                                    onClick={() => handleOpenFormAdd()}
                                    className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                  >
                                    <FaKey
                                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span>Đổi mật khẩu</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="hidden lg:block 2xl:hidden mt-6 min-w-0 flex-1">
                              <h1 className="text-2xl font-bold text-gray-900 truncate">
                                {profile?.currentName}
                              </h1>
                            </div>
                          </div>
                        </div>
                        {/* Tabs */}
                        <div className="mt-6 lg:mt-2 2xl:mt-5">
                          <div className="border-b border-blue-900">
                            <div className="max-w-5xl mx-auto"></div>
                          </div>
                        </div>
                      </article>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
