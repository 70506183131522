import React from "react";
interface ListItemProps {
  numberStep: number;
  nameStep: string;
  data?: string | number;
  hightLight?: boolean;
}
const StepperComponent: React.FC<ListItemProps> = ({
  numberStep,
  nameStep,
  data,
  hightLight = false,
}) => {
  return (
    <li className="flex items-center text-black-600 space-x-2.5 rtl:space-x-reverse w-[200px]">
      {nameStep && numberStep && (
        <>
          <span className="flex items-center justify-center w-8 h-8 border border-black rounded-full shrink-0">
            {numberStep}
          </span>
          <span>
            <h3 className="font-medium leading-tight">{nameStep}</h3>
            <p
              className={`${
                hightLight ? "text-md text-red-500 font-extrabold" : "text-sm"
              } `}
            >
              {data == 0 ? data : !data ? "Chưa cập nhật" : data}
            </p>
          </span>
        </>
      )}
    </li>
  );
};

export default StepperComponent;
