import React, { useState } from "react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import {
  selectAllAction,
  selectSelects,
} from "../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import { useSelector } from "react-redux";
import { selectContainer } from "../../redux/slices/Container/containerSlice";
import { v4 as uuidv4 } from "uuid";
interface TableHeaderProps {
  titles: string[];
  isCheckbox?: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  titles,
  isCheckbox = true,
}) => {
  const dispatch = useAppDispatch();
  const containerData = useSelector(selectContainer);
  const selectData = useSelector(selectSelects);
  const { data } = containerData;
  const { selected } = selectData;
  const [lengthData, setLengthData] = useState(0);
  const handleSelectAll = () => {
    const dataListSelected = data
      .filter((item) => item.contStatus === 0)
      .map((item) => {
        return { _id: item._id };
      });
    setLengthData(dataListSelected.length);
    dispatch(selectAllAction(dataListSelected));
  };
  return (
    <tr>
      {isCheckbox && (
        <th
          key={uuidv4()}
          scope="col"
          className="px-6 py-3 whitespace-nowrap text-left text-xs font-bold text-gray-800 uppercase tracking-wider border border-slate-500"
        >
          <div className="flex items-center -ml-2">
            <input
              id="checkbox-all-select"
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
              // onClick={handleSelectAll}
              checked={
                selected.length === lengthData && selected.length > 0
                  ? true
                  : false
              }
              onChange={handleSelectAll}
            />
            <label htmlFor="checkbox-all-select" className="sr-only">
              checkbox
            </label>
          </div>
        </th>
      )}
      <th
        key={uuidv4()}
        scope="col"
        className="px-6 py-3 whitespace-nowrap text-left text-xs font-bold text-gray-800 uppercase tracking-wider border border-slate-500"
        // className="px-6 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        STT
      </th>
      {titles.map((title, index) => (
        <th
          key={index}
          scope="col"
          className="px-6 py-3 whitespace-nowrap text-left text-xs font-bold text-gray-800 uppercase tracking-wider border border-slate-500"
          // className="px-6 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          {title}
        </th>
      ))}
    </tr>
  );
};

export default TableHeader;
