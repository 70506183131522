import React from "react";
import DateFormatter from "../../../utils/DateFormatter";
import { FaUserAlt } from "react-icons/fa";
import { UserApiProps } from "../../../types/apiType";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import Swal from "sweetalert2";
import { deleteAction } from "../../../redux/slices/User/UserSlice";
import { BsTrash } from "react-icons/bs";
import { RoleHelper } from "../../../utils/StatusHelper";

// Define the props type for ListItem
interface ListItemProps {
  data: Array<UserApiProps> | null;
  openFormUpdate: (id: string) => void;
}

export const ListItem: React.FC<ListItemProps> = ({ data, openFormUpdate }) => {
  const dispatch = useAppDispatch();
  const handleOpenFormUpdate = (id: string | undefined) => {
    if (id !== undefined) {
      openFormUpdate(id);
    }
  };

  // delete data event
  const handleDelete = (id: string | undefined) => {
    Swal.fire({
      title: "Bạn có chắc muốn xóa dữ liệu này không?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed && id !== undefined) {
        const action = await dispatch(deleteAction(id));
        if (deleteAction.fulfilled.match(action)) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: action.payload.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            width: 500,
          });

          Toast.fire({
            icon: "error",
            title: action.payload as string,
          });
        }
      } else if (result.isDenied) {
        Swal.fire("Bạn vẫn chưa xóa!", "", "info");
      }
    });
  };

  return (
    <>
      {data?.map((item, index) => (
        <tr className="bg-gray-50" key={item._id}>
          <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {index + 1}
          </td>
          <td className="px-1 py-1 whitespace-nowrap border border-slate-500">
            <div className="flex items-center">
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900 ">
                  {item?.userName}
                </div>
              </div>
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.currentName}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {RoleHelper(item?.role)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {<DateFormatter date={item?.createdAt} />}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500 space-x-2">
            <div
              onClick={() => handleDelete(item?._id)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <BsTrash
                className="-ml-1 mr-2 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <span>Xóa tài khoản</span>
            </div>
            <div
              onClick={() => handleOpenFormUpdate(item._id)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <FaUserAlt
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span className="text-white">Cập nhật tài khoản</span>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
