import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { UserState } from "../../types/reduxTypes";
import LogoutConfirmation from "../../components/Logout/LogoutConfirmation";

interface AdminProtectRouteProps {
  user: UserState;
  children: React.ReactNode;
}

const AdminProtectRoute: React.FC<AdminProtectRouteProps> = ({
  user,
  children,
}) => {
  const { userAuth, appError } = user;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  if (userAuth?.role !== 0) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Chỉ có admin mới thực hiện thao tác này!"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  } else if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  } else if (userAuth) {
    return <>{children}</>;
  } else {
    return <div>Unauthorized</div>;
  }
};

export default AdminProtectRoute;
