import { useEffect, useState } from "react";
//libraries
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import "moment/locale/vi"; // Import the Vietnamese locale\
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
//types
import {
  ServiceSupplierApiProps,
  SupplierApiProps,
} from "../../../types/apiType";
//redux
import {
  clearInvoicesAction,
  selectInvoice,
} from "../../../redux/slices/Invoice/invoiceSlice";
import {
  getAllWithoutLimitAction as getAllSupplier,
  selectSupplier,
} from "../../../redux/slices/Supplier/SupplierSlice";
import {
  getAllWithoutLimitAction as getAllServiceSupplier,
  selectServiceSuppliers,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import { useSelector } from "react-redux";
//utils
import { dateHelper, dateTimeSearchHelper, plusDateHelper } from "../../../utils/DateHelper";
import {
  dataAnalyticHelper,
  dataSelectOptionAnalyticHelper,
} from "../../../utils/dataAnalyticHelper";
import {
  ExportContainerSoldByMonthFormatter,
  ExportContainerStorageFormatter,
} from "../../../utils/ExportDataFormatter";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//icons
//constants
//components
import ToastSuccess from "../../../components/Toast/success";
import {
  getAllAnalyticAdminAction,
  getAllContainerSoldByMonthWithoutLimitAction,
  getAllContainerStorageWithoutLimitAction,
  selectContainer,
} from "../../../redux/slices/Container/containerSlice";
import TableAnalyticCost from "../../../components/ContainerAnalytic/TableAnalyticCost";
import LabelComponent from "../../../components/LabelComponent";
import TableSoldByUser from "../../../components/ContainerAnalytic/TableSoldByUser";
import TableSoldBySupplier from "../../../components/ContainerAnalytic/TableSoldBySupplier";
import TableSoldByServiceSupplier from "../../../components/ContainerAnalytic/TableSoldByServiceSupplier";
import TableSoldByClient from "../../../components/ContainerAnalytic/TableSoldByClient";
import TableSoldBySize from "../../../components/ContainerAnalytic/TableSoldBySize";
import TableSoldByContainer from "../../../components/ContainerAnalytic/TableSoldByContainer";
import TableSoldByClientOnly from "../../../components/ContainerAnalytic/TableSoldByClientOnly";
import { Socket } from "socket.io-client";
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import { paramsNotification } from "../../../utils/paramsHelper";
import { getAllNotificationAction } from "../../../redux/slices/User/UserSlice";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import LoadingComponent from "../../../components/LoadingComponent";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);
interface SocketProps {
  socket: Socket;
}
const ContainerAnalyst = ({ socket }: SocketProps) => {
  //start setting socket
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  //end setting socket
  const dispatch = useAppDispatch();
  const containerData = useSelector(selectContainer);
  const invoiceData = useSelector(selectInvoice);
  const supplierData = useSelector(selectSupplier);
  const serviceSupplierData = useSelector(selectServiceSuppliers);
  const {
    totalContainer,
    // dataAnalytic: dataContainer,
    // soldByUser,
    // soldBySupplier,
    // restBySupplier,
    // soldByServiceSupplier,
    // restByServiceSupplier,
    // soldByClient,
    // soldBySizes,
    // restBySizes,
    // soldByContainer,
    // restByContainer,
    // soldByClientOnly,
    data: exportStorageContainer,
    dataSoldByMonth,
    loadingSoldContainer,
    loading,
  } = containerData;
  const { data } = invoiceData;
  const { data: dataSupplier } = supplierData;
  const { data: dataServiceSupplier } = serviceSupplierData;

  const [contQuantityOfMonth, setContQuantityOfMonth] = useState(0);
  const [totalInvoiceOfMonth, setTotalInvoiceOfMonth] = useState(0);
  const [totalPaymentStatusOfMonth, setTotalPaymentStatusOfMonth] = useState(0);
  const [supplierNotIncluded, setSupplierNotIncluded] =
    useState<Array<SupplierApiProps>>();
  const [serviceSupplierNotIncluded, setServiceSupplierNotIncluded] =
    useState<Array<ServiceSupplierApiProps>>();

  const currentDate = new Date(); // Get the current date
  currentDate.setDate(1); // Set the day to 1
  const [getByMonth, setGetByMonth] = useState<Date>(currentDate);

  const getQuarter = (date: Date): number => {
    const month = date.getMonth();
    return Math.floor(month / 3) + 1;
  };

  const [startQuarter, setStartQuarter] = useState<Date | null>(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [endQuarter, setEndQuarter] = useState<Date | null>(null);
  let title = "";

  if (startQuarter && endQuarter) {
    const startQuarterNumber = getQuarter(startQuarter);
    const endQuarterNumber = getQuarter(endQuarter);

    if (startQuarterNumber === endQuarterNumber) {
      title = `Thống kê container quý ${startQuarterNumber}`;
    } else {
      title = `Thống kê container từ quý ${startQuarterNumber} đến quý ${endQuarterNumber}`;
    }
  } else {
    title = `Thống kê container tháng ${dateHelper(getByMonth).charAt(
      5
    )}${dateHelper(getByMonth).charAt(6)}`;
  }

  //TODO: date range
  const today = new Date();

  // Calculate the difference between today and the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffDays = today.getDay() - 1; // Assuming Monday is the first day of the week

  // Calculate the date range to cover the current week from Monday to today
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - diffDays);

  // Use the calculated start of the week in useState
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfWeek,
    today,
  ]);
  const [startDate, endDate] = dateRange;
  //----------------------------------------------------------------

  useEffect(() => {
    document.title = title;
    const params = {
      keyword: "",
      offset: 0,
      limit: 100,
    };
    dispatch(getAllSupplier(params));
    dispatch(getAllServiceSupplier(params));
    dispatch(getAllNotificationAction(paramsNotification));
    dispatch(getAllContainerStorageWithoutLimitAction());
  }, []);

  const handleChange = ([newStartQuarter, newEndQuarter]: [
    Date | null,
    Date | null
  ]) => {
    setStartQuarter(newStartQuarter);
    setEndQuarter(newEndQuarter);
  };
  useEffect(() => {
    dispatch(clearInvoicesAction());
    if (startQuarter !== null && endQuarter !== null) {
      let toUpdateEndQuarter = new Date(dateHelper(endQuarter));
      toUpdateEndQuarter.setMonth(endQuarter.getMonth() + 2);
      dispatch(
        getAllAnalyticAdminAction({
          from: dateHelper(startQuarter),
          to: dateHelper(toUpdateEndQuarter),
        })
      );
      dispatch(
        getAllContainerSoldByMonthWithoutLimitAction({
          from: dateHelper(startQuarter),
          to: dateHelper(toUpdateEndQuarter),
        })
      );
    } else {
      // if (getByMonth !== null) {
      //   let toUpdate = new Date(dateHelper(getByMonth));
      //   toUpdate.setMonth(toUpdate.getMonth() + 1);
      //   dispatch(
      //     getAllAnalyticAdminAction({
      //       from: dateHelper(getByMonth),
      //       to: toUpdate,
      //       // to: dateHelper(getByMonth),
      //     })
      //   );
      //   dispatch(
      //     getAllContainerSoldByMonthWithoutLimitAction({
      //       from: dateHelper(getByMonth),
      //       to: toUpdate,
      //       // to: dateHelper(getByMonth),
      //     })
      //   );
      // }
      if (startDate !== null && endDate !== null) {
        dispatch(
          getAllAnalyticAdminAction({
            from: startDate,
            to: endDate,
          })
        );
        dispatch(
          getAllContainerSoldByMonthWithoutLimitAction({
            from: startDate,
            to: endDate,
          })
        );
      }
    }
  }, [dispatch, endQuarter, startQuarter, getByMonth, startDate, endDate]);

  useEffect(() => {
    const contQuantityAfterCount = dataAnalyticHelper(
      data,
      "size",
      "Số lượng container"
    ).datasets[0].data.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const totalInvoiceAfterCount = data.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.invoicePrice!),
      0
    );

    const totalPaymentStatusAfterCount = data.reduce(
      (accumulator, currentValue) =>
        currentValue.paymentStatus === 0 ? accumulator + 1 : accumulator + 0,
      0
    );

    setContQuantityOfMonth(contQuantityAfterCount);
    setTotalInvoiceOfMonth(totalInvoiceAfterCount);
    setTotalPaymentStatusOfMonth(totalPaymentStatusAfterCount);

    const filterSupplierNotIncludes = dataSupplier.filter(
      (item) =>
        !dataSelectOptionAnalyticHelper(
          data,
          "supplier",
          "label",
          "Hãng khai thác"
        ).labels.includes(item.supplierName)
    );
    setSupplierNotIncluded(filterSupplierNotIncludes);
    const filterServiceSupplierNotIncludes = dataServiceSupplier.filter(
      (item) =>
        !dataSelectOptionAnalyticHelper(
          data,
          "serviceSupplier",
          "label",
          "Vị trí container"
        ).labels.includes(item.serviceSupplierName)
    );
    setServiceSupplierNotIncluded(filterServiceSupplierNotIncludes);
  }, [data, dataServiceSupplier, dataSupplier]);

  return (
    <div className="bg-blue-100 h-full pb-20">
      <div className="bg-slate-50 mx-2 rounded-2xl p-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 overflow-hidden overflow-x-auto">
          <div className="h-16 flex items-center w-full ">
            <div className="flex flex-row ml-2 w-full">
              <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
              <div className="flex-none flex-shrink-0">
                <p className="font-sans font-semibold text-3xl">{title}</p>
                <p className="font-sans text-sm italic">{title} Conta</p>
              </div>
              <div className="flex flex-1 justify-between lg:mt-0 ml-4">
                <div className="flex lg:mt-0 ml-4">
                  <DatePicker
                    selected={startQuarter}
                    onChange={handleChange}
                    selectsRange
                    startDate={startQuarter}
                    endDate={endQuarter}
                    dateFormat="yyyy, QQQ"
                    showQuarterYearPicker
                    className="bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                  {/* <DatePicker
                    selected={getByMonth}
                    onChange={(date) => setGetByMonth(date as Date)}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    className=" bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  /> */}

                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    // isClearable={true}
                    className=" bg-white ml-4 block w-52 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                {/* {restByContainer !== undefined && (
                  <CSVLink
                    filename={`${title}-${Date.now()}.csv`}
                    data={ExportDataStockFormatter(restByContainer)}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="flex-shrink-0 ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel tồn kho</span>
                  </CSVLink>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6 p-4">
          <div className="flex justify-end gap-4 mb-8">
            {loading ? (
              <div>
                <LoadingComponent />
              </div>
            ) : (
              exportStorageContainer !== undefined &&
              exportStorageContainer.length > 0 && (
                <CSVLink
                  filename={`Tồn kho-${Date.now()}.csv`}
                  data={ExportContainerStorageFormatter(exportStorageContainer)}
                  onClick={() => {
                    ToastSuccess("Tải về file Excel thành công!");
                  }}
                  className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                >
                  <span>Xuất excel container tồn kho</span>
                </CSVLink>
              )
            )}
            {loadingSoldContainer ? (
              <div>
                <LoadingComponent />
              </div>
            ) : (
              dataSoldByMonth !== undefined &&
              dataSoldByMonth.length > 0 && (
                <CSVLink
                  filename={`Sản lượng-${Date.now()}.csv`}
                  data={ExportContainerSoldByMonthFormatter(dataSoldByMonth)}
                  onClick={() => {
                    ToastSuccess("Tải về file Excel thành công!");
                  }}
                  className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                >
                  <span>Xuất excel sản lượng</span>
                </CSVLink>
              )
            )}
          </div>
          <div className=" flex justify-between lg:justify-end space-x-2 overflow-hidden overflow-x-auto">
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-72">
              <span className="text-xl font-medium text-gray-800">
                Tháng này đã bán được
              </span>
              <div className="flex items-center text-green-700 space-x-3">
                <span className="text-xl font-bold text-green-700">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].count !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].count
                      )
                    : "N/A"}{" "}
                  container
                </span>
                {/* <span className="text-xl font-bold text-green-700">
                  {totalContainer && totalContainer.length} container
                </span> */}
              </div>
            </div>
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-56">
              <span className="text-xl font-medium text-gray-800">
                Lợi nhuận
              </span>
              <div className="flex items-center text-green-700 space-x-3">
                <span className="text-xl font-bold text-green-700">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].revenue !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].revenue
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>
            <div className="flex flex-col flex-shrink-0 p-8 bg-gray-200 rounded-lg border border-gray-300 w-56">
              <span className="text-xl font-medium text-gray-800">
                Doanh thu
              </span>
              <div className="flex items-center text-gray-800 space-x-3">
                <span className="text-xl font-bold">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].totalSelling !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].totalSelling
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>

            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-56">
              <span className="text-xl font-medium text-gray-800">
                Tổng chi phí
              </span>
              <div className="flex items-center space-x-1">
                {/* <BiDollar className="text-2xl -ml-1" /> */}
                <span className="text-xl font-bold">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].totalCost !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].totalCost
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-56">
              <span className="text-xl font-medium text-gray-800">
                Tổng vốn
              </span>
              <div className="flex items-center space-x-1">
                {/* <BiDollar className="text-2xl -ml-1" /> */}
                <span className="text-xl font-bold">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].totalBuying !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].totalBuying
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
          <LabelComponent
            label="Bảng thống kê chi tiết chi phí:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableAnalyticCost totalContainer={totalContainer} />
        </div>

        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết sale:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByUser data={soldByUser} />
        </div>

        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết Container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByContainer
            data={soldByContainer}
            isRest={false}
            totalSize="Tổng số container bán được"
            isAdmin={true}
          />
          <div className="mt-4"></div>
          <TableSoldByContainer
            data={restByContainer}
            isRest={true}
            restSize="Tổng số container còn lại"
            isAdmin={true}
          />
        </div>

        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết nhà cung cấp:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldBySupplier
            data={soldBySupplier}
            isRest={false}
            totalSupplier="Số container đã bán"
          />
          <div className="mt-4"></div>
          <TableSoldBySupplier
            data={restBySupplier}
            isRest={true}
            restSupplier="Số container còn lại"
          />
        </div>

        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết vị trí container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByServiceSupplier
            data={soldByServiceSupplier}
            isRest={false}
          />
          <div className="mt-4"></div>
          <TableSoldByServiceSupplier
            data={restByServiceSupplier}
            isRest={true}
          />
        </div>
        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết size container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldBySize
            data={soldBySizes}
            isRest={false}
            totalSize="Số container đã bán"
          />
          <div className="mt-4"></div>
          <TableSoldBySize
            data={restBySizes}
            isRest={true}
            restSize="Số container còn lại"
          />
        </div>
        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết khách hàng:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByClient data={soldByClient} />
        </div> */}
      </div>
    </div>
  );
};

export default ContainerAnalyst;
