export const optionDoughnutChart = {
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (ttItem: any) {
          const total = ttItem.dataset.data.reduce(
            (a: number, b: number) => Number(a) + Number(b),
            0
          );
          const percentage = ((ttItem.parsed * 100) / total).toFixed(2); // Calculate the percentage
          // Format the label with both the label and the value
          return `${percentage}% (${ttItem.parsed} of ${total})`;
          // return `${ttItem.label}: ${percentage}% (${ttItem.parsed} of ${total})`;
        },
      },
    },
  },
};

export const optionLineChart = (title: string) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 22,
        },
      },
    },
  };
};
