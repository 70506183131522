import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import vi from "date-fns/locale/vi"; // Vietnamese localization
import moment from "moment";

interface MyDatePickerProps {
  value: string; // Assuming the date is in string format ("YYYY-MM-DD")
  onChange: (fieldName: string, value: string) => void;
  onBlur: (fieldName: string, value: boolean) => void;
  error?: string;
  touched?: boolean;
  isUpdating: string | null;
  nameField: string;
  maxDate?: string;
}

function MyDatePicker(props: MyDatePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    props.isUpdating ? new Date(props.isUpdating) : null
  );

  useEffect(() => {
    if (props.isUpdating) {
      setSelectedDate(new Date(props.isUpdating));
    }
  }, [props.isUpdating]);

  const handleDateChange = (value: Date | null) => {
    if (value) {
      const formattedDate = moment(value).format("YYYY-MM-DD");
      props.onChange(props.nameField, formattedDate);
    }
    setSelectedDate(value);
  };

  const handleBlur = () => {
    props.onBlur(props.nameField, true);
  };

  const maxDate = new Date(!props.maxDate ? "2025-01-01" : props.maxDate);
  const minDate = new Date();
  // const minDate = new Date("1950-01-01");

  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onBlur={handleBlur}
        onChange={handleDateChange}
        locale={vi} // set Vietnamese localization
        dateFormat="dd/MM/yyyy" // set date format
        className="border border-gray-300 rounded-md p-2 w-full text-gray-500"
        placeholderText="Chọn ngày"
        maxDate={maxDate}
        minDate={minDate}
        // popperPlacement="top"
      />
      {props?.error && props.touched && (
        <div className="text-red-400 ">{props?.error}</div>
      )}
    </div>
  );
}

export default MyDatePicker;
