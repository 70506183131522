import React, { useEffect } from "react";
import { FaUserAlt } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import { BookingSaleApiProps, InvoiceApiProps } from "../../../types/apiType";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import Swal from "sweetalert2";
import { deleteAction } from "../../../redux/slices/BookingSale/bookingSaleSlices";
import { BsTrash } from "react-icons/bs";
import { DateConverter } from "../../../utils/DateFormatter";
import {
  PaymentStatusHelper,
  containerSizeHelper,
} from "../../../utils/StatusHelper";
import { Socket } from "socket.io-client";
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import { getAllAction } from "../../../redux/slices/User/UserSlice";
import { v4 as uuidv4 } from "uuid";
import { bookingSalePDF } from "../../../utils/PDFHelper/bookingSalePDF";
// Define the props type for ListItem
interface ListItemProps {
  data: Array<InvoiceApiProps> | null;
  openFormUpdate: (id: string) => void;
  socket: Socket;
}

export const ListItem: React.FC<ListItemProps> = ({
  data,
  openFormUpdate,
  socket,
}) => {
  const dispatch = useAppDispatch();
  const handleOpenFormUpdate = (id: string | undefined) => {
    if (id !== undefined) {
      openFormUpdate(id);
    }
  };
  useEffect(() => {
    if (socket) {
      const invoiceDeleteReceiveHandler = (deletedID: string) => {
        const params = {
          keyword: "",
          offset: 0,
          limit: 5,
        };
        dispatch(getAllAction(params));
      };
      socket.on("delete-receive", invoiceDeleteReceiveHandler);
      return () => {
        // Clean up the event listener when the component unmounts
        socket.off("delete-receive", invoiceDeleteReceiveHandler);

        // Leave the "invoice" room
        socket.emit("leave-room", "invoice");
      };
    }
  }, []);
  // delete data event
  const handleDelete = (id: string | undefined) => {
    Swal.fire({
      title: "Bạn có chắc muốn xóa dữ liệu này không?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed && id !== undefined) {
        const action = await dispatch(deleteAction(id));
        if (deleteAction.fulfilled.match(action)) {
          socket.emit("delete-data", {
            room: "invoice",
            roomContainer: "container",
            deleteId: id,
          });
          ToastSuccess(action.payload.message);
        } else {
          ToastError(action.payload as string);
        }
      } else if (result.isDenied) {
        Swal.fire("Bạn vẫn chưa xóa!", "", "info");
      }
    });
  };
  // paymentStatus == 1
  //   ? "Đã thanh toán"
  //   : paymentStatus == 2
  //   ? "Sắp đến hạn"
  //   : paymentStatus == 0
  //   ? "Chưa thanh toán"
  //   : "Đã quá hạn thanh toán";

  const handleBookingPDF = (data: BookingSaleApiProps) => {
    bookingSalePDF(data);
  };
  return (
    <>
      {data?.map((item, index) => (
        <tr
          className={` ${
            item?.paymentStatus === 1
              ? "bg-gray-50"
              : item?.paymentStatus === 2
              ? "bg-amber-100"
              : item?.paymentStatus === 0
              ? "bg-amber-50"
              : item?.paymentStatus === 4
              ? "bg-green-50"
              : "bg-red-50"
          }`}
          key={uuidv4()}
        >
          <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {index + 1}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-gray-500 border border-slate-500 ">
            {item?.bookingManySaleNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {!item?.supplier?.label ? "---" : item?.supplier?.label}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {!item?.serviceSupplier?.label
              ? "---"
              : item?.serviceSupplier?.label}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {!item?.buyer?.label ? "---" : item?.buyer?.label}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {!item?.seller?.label ? "---" : item?.seller?.label}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.contQuantity}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.remainingQuantity}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            <div className="group relative w-max">
              {!item?.containerCondition ? "---" : item?.containerCondition}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            <div className="group relative w-max">
              {containerSizeHelper(item?.size)}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.paymentDue === "" ? (
              "---"
            ) : (
              <DateConverter date={item?.paymentDue} />
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.bookingDate === "" ? (
              "---"
            ) : (
              <DateConverter date={item?.bookingDate} />
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {PaymentStatusHelper(item?.paymentStatus)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              parseInt(!item?.sellingPrice ? "0" : item?.sellingPrice)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              parseInt(item?.bookingManySalePrice!)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {!item?.hotline ? "---" : item?.hotline}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500 space-x-2">
            <div
              onClick={() => handleDelete(item?._id)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <BsTrash
                className="-ml-1 mr-2 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <span>Xóa dữ liệu</span>
            </div>
            <div
              onClick={() => handleOpenFormUpdate(item?._id)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <FaUserAlt
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span className="text-white">Cập nhật dữ liệu</span>
            </div>
            <div
              onClick={() => handleBookingPDF(item)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <FaFilePdf
                className="-ml-1 mr-2 h-5 w-5 text-red-400"
                aria-hidden="true"
              />
              <span className="text-white">Xuất PDF</span>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
