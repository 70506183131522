import Swal from "sweetalert2";
import { NavigateFunction } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { logoutAction } from "../../redux/slices/User/UserSlice";
import { clearClientAdminAction } from "../../redux/slices/ClientAdmin/ClientAdminSlice";
import { clearServiceSuppliersAction } from "../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import { clearSuppliersAction } from "../../redux/slices/Supplier/SupplierSlice";
import { clearInvoicesAction } from "../../redux/slices/Invoice/invoiceSlice";
import { clearContainersAction } from "../../redux/slices/Container/containerSlice";
import { clearCountersAction } from "../../redux/slices/Counter/CounterSlice";
import { clearBrandOfUnitsAction } from "../../redux/slices/BrandOfUnit/BrandOfUnitSlice";

const LogoutConfirmation = (
  navigate: NavigateFunction,
  dispatch: AppDispatch,
  title: string
) => {
  Swal.fire({
    title: title,
    // title: "Đã hết phiên làm việc, vui lòng đăng nhập lại?",
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: "OK",
    denyButtonText: "Chờ một tí",
  }).then(async (result) => {
    if (result.isConfirmed) {
      Swal.fire("Đã đăng xuất!", "", "success");
      navigate("/login");
      await dispatch(logoutAction());
      dispatch(clearClientAdminAction());
      dispatch(clearServiceSuppliersAction());
      dispatch(clearBrandOfUnitsAction());
      dispatch(clearSuppliersAction());
      dispatch(clearInvoicesAction());
      dispatch(clearContainersAction());
      dispatch(clearCountersAction());
    }
  });
};

export default LogoutConfirmation;
