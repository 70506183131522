export const ColorContainerHelper = (
  sizeCont: string | undefined,
  contStatus: number | undefined
) => {
  if (contStatus === 1 || contStatus === 2) {
    return "bg-[#7030a0]";
  } else if (!sizeCont) {
    return "bg-green-800";
  } else if (sizeCont.includes("20DC")) {
    return "bg-white";
  } else if (sizeCont.includes("20HR")) {
    return "bg-[#ff0]";
  } else if (sizeCont.includes("40DC")) {
    return "bg-[#9bc2e6]";
  } else if (sizeCont.includes("40HC")) {
    return "bg-[#f00]";
  } else if (sizeCont.includes("40HR")) {
    return "bg-[#c6e0b4]";
  } else if (sizeCont.includes("40OT")) {
    return "bg-[#4d1b1a]";
  } else if (sizeCont.includes("45HQ")) {
    return "bg-[#f4b084]";
  } else if (sizeCont.includes("40FL")) {
    return "bg-[#bfbfbf]";
  }
};
