import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { ContainerApiProps } from "../../../types/apiType";
import { NumericFormat } from "react-number-format";

interface FormProps {
  closeForm: () => void;
  updateData: (data: ContainerApiProps) => Promise<void>;
}

export const FormUpdateMany = (props: FormProps) => {
  const formSchema = Yup.object().shape({
    sellingPrice: Yup.string().test(
      "Phải là số dương!",
      "Giá trị phải lớn hơn 0!",
      (value) => {
        if (value !== undefined) {
          return parseFloat(value) >= 0;
        }
      }
    ),
  });

  //declare value in fields
  const [sellingPrice, setSellingPrice] = useState("");

  // get props to index components
  const { closeForm, updateData } = props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataUpdateNew: ContainerApiProps = {
      sellingPrice:
        typeof formik.values.sellingPrice == "string"
          ? formik.values.sellingPrice.replace(/,/g, "")
          : formik.values.sellingPrice,
    };
    updateData(dataUpdateNew);
  };

  // check show button action
  const showButtonAction = () => {
    return (
      <button
        type="submit"
        onClick={(e) => handleUpdateData(e)}
        className="focus:outline-none text-white bg-green-600 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 disabled:cursor-not-allowed"
        disabled={!formik.isValid}
      >
        Cập nhật tất cả
      </button>
    );
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sellingPrice,
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);
  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">Cập nhật giá container</p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  type="text"
                  name="floating_sellingPrice"
                  id="floating_sellingPrice"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.sellingPrice}
                  onChange={formik.handleChange("sellingPrice")}
                  onBlur={formik.handleBlur("sellingPrice")}
                />
                <label
                  htmlFor="floating_sellingPrice"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Giá bán (VNĐ)<span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.sellingPrice && formik.errors.sellingPrice}
              </div>
            </div>
          </div>
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
