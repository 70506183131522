import React, { useState } from "react";
import { HiSearch } from "react-icons/hi";
import { Reload } from "../../components/Reload";
import { dataSearchPaymentStatus } from "../../constants/dropdown";
import { useFormik } from "formik";
interface SearchProps {
  handleSearch: (keySearch: string, paymentStatus: string) => void;
}
export const Search = (props: SearchProps) => {
  const [keySearch, setKeySearch] = useState("");
  const [paymentStatus] = useState("");

  const { handleSearch } = props;

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentStatus,
    },
    onSubmit: () => {},
  });

  // search event
  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSearch(keySearch.trim(), formik.values.paymentStatus);
  };

  return (
    <div className="h-28 flex items-center w-full bg-slate-100 mt-3 overflow-hidden overflow-x-auto">
      <form className="w-full md:w-1/2">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Tìm
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-60 sm:w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline "
            placeholder="Nhập từ khóa ..."
            required
            onChange={(e) => setKeySearch(e.target.value)}
            value={keySearch}
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
            onClick={handleClickSearch}
          >
            Tìm
          </button>
        </div>
      </form>
      <select
        id="ward"
        className="bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        value={formik.values.paymentStatus}
        onChange={formik.handleChange("paymentStatus")}
        onBlur={formik.handleBlur("paymentStatus")}
      >
        <option value="">{`-- Chọn --`}</option>
        {dataSearchPaymentStatus?.map((item, index) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <Reload />
    </div>
  );
};
