import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  BsChevronCompactLeft,
  BsChevronCompactRight,
  BsDownload,
} from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectContainer } from "../../redux/slices/Container/containerSlice";
import { ImageParams } from "../../types/apiType";
import { downloadBase64Data } from "../../utils/DownloadImagesHelper";

interface SliderProps {
  closeForm: () => void;
  isBigger: boolean;
  currentNameField: string;
}

export const Slider: React.FC<SliderProps> = ({
  closeForm,
  isBigger,
  currentNameField,
}) => {
  //logic redux
  const posts = useSelector(selectContainer);
  const { container } = posts;

  //logic slider
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    if (container?.[currentNameField]?.length !== undefined) {
      const newIndex = isFirstSlide
        ? container?.[currentNameField]?.length - 1
        : currentIndex - 1;
      setCurrentIndex(newIndex);
    }
  };
  const nextSlide = () => {
    if (container?.[currentNameField]?.length !== undefined) {
      const isLastSlide =
        currentIndex === container?.[currentNameField]?.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }
  };

  //   const prevSlide = () => {
  //     if (container?.images?.length) {
  //       setCurrentIndex(
  //         (currentIndex - 1 + container.images.length) % container.images.length
  //       );
  //     }
  //   };

  //   const nextSlide = () => {
  //     if (container?.images?.length) {
  //       setCurrentIndex((currentIndex + 1) % container.images.length);
  //     }
  //   };

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setCurrentIndex(
  //         (currentIndex) => (currentIndex + 1) % container?.images?.length!
  //       );
  //     }, 5000);
  //     return () => clearInterval(interval);
  //   }, [container?.images]);
  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  // const handleDownloadImages = () => {
  //   downloadBase64Data(container?.[currentNameField]);
  // };

  // const handleDownloadImages = () => {
  //   container?.[currentNameField].forEach((element: ImageParams) => {
  //     var a = document.createElement("a"); //Create <a>
  //     a.href = "data:image/png;base64," + element.preview; //Image Base64 Goes here
  //     a.download = element.filename!; //File name Here
  //     a.click(); //Downloaded file
  //   });
  // };

  const handleDownloadImages = () => {
    container?.[currentNameField].forEach(
      (element: ImageParams, index: number) => {
        setTimeout(() => {
          var a = document.createElement("a");
          a.href = "data:image/png;base64," + element.preview;
          a.download = element.filename!;
          a.click();
        }, index * 1000); // Delay in milliseconds (1000ms = 1 second)
      }
    );
  };

  return (
    <div className="w-full h-full ">
      {!isBigger && (
        <>
          <button
            className="absolute top-0 left-0 sm:left-[68%] z-10 w-full inline-flex justify-end "
            onClick={() => handleCloseForm()}
          >
            <AiOutlineClose className="text-3xl text-white bg-red-600 hover:bg-red-400 rounded-lg" />
          </button>
          <button
            // className="absolute bottom-12 sm375:bottom-2 right-40 left-0 sm:left-[68%] z-10 w-full justify-end hidden md:inline-flex"
            className="absolute bottom-12 sm375:bottom-2 right-40 left-0 sm:left-[68%] z-10 w-full justify-end inline-flex"
            onClick={() => handleDownloadImages()}
          >
            <BsDownload className="text-3xl text-white bg-blue-600 hover:bg-blue-400 rounded-lg mr-2" />
            <span className="text-white">Tải xuống</span>
          </button>
        </>
      )}
      {container?.[currentNameField]?.map(
        (imageUrl: ImageParams, index: number) => (
          <img
            key={index}
            src={`data:image/jpeg;base64,${imageUrl.preview}`}
            alt=""
            className={`${
              isBigger ? "top-[4.1rem] h-[570px]" : "top-0 h-full"
            } absolute left-0 w-full sm:left-[68%] sm:w-[540px] rounded-2xl bg-contain ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            } `}
          />
        )
      )}

      {/* Left arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[50%] left-5 sm:left-[68%] text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft size={30} onClick={prevSlide} />
      </div>
      {/* Right arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[50%] right-5 sm:-right-[68%] text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight size={30} onClick={nextSlide} />
      </div>
    </div>
  );
};
