import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Navigation from "./Navigation/NavItem";
export default function ContainerManagement() {
  const location = useLocation();
  const title = "Quản lý container";

  useEffect(() => {
    document.title = title;
  }, []);

  const isContainerRoute = location.pathname === ROUTES.CONTAINER_MANAGEMENT;
  // if the current route is Container_MANAGEMENT, redirect to the Container route
  if (isContainerRoute) {
    return <Navigate to={ROUTES.CONTAINER} replace />;
  }
  return (
    <>
      <div className="bg-blue-100 h-screen pt-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-xl p-4 drop-shadow-sm">
          {/* Nav features */}
          <Navigation />
        </div>
        <div className="mt-2">
          <Outlet />
        </div>
      </div>
    </>
  );
}
