import Swal from "sweetalert2";

export default function ToastError(message, serverError) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    width: 500,
  });

  Toast.fire({
    icon: "error",
    title: message ?? (serverError && "Máy chủ đang bận!"),
  });
}

export function ToastError3Seconds(message, serverError) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    width: 500,
  });

  Toast.fire({
    icon: "error",
    title: message ?? (serverError && "Máy chủ đang bận!"),
  });
}
