import React from "react";
//libraries
import { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
//icons
import { BsFillCloudUploadFill, BsImages } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
//types
import { ContainerApiProps, UserApiProps } from "../../../types/apiType";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//utils
import { DateConverter } from "../../../utils/DateFormatter";
import {
  containerSizeHelper,
  containerStatusHelper,
} from "../../../utils/StatusHelper";
//redux
import {
  selectSelects,
  toggleItemAction,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import { useSelector } from "react-redux";
import LoadingComponent from "../../../components/LoadingComponent";
// Define the props type for ListItem
interface ListItemProps {
  data: Array<ContainerApiProps> | undefined;
  openFormUpdateReceipt: (id: string) => void;
  openFormUpdateImages: (id: string) => void;
  openFormUpdateImagesAfterRepair: (id: string) => void;
  openFormUpdateImagesGateOut: (id: string) => void;
  openSlide: (id: string, currentNameField: string) => void;
  socket: Socket;
  userAuth?: UserApiProps;
  loadingImagesGateOut?: boolean;
  loadingImagesGateIn?: boolean;
  loadingImagesMNR?: boolean;
  containerUpdate?: ContainerApiProps;
}

export const ListItem: React.FC<ListItemProps> = ({
  data,
  openFormUpdateReceipt,
  socket,
  userAuth,
  openSlide,
  openFormUpdateImages,
  openFormUpdateImagesAfterRepair,
  openFormUpdateImagesGateOut,
  loadingImagesGateOut,
  loadingImagesGateIn,
  loadingImagesMNR,
  containerUpdate,
}) => {
  const dispatch = useAppDispatch();

  const getSelects = useSelector(selectSelects);
  const { selected } = getSelects;

  const handleSelection = (itemSelected: ContainerApiProps) => {
    // dispatch(toggleItemAction({ itemSelected }));
    dispatch(toggleItemAction(itemSelected));
  };
  const openSlideShow = (id: string, currentNameField: string) => {
    openSlide(id, currentNameField);
  };
  const openFormUpdateImagesForm = (id: string) => {
    openFormUpdateImages(id);
  };
  const openFormUpdateImagesAfterRepairForm = (id: string) => {
    openFormUpdateImagesAfterRepair(id);
  };
  const openFormUpdateImagesGateOutForm = (id: string) => {
    openFormUpdateImagesGateOut(id);
  };

  const handleOpenFormUpdateReceipt = (id: string) => {
    openFormUpdateReceipt(id);
  };

  return (
    <>
      {data?.map((item, index) => (
        <tr
          // className="bg-gray-50"
          className={` ${!item.isReceipt ? "bg-gray-50" : "bg-red-50"}`}
          key={uuidv4()}
        >
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.contStatus !== 2 && (
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  checked={selected
                    ?.map((item) => item._id)
                    .includes(item._id!)}
                  onChange={() => handleSelection(item)}
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {index + 1}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item?.invoiceNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item?.bookingNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>{!item?.supplier?.label ? "---" : item?.supplier?.label}</div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.lineOperation?.label ? "---" : item?.lineOperation?.label}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.serviceSupplier?.label
                ? "---"
                : item?.serviceSupplier?.label}
            </div>
            {/* <div>{!item?.client?.label ? "---" : item?.client?.label}</div> */}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.containerNumber ? "---" : item?.containerNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.containerCondition ? "---" : item?.containerCondition}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {containerSizeHelper(item?.size)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.brandOfUnit?.label ? "---" : item?.brandOfUnit?.label}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.yearOfManufacture ? "---" : item?.yearOfManufacture}
          </td>

          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {loadingImagesGateIn && containerUpdate?._id === item._id ? (
              <LoadingComponent />
            ) : !item?.hasImage && !item?.images?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() => openFormUpdateImagesForm(item?._id!)}
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "images")}
              >
                <div className="bg-blue-600 p-2 rounded group relative w-max flex items-center gap-2 mt-2">
                  <BsImages className="w-5 h-5" />
                  Xem hình
                </div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() => openFormUpdateImagesForm(item?._id!)}
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 ">
            {loadingImagesMNR && containerUpdate?._id === item._id ? (
              <LoadingComponent />
            ) : !item?.hasImagesAfterRepair &&
              !item?.imagesAfterRepair?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() =>
                    openFormUpdateImagesAfterRepairForm(item?._id!)
                  }
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "imagesAfterRepair")}
              >
                <div className="bg-blue-600 p-2 mt-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white w-[102px] group cursor-pointer"
                  onClick={() =>
                    openFormUpdateImagesAfterRepairForm(item?._id!)
                  }
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {loadingImagesGateOut && containerUpdate?._id === item._id ? (
              <LoadingComponent />
            ) : !item?.hasImagesGateOut && !item?.imagesGateOut?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() => openFormUpdateImagesGateOutForm(item?._id!)}
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "imagesGateOut")}
              >
                <div className="bg-blue-600 mt-2 p-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
                <div
                  onClick={() => openFormUpdateImagesGateOutForm(item?._id!)}
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white w-[102px] group cursor-pointer"
                >
                  <BsFillCloudUploadFill className="w-5 h-5" />
                  Upload
                </div>
              </div>
            )}
          </td>

          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {containerStatusHelper(item?.contStatus)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item?.VAT}%
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              parseInt(!item?.sellingPrice ? "0" : item?.sellingPrice)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              parseInt(
                !item?.actualSellingPrice ? "0" : item?.actualSellingPrice
              )
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            {!item?.seller?.label ? "---" : item?.seller?.label}
          </td>
          {/* <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            {!item?.buyer?.label ? "---" : item?.buyer?.label}
          </td> */}
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              item?.depositAmount === undefined
                ? 0
                : parseInt(item?.depositAmount)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.depositDuration ? (
              "---"
            ) : (
              <DateConverter date={item?.depositDuration} />
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.sellContDate ? (
              "---"
            ) : (
              <DateConverter date={item?.sellContDate} />
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              !item?.incurCost ? 0 : parseInt(item?.incurCost)
            )}
          </td>

          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              !item?.otherCosts ? 0 : parseInt(item?.otherCosts)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              !item?.LOLOCost ? 0 : parseInt(item?.LOLOCost)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              !item?.towingCost ? 0 : parseInt(item?.towingCost)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              !item?.repairCost ? 0 : parseInt(item?.repairCost)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item?.notes}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 space-x-2">
            <div
              onClick={() => handleOpenFormUpdateReceipt(item?._id!)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <FaFileInvoiceDollar
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span className="text-white">Lập phiếu thu</span>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
