import { Fragment, useEffect, useMemo, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  navigationDepotPrivate,
  navigationPrivate,
} from "../../../constants/navigation";
import NavItem from "../NavItem";
import user from "../../../img/user.png";
import LogoutConfirmation from "../../Logout/LogoutConfirmation";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import NotificationListComponent from "../../NotificationListComponent";
import containerIcon from "../../../img/logo-conta.png";
import NotificationMobile from "../../NotificationListComponent/NotificationMobile";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const PrivateNavbar = ({
  isLogin,
  notificationData,
  showRedDotFirstTime,
  totalResult,
  totalUnRead,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userNavigation = [
    { title: "Hồ sơ của bạn", href: `/profile/${isLogin?._id}` },
  ];
  const [listNotification, setListNotification] = useState(notificationData);

  const dispatch = useAppDispatch();

  useMemo(() => {
    setListNotification(notificationData);
  }, [notificationData]);

  const [showRedDot, setShowRedDot] = useState(showRedDotFirstTime);

  useEffect(() => {
    setShowRedDot(notificationData.some((item) => item.status === false));
  }, [notificationData]);

  //logout
  const handleLogout = () => {
    LogoutConfirmation(navigate, dispatch, "Bạn có chắc là muốn đăng xuất?");
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  };

  const dataArrayResponsive =
    isLogin?.role === 3 || isLogin?.role === 4
      ? navigationDepotPrivate
      : navigationPrivate;

  const [locationCurrent, setLocationCurrent] = useState("");
  useEffect(() => {
    setLocationCurrent(`/${location.pathname.split("/")[1]}`);
  }, [location.pathname]);

  return (
    <Disclosure as="nav" className="background-color-base">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <AiOutlineClose
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <AiOutlineMenu
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  {/* Logo */}
                  {/* <BsBook className="h-10 w-10 text-yellow-200" /> */}
                  <img
                    src={containerIcon}
                    alt="container-icon"
                    className="h-14 w-24"
                  />
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                  <NavItem
                    navigationArr={
                      isLogin?.role === 3 || isLogin?.role === 4
                        ? navigationDepotPrivate
                        : navigationPrivate
                    }
                  />
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden lg:flex-shrink-0 lg:flex lg:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative z-10">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full bg-white"
                              src={user}
                              alt="Admin avatar"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {userNavigation.map((item, index) => (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.title}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="hidden lg:flex-shrink-0 lg:flex lg:items-center">
                  {/* Notifications */}
                  <NotificationListComponent
                    showRedDot={showRedDot}
                    listNotification={listNotification}
                    totalResult={totalResult}
                    totalUnRead={totalUnRead}
                  />
                </div>
                <div className="flex-shrink-0 lg:ml-4">
                  {/* Logout */}
                  <button
                    onClick={handleLogout}
                    // onClick={() => dispatch(logoutAction())}
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-red-500"
                  >
                    <HiOutlineLogout
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Log out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {dataArrayResponsive.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className={classNames(
                    locationCurrent === item.href
                      ? "bg-gray-900 text-white"
                      : "text-white hover:bg-gray-700 hover:text-white",
                    "block py-2 px-3 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.title}
                </Link>
              ))}
            </div>
            {/* Mobile */}
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center justify-between px-5 sm:px-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {/* Image */}
                    <img className="h-10 w-10 rounded-full" src={user} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {isLogin?.currentName}
                    </div>
                    {/* <div className="text-base font-medium text-white">
                        {isLogin?.email}
                      </div> */}
                  </div>
                </div>
                <NotificationMobile
                  showRedDot={showRedDot}
                  listNotification={listNotification}
                  totalResult={totalResult}
                  totalUnRead={totalUnRead}
                />
              </div>
              <div className="mt-3 px-2 space-y-1 sm:px-3">
                {userNavigation.map((item, index) => (
                  <Link
                    key={index}
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700"
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
