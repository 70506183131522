import { ApiParams, ClientApiProps } from "../types/apiType";
import axiosClient from "./axiosClient";

const module = "admin/client";

const adminClientApi = {
  create: (body: ClientApiProps) => {
    const url = `/${module}/`;
    return axiosClient.post(url, body);
  },
  update: (id: string | undefined, body: ClientApiProps) => {
    const url = `/${module}/${id}`;
    return axiosClient.put(url, body);
  },
  share: (id: string | undefined, body: ClientApiProps) => {
    const url = `/${module}/share/${id}`;
    return axiosClient.put(url, body);
  },
  delete: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.delete(url);
  },
  getByID: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.get(url);
  },
  getAll: (params: ApiParams) => {
    const url = `/${module}/search`;
    return axiosClient.get(url, { params });
  },
  getAllWithoutLimit: () => {
    const url = `/${module}/getAll`;
    return axiosClient.get(url);
  },
};

export default adminClientApi;
