import { useState, useEffect } from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useSelector } from "react-redux";
import {
  getAllWithoutLimitAction,
  selectUser,
} from "../../redux/slices/User/UserSlice";
import { UserInfoProps, UserSelectOption } from "../../types";
import { FormikErrors, FormikTouched } from "formik";

type CustomStyles = {
  container: (provided: any) => any;
  control: (provided: any, state: { isFocused: boolean }) => any;
  option: (provided: any, state: { isSelected: boolean }) => any;
};

const customStyles: CustomStyles = {
  container: (provided) => ({
    ...provided,
    borderRadius: "0.5rem",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.5rem",
    "&:hover": {
      borderColor: state.isFocused ? "#9CA3AF" : "#D1D5DB",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E5E7EB" : "#FFFFFF",
    color: state.isSelected ? "#111827" : "#374151",
    "&:hover": {
      backgroundColor: "#D1D5DB",
    },
  }),
};

interface UserDropDownProps {
  value: UserSelectOption[];
  onChange: (
    fieldName: string,
    value: UserSelectOption[] | null,
    actionMeta: ActionMeta<UserSelectOption>
  ) => void;
  onBlur: (fieldName: string, value: boolean) => void;
  isUpdating: UserSelectOption[] | null; // Use null for initializing
  error?: string | string[] | FormikErrors<UserSelectOption>[] | undefined;
  touched?: FormikTouched<UserSelectOption>[] | undefined;
}

export default function UserDropDown(props: UserDropDownProps) {
  const dispatch = useAppDispatch();
  const allUsers = useSelector(selectUser);
  const { data } = allUsers;

  // Fetch data when the component mounts
  useEffect(() => {
    const params = {
      keyword: "",
      offset: 0,
      limit: 100,
    };
    dispatch(getAllWithoutLimitAction(params));
  }, [dispatch]);

  const getUsers = data?.map((user: UserInfoProps) => {
    return {
      label: user?.currentName,
      value: user?._id?.toString(),
    };
  });

  const handleChange = (
    value: MultiValue<UserSelectOption>,
    actionMeta: ActionMeta<UserSelectOption>
  ) => {
    if (value) {
      props.onChange("listUser", value as UserSelectOption[], actionMeta);
    } else {
      props.onChange("listUser", null, actionMeta);
    }
  };

  const handleBlur = () => {
    props.onBlur("listUser", true);
  };
  return (
    <div>
      <Select
        options={getUsers}
        styles={customStyles}
        onChange={handleChange}
        onBlur={handleBlur}
        value={props.value}
        isMulti
      />
      {/* Display */}
      {props.error && props.touched && (
        <div className="text-red-400 ">{props.error.toString()}</div>
      )}
    </div>
  );
}
