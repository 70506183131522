import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  clearAContainerAction,
  clearContainersAction,
  getByIdAction,
  getContainerQueryByPageAction,
  getQueryContainerAction,
  selectContainer,
} from "../../redux/slices/Container/containerSlice";
import {
  getAllAction,
  selectBookingSale,
} from "../../redux/slices/BookingSale/bookingSaleSlices";
import { ListItem } from "./ListItem";
import { Search } from "./Search";
import { useAppDispatch } from "../../hooks/reduxHooks";
import LogoutConfirmation from "../../components/Logout/LogoutConfirmation";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import ToastSuccess from "../../components/Toast/success";
import {
  ExportBookingDataQueryContainerFormatter,
  ExportBookingUserDataQueryContainerFormatter,
  ExportDataQueryContainerFormatter,
  ExportUserDataQueryContainerFormatter,
} from "../../utils/ExportDataFormatter";
import {
  getAllNotificationAction,
  selectUser,
} from "../../redux/slices/User/UserSlice";
import { paramsNotification } from "../../utils/paramsHelper";
import ReactPaginate from "react-paginate";
import { clearSelectionAction } from "../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import { Socket } from "socket.io-client";
import useSocketJoinRoom from "../../hooks/useSocketJoinRoom";
import useSocketEventHandler from "../../hooks/useSocketHandler";
import { Slider } from "../../components/Slider";
import { openForm } from "../../redux/slices/formSlices";
interface SocketProps {
  socket: Socket;
}
export const QueryContainer = ({ socket }: SocketProps) => {
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = () => {
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const title = "Truy vấn";
  const [currentPage] = useState(1);
  const [limit] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [searchKey, setSearchKey] = useState(0);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [currentNameField, setCurrentNameField] = useState("");
  //set offset
  let offset = currentPage - 1;
  //set params
  const params = {
    keyword: keyword,
    offset: offset,
    limit: limit,
  };

  const getData = () => {
    dispatch(getQueryContainerAction(params));
    dispatch(getAllAction(params));
  };

  useEffect(() => {
    document.title = title;
    dispatch(clearContainersAction());
    dispatch(getAllNotificationAction(paramsNotification));
    getData();
  }, []);

  //get data from redux
  const containerData = useSelector(selectContainer);
  const { container, data, totalPage, appError, containerRevenue, loading } =
    containerData;
  const bookingSaleData = useSelector(selectBookingSale);
  const { data: bookingSalesData } = bookingSaleData;
  const userLoginDate = useSelector(selectUser);
  const { userAuth } = userLoginDate;
  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    // dispatch(clearSelectionAction());
    // getData();
    dispatch(getContainerQueryByPageAction(data[params.offset]));
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (keyword: string) => {
    params.keyword = keyword;
    setKeyword(keyword);
    params.offset = 0;
    getData();
    setSearchKey((prevKey) => prevKey + 1);
    dispatch(clearSelectionAction());
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }
  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    // dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    setCurrentNameField(currentNameField);
  };
  const handleCloseSlide = () => {
    setSlideStatusState(false);
  };

  return (
    <>
      <div className="bg-blue-100 h-screen ">
        {slideStatusState && (
          <div className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16">
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-col sm:flex-row ml-2 ">
            <div>
              <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
              <div className="flex-none flex-shrink-0">
                <p className="font-sans font-semibold text-3xl">{title}</p>
                <p className="font-sans text-sm italic">{title} Conta</p>
              </div>
            </div>
            <div className="flex flex-col gap-y-3 sm:gap-y-0 sm:flex-row sm:items-center grow sm:justify-end mt-4 sm:mt-0 flex-shrink-0 ">
              {container?.listLifecycle !== undefined &&
                (userAuth?.role === 0 ? (
                  <CSVLink
                    data={ExportDataQueryContainerFormatter(
                      container,
                      containerRevenue
                    )}
                    filename={`Truy vấn-${Date.now()}.csv`}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="sm:ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4 "
                  >
                    <span>Xuất excel truy vấn</span>
                  </CSVLink>
                ) : (
                  <CSVLink
                    data={ExportUserDataQueryContainerFormatter(
                      container,
                      containerRevenue
                    )}
                    filename={`Truy vấn-${Date.now()}.csv`}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="sm:ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel truy vấn</span>
                  </CSVLink>
                ))}

              {container?.listLifecycle !== undefined &&
                data.length > 1 &&
                (userAuth?.role === 0 ? (
                  <CSVLink
                    data={ExportBookingDataQueryContainerFormatter(
                      data,
                      containerRevenue
                    )}
                    filename={`Truy vấn booking container-${Date.now()}.csv`}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="sm:ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel truy vấn booking</span>
                  </CSVLink>
                ) : (
                  <CSVLink
                    data={ExportBookingUserDataQueryContainerFormatter(
                      data,
                      containerRevenue
                    )}
                    filename={`Truy vấn booking container-${Date.now()}.csv`}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="sm:ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel truy vấn booking</span>
                  </CSVLink>
                ))}
            </div>
          </div>
          <Search handleSearch={handleSearch} />

          <div>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <ListItem
                      openSlide={(imageId, currentNameField) =>
                        handleOpenSlide(imageId, currentNameField)
                      }
                      data={container}
                      containerRevenue={containerRevenue}
                      userAuth={userAuth}
                      bookingSalesData={bookingSalesData[0]}
                      loading={loading}
                    />
                    {totalPage > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
