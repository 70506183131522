import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { LocationApiUpdateProps } from "../../../../../types/apiType";
import ContainerInContaDropDown from "../../../../../components/ContainerInContaDropDown";
import { UserSelectOption } from "../../../../../types";

interface FormProps {
  closeForm: () => void;
  updateData: (id: string | undefined, data: LocationApiUpdateProps) => void;
  dataUpdate: LocationApiUpdateProps;
  isUpdate: boolean;
}

export const FormUpdate = (props: FormProps) => {
  const formSchema = Yup.object().shape({});

  const [containerInConta, setContainerInConta] = useState<UserSelectOption>({
    value: "",
    label: "",
    _id: "",
  });

  // get props to index components
  const { closeForm, updateData, dataUpdate } = props;

  useEffect(() => {
    focus();
    if (dataUpdate) {
      if (dataUpdate.dataUpdate?.containerNumber !== undefined) {
        setContainerInConta({
          label: dataUpdate.dataUpdate?.containerNumber,
          value: dataUpdate.dataUpdate?.id,
        });
      }
    }
  }, [dataUpdate]);
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (formik.values.containerInConta !== null) {
      dataUpdate.idContainer = formik.values.containerInConta.value;
      dataUpdate.isDelete = false;
      //!dataUpdate._id is _id of container location
      updateData(dataUpdate._id, dataUpdate);
    } else {
      dataUpdate.idContainer = containerInConta.value;
      dataUpdate.isDelete = true;
      //!dataUpdate._id is _id of container location
      updateData(dataUpdate._id, dataUpdate);
    }
  };

  // check show button action
  const showButtonAction = () => {
    return (
      <button
        type="submit"
        onClick={(e) => handleUpdateData(e)}
        className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
        disabled={!formik.isValid}
      >
        Cập nhật
      </button>
    );
  };

  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      containerInConta,
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">
          Cập nhật vị trí container
        </p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>
        <form>
          <div className="mb-8">
            <div className="flex flex-col w-full">
              <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Chọn container <span className="text-red-500">*</span>
                </label>
                <ContainerInContaDropDown
                  value={formik.values.containerInConta}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.containerInConta}
                  touched={formik.touched.containerInConta}
                  isUpdating={containerInConta}
                />
              </div>
            </div>
          </div>

          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
