import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import {
  deleteANotificationAction,
  deleteAllNotificationAction,
  getAllNotificationAction,
  updateAllStatusNotificationAction,
  updateStatusNotificationAction,
} from "../../redux/slices/User/UserSlice";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { NotificationProps } from "../../types/apiType";
import { MdMarkEmailRead, MdMarkEmailUnread } from "react-icons/md";
import { dateTimeGateInOutHelper } from "../../utils/DateHelper";
import bell from "../../img/bell.png";
import { BsTrash } from "react-icons/bs";
import Swal from "sweetalert2";
interface NotificationListProps {
  showRedDot: boolean;
  listNotification: Array<NotificationProps>;
  totalResult: number;
  offSetValue: number;
  limitNotification: number;
  totalUnRead: number;
}

const NotificationListComponent = ({
  showRedDot,
  listNotification,
  totalResult,
  totalUnRead,
}: NotificationListProps) => {
  const dispatch = useAppDispatch();

  const [offSetValue, setOffSetValue] = useState(0);

  const [limitNotification] = useState(6);

  const handleReadAll = () => {
    dispatch(updateAllStatusNotificationAction("no parameter"));
  };

  const handleChangeStatus = (id: string) => {
    dispatch(updateStatusNotificationAction(id));
  };

  const handleSeeMoreClick = (offset: number) => {
    const updatedOffset = offset + limitNotification;
    const params1 = {
      keyword: "",
      limitNotification,
      offset: updatedOffset,
    };
    setOffSetValue(updatedOffset);
    dispatch(getAllNotificationAction(params1));
  };
  const handleDeleteANotification = (id: string) => {
    Swal.fire({
      title: "Bạn có chắc là muốn xóa thông báo này không?",
      showDenyButton: true,
      confirmButtonText: "OK",
      denyButtonText: "Chờ một tí",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(deleteANotificationAction(id));
        Swal.fire("Đã xóa thông báo thành công!", "", "success");
      }
    });
  };

  const handleDeleteAllNotification = () => {
    Swal.fire({
      title: "Bạn có chắc là muốn xóa tất cả thông báo không?",
      showDenyButton: true,
      confirmButtonText: "OK",
      denyButtonText: "Chờ một tí",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(deleteAllNotificationAction());
        Swal.fire("Đã xóa tất cả thông báo thành công!", "", "success");
      }
    });
  };

  return (
    <Menu as="div" className="ml-3 relative z-10">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full bg-white"
                src={bell}
                // onClick={handleLinkClick}
                alt="bell avatar"
              />
            </Menu.Button>
          </div>

          {showRedDot && (
            <span className="absolute top-0 right-0 h-3 w-3 bg-red-500 rounded-full text-xs text-center text-white"></span>
            // <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
            //   {totalUnRead}
            // </div>
          )}
          {open && (
            <div className="custom-triangle custom-triangle-5 absolute"></div>
          )}
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute top-10 right-0 w-[512px] max-h-[360px] overflow-hidden overflow-y-auto rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="w-full max-w-lg p-3 bg-white border border-gray-200 rounded-md shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <div className="flex items-center justify-between mb-4">
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    {listNotification.length > 0
                      ? "Thông báo từ hệ thống"
                      : "Chưa có thông báo từ hệ thống"}
                  </h5>
                  {showRedDot ? (
                    <div
                      className="cursor-pointer text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                      onClick={() => handleReadAll()}
                    >
                      Đánh dấu là đã đọc
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                      onClick={() => handleDeleteAllNotification()}
                    >
                      {listNotification.length > 0 ? "Xóa tất cả" : null}
                    </div>
                  )}
                </div>
                {listNotification.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <>
                        <div className="flow-root">
                          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                            <li
                              className={`relative py-3 sm:py-4  rounded-lg p-2 ${
                                item.status
                                  ? "hover:cursor-default"
                                  : "hover:cursor-pointer hover:bg-gray-200"
                              }`}
                              onClick={() => {
                                if (item.status === false) {
                                  handleChangeStatus(item._id);
                                }
                              }}
                            >
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  {item.status ? (
                                    <MdMarkEmailRead className="w-8 h-8 rounded-full " />
                                  ) : (
                                    <MdMarkEmailUnread className="w-8 h-8 rounded-full " />
                                  )}
                                </div>
                                <div className="flex-1 min-w-0 ms-4 ">
                                  <p className="text-sm font-medium text-gray-900 wrap dark:text-white">
                                    {item.description}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {dateTimeGateInOutHelper(item.createdAt)}
                                  </p>
                                </div>
                                <div className="flex flex-col items-center justify-between h-full ">
                                  <div className=" inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {item.status ? (
                                      <BsTrash
                                        className="absolute top-8 h-4 w-4 text-red-400 cursor-pointer hover:text-red-600"
                                        onClick={() =>
                                          handleDeleteANotification(item._id)
                                        }
                                      />
                                    ) : (
                                      <span className="inline-flex items-center justify-center w-2 h-2 ms-2 text-xs font-semibold text-[#0866ff] bg-[#0866ff] rounded-full"></span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </Menu.Item>
                ))}
                {totalResult !== undefined &&
                  offSetValue + limitNotification < totalResult && (
                    <div
                      onClick={() => handleSeeMoreClick(offSetValue)}
                      className={
                        "block py-2 text-sm text-gray-700 cursor-pointer hover:text-blue-500"
                      }
                    >
                      {listNotification.length > 0 ? "Xem thêm >>>" : null}
                    </div>
                  )}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default NotificationListComponent;
