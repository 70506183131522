import { InvoiceApiProps } from "../types/apiType";
import { PaymentStatusHelper } from "./StatusHelper";

export const dataAnalyticHelper = (
  data: Array<InvoiceApiProps>,
  nameAnalytic: string,
  description: string
) => {
  type dataAnalyticProps = {
    labels: string[];
    datasets: [
      {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor: string[];
        borderWidth: number;
      }
    ];
  };
  const sizeCounts: { [size: string]: number } = {};

  data.forEach((item) => {
    if (item && item[nameAnalytic] !== undefined) {
      const size = item[nameAnalytic];
      if (size !== undefined) {
        if (size in sizeCounts) {
          sizeCounts[size]++;
        } else {
          sizeCounts[size] = 1;
        }
      }
    }
  });

  let labels = Object.keys(sizeCounts);
  const dataValues = Object.values(sizeCounts);

  if (nameAnalytic === "containerCondition") {
    labels = labels.map((item) => item);
  }
  if (nameAnalytic === "paymentStatus") {
    labels = labels.map((item) => PaymentStatusHelper(item));
  }

  const dataAnalytic: dataAnalyticProps = {
    labels,
    datasets: [
      {
        label: description,
        data: dataValues,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return dataAnalytic;
};

export const dataSelectOptionAnalyticHelper = (
  data: Array<InvoiceApiProps>,
  nameAnalytic: string,
  labelAnalytic: string,
  description: string
) => {
  type dataAnalyticProps = {
    labels: string[];
    datasets: [
      {
        label: string;
        data: number[];
        backgroundColor: string;
        borderColor: string;
        borderWidth: number;
      }
    ];
  };
  const sizeCounts: { [size: string]: number } = {};

  data.forEach((item) => {
    if (item) {
      const nameAnalyticValue = item[nameAnalytic];
      if (nameAnalyticValue && nameAnalyticValue[labelAnalytic] !== undefined) {
        const size = nameAnalyticValue[labelAnalytic];

        if (size !== undefined) {
          if (size in sizeCounts) {
            sizeCounts[size]++;
          } else {
            sizeCounts[size] = 1;
          }
        }
      }
    }
  });

  let labels = Object.keys(sizeCounts);
  const dataValues = Object.values(sizeCounts);

  const dataAnalytic: dataAnalyticProps = {
    labels,
    datasets: [
      {
        label: description,
        data: dataValues,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 5,
      },
    ],
  };
  return dataAnalytic;
};
