import axiosClient from "./axiosClient";

const module = "counter";

const countersApi = {
  getCounter: () => {
    const url = `/${module}/getCounter`;
    return axiosClient.get(url);
  },
  getCounterBookingSale: () => {
    const url = `/${module}/getCounterBookingSale`;
    return axiosClient.get(url);
  },
};

export default countersApi;
