import { ColumnType } from "../../types/apiType";

export const CompareTwoArrayHelper = (
  array1: Array<ColumnType>,
  array2: Array<ColumnType>
) => {
  // If the lengths of the arrays are different, they are definitely different
  if (array1.length !== array2.length) {
    return true;
  }

  // Iterate through each element of both arrays
  for (let i = 0; i < array1.length; i++) {
    // Compare each property of the objects in the arrays
    if (JSON.stringify(array1[i]) !== JSON.stringify(array2[i])) {
      // If any element is different, return true
      return true;
    }
  }

  // If all elements are the same, return false
  return false;
};
