import { ApiParams, UserApiProps } from "../types/apiType";
import axiosClient, { axiosNotToken } from "./axiosClient";

const module = "users";

const usersApi = {
  adminRegister: (body: UserApiProps) => {
    const url = `/${module}/register`;
    return axiosClient.post(url, body);
  },
  login: (body: UserApiProps) => {
    const url = `/${module}/login`;
    return axiosNotToken.post(url, body);
  },
  updatePassword: (id: string | undefined, body: UserApiProps) => {
    const url = `/${module}/update-password`;
    return axiosClient.put(url, body);
  },
  update: (id: string | undefined, body: UserApiProps) => {
    const url = `/${module}/update/${id}`;
    return axiosClient.put(url, body);
  },
  updateStatusNotification: (id: string | undefined) => {
    const url = `/${module}/notification/${id}`;
    return axiosClient.put(url);
  },
  updateAllStatusNotification: () => {
    const url = `/${module}/update-all-notification`;
    return axiosClient.put(url);
  },
  delete: (id: string) => {
    const url = `/${module}/delete/${id}`;
    return axiosClient.delete(url);
  },
  deleteANotification: (id: string) => {
    const url = `/${module}/notification/${id}`;
    return axiosClient.delete(url);
  },
  deleteAllNotification: () => {
    const url = `/${module}/delete-all-notification`;
    return axiosClient.delete(url);
  },
  getUser: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.get(url);
  },
  getAll: (params: ApiParams) => {
    const url = `/${module}/search`;
    return axiosClient.get(url, { params });
  },
  getAllNotification: (params: ApiParams) => {
    const url = `/${module}/get-notifications`;
    return axiosClient.get(url, { params });
  },
  getAllWithoutLimit: () => {
    const url = `/${module}/getAll`;
    return axiosClient.get(url);
  },
};

export default usersApi;
