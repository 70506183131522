import { useEffect, useState } from "react";
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
// } from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
// import "moment/locale/vi"; // Import the Vietnamese locale\
import DatePicker from "react-datepicker";
import { dateHelper } from "../../utils/DateHelper";
import { useAppDispatch } from "../../hooks/reduxHooks";
import {
  clearInvoicesAction,
  getAllWithoutLimitAction,
  selectInvoice,
} from "../../redux/slices/Invoice/invoiceSlice";
import {
  getAllWithoutLimitAction as getAllSupplier,
  selectSupplier,
} from "../../redux/slices/Supplier/SupplierSlice";

import {
  getAllWithoutLimitAction as getAllServiceSupplier,
  selectServiceSuppliers,
} from "../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import { useSelector } from "react-redux";
import {
  dataAnalyticHelper,
  dataSelectOptionAnalyticHelper,
} from "../../utils/dataAnalyticHelper";
import { BiCube } from "react-icons/bi";
import { LuContainer } from "react-icons/lu";
import { ServiceSupplierApiProps, SupplierApiProps } from "../../types/apiType";
import { v4 as uuidv4 } from "uuid";
import {
  optionDoughnutChart,
  optionLineChart,
} from "../../constants/chartOption";
import { CSVLink } from "react-csv";
import { ExportDataFormatter } from "../../utils/ExportDataFormatter";
import ToastSuccess from "../../components/Toast/success";
import { dataAnalyticWithQuantityHelper } from "../../utils/dataAnalyticWithQuantityHelper";
import {
  getAllNotificationAction,
} from "../../redux/slices/User/UserSlice";
import { paramsNotification } from "../../utils/paramsHelper";
// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title
// );

const InvoiceAnalyst = () => {
  const dispatch = useAppDispatch();
  const invoiceData = useSelector(selectInvoice);
  const supplierData = useSelector(selectSupplier);
  const serviceSupplierData = useSelector(selectServiceSuppliers);
  const { data, appError } = invoiceData;
  const { data: dataSupplier } = supplierData;
  const { data: dataServiceSupplier } = serviceSupplierData;
  const data1 = {
    labels: [
      "Red",
      "Blue",
      "Yellow",
      "Green",
      "Purple",
      "Orange",
      "Red2",
      "Blue2",
      "Yellow2",
      "Green2",
      "Purple2",
      "Orange2",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 5, 3],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 5,
      },
    ],
  };

  const [contQuantityOfMonth, setContQuantityOfMonth] = useState(0);
  const [totalInvoiceOfMonth, setTotalInvoiceOfMonth] = useState(0);
  const [totalPaymentStatusOfMonth, setTotalPaymentStatusOfMonth] = useState(0);
  const [supplierNotIncluded, setSupplierNotIncluded] =
    useState<Array<SupplierApiProps>>();
  const [serviceSupplierNotIncluded, setServiceSupplierNotIncluded] =
    useState<Array<ServiceSupplierApiProps>>();

  const currentDate = new Date(); // Get the current date
  currentDate.setDate(1); // Set the day to 1
  const [getByMonth, setGetByMonth] = useState<Date>(currentDate);

  const [startQuarter, setStartQuarter] = useState<Date | null>(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endQuarter, setEndQuarter] = useState<Date | null>(null);

  const handleChange = ([newStartQuarter, newEndQuarter]: [
    Date | null,
    Date | null
  ]) => {
    setStartQuarter(newStartQuarter);
    setEndQuarter(newEndQuarter);
  };

  const getQuarter = (date: Date): number => {
    const month = date.getMonth();
    return Math.floor(month / 3) + 1;
  };

  let title = "";

  if (startQuarter && endQuarter) {
    const startQuarterNumber = getQuarter(startQuarter);
    const endQuarterNumber = getQuarter(endQuarter);

    if (startQuarterNumber === endQuarterNumber) {
      title = `Thống kê hóa đơn quý ${startQuarterNumber}`;
    } else {
      title = `Thống kê hóa đơn từ quý ${startQuarterNumber} đến quý ${endQuarterNumber}`;
    }
  } else {
    const currentMonth = new Date().getMonth() + 1;
    title = `Thống kê hóa đơn tháng ${currentMonth}`;
  }

  useEffect(() => {
    document.title = title;
    const params = {
      keyword: "",
      offset: 0,
      limit: 10,
    };
    dispatch(getAllNotificationAction(paramsNotification));
    dispatch(getAllSupplier(params));
    dispatch(getAllServiceSupplier(params));
  }, []);

  useEffect(() => {
    dispatch(clearInvoicesAction());
    if (startQuarter !== null && endQuarter !== null) {
      let toUpdateEndQuarter = new Date(dateHelper(endQuarter));
      toUpdateEndQuarter.setMonth(endQuarter.getMonth() + 2);
      dispatch(
        getAllWithoutLimitAction({
          from: dateHelper(startQuarter),
          to: dateHelper(toUpdateEndQuarter),
        })
      );
    } else {
      if (getByMonth !== null) {
        let toUpdate = new Date(dateHelper(getByMonth));
        toUpdate.setMonth(toUpdate.getMonth() + 1);
        dispatch(
          getAllWithoutLimitAction({
            from: dateHelper(getByMonth),
            to: toUpdate,
            // to: dateHelper(getByMonth),
          })
        );
      }
    }
  }, [dispatch, endQuarter, startQuarter, getByMonth]);

  useEffect(() => {
    const contQuantityAfterCount = dataAnalyticHelper(
      data,
      "size",
      "Số lượng container"
    ).datasets[0].data.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const totalInvoiceAfterCount = data.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue.invoicePrice!),
      0
    );

    const totalPaymentStatusAfterCount = data.reduce(
      (accumulator, currentValue) =>
        currentValue.paymentStatus === 0 ? accumulator + 1 : accumulator + 0,
      0
    );

    setContQuantityOfMonth(contQuantityAfterCount);
    setTotalInvoiceOfMonth(totalInvoiceAfterCount);
    setTotalPaymentStatusOfMonth(totalPaymentStatusAfterCount);

    const filterSupplierNotIncludes = dataSupplier.filter(
      (item) =>
        !dataSelectOptionAnalyticHelper(
          data,
          "supplier",
          "label",
          "Hãng khai thác"
        ).labels.includes(item.codeSupplier)
    );
    // service supplier
    setSupplierNotIncluded(filterSupplierNotIncludes);
    const filterServiceSupplierNotIncludes = dataServiceSupplier.filter(
      (item) =>
        !dataSelectOptionAnalyticHelper(
          data,
          "serviceSupplier",
          "label",
          "Vị trí"
        ).labels.includes(item.codeServiceSupplier)
    );

    setServiceSupplierNotIncluded(filterServiceSupplierNotIncludes);
  }, [data, dataServiceSupplier, dataSupplier]);
  return (
    <div className="bg-blue-100 h-full pb-20">
      <div className="bg-slate-50 mx-2 rounded-2xl p-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 overflow-hidden overflow-x-auto">
          <div className="h-16 flex items-center w-full ">
            <div className="flex flex-row ml-2 w-full">
              <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
              <div className="flex-none flex-shrink-0">
                <p className="font-sans font-semibold text-3xl">{title}</p>
                <p className="font-sans text-sm italic">{title} Conta</p>
              </div>
              {appError?.statusCode === 500 &&
              appError.message?.includes("Token đã hết hạn") ? null : (
                <div className="flex flex-1 justify-between lg:mt-0 ml-4">
                  <div className="flex lg:mt-0 ml-4">
                    <DatePicker
                      selected={startQuarter}
                      onChange={handleChange}
                      selectsRange
                      startDate={startQuarter}
                      endDate={endQuarter}
                      dateFormat="yyyy, QQQ"
                      showQuarterYearPicker
                      className="bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    />
                    <DatePicker
                      selected={getByMonth}
                      onChange={(date) => setGetByMonth(date as Date)}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      className=" bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  {data.length > 0 && (
                    <CSVLink
                      filename={`Hóa đơn-${Date.now()}.csv`}
                      data={ExportDataFormatter(data)}
                      onClick={() => {
                        ToastSuccess("Tải về file Excel thành công!");
                      }}
                      className="flex-shrink-0 ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                    >
                      <span>Xuất excel hóa đơn</span>
                    </CSVLink>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=" mb-6 p-4">
          <div className=" flex justify-between lg:justify-end space-x-2 overflow-hidden overflow-x-auto">
            <div className="flex flex-col flex-shrink-0 p-8 bg-gray-200 rounded-lg border border-gray-300 w-56">
              <span className="text-xl font-medium text-gray-800">
                Tổng số hóa đơn
              </span>
              <div className="flex items-center text-gray-800 space-x-3">
                <LuContainer className="text-2xl" />
                <span className="text-xl font-bold">
                  {new Intl.NumberFormat("de-DE").format(contQuantityOfMonth)}
                </span>
              </div>
            </div>
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-56">
              <span className="text-xl font-medium text-gray-800">
                Chưa thanh toán
              </span>
              <div className="flex items-center text-red-600 space-x-3">
                <BiCube className="text-2xl" />
                <span className="text-xl font-bold">
                  {new Intl.NumberFormat("de-DE").format(
                    totalPaymentStatusOfMonth
                  )}
                </span>
              </div>
            </div>
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-56">
              <span className="text-xl font-medium text-gray-800">
                Tổng hóa đơn
              </span>
              <div className="flex items-center text-green-700 space-x-1">
                {/* <BiDollar className="text-2xl -ml-1" /> */}
                <span className="text-xl font-bold">
                  {new Intl.NumberFormat("de-DE").format(totalInvoiceOfMonth)}{" "}
                  &#8363;
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-col mt-4 p-4">
          <div className="flex flex-col sm:flex-row mt-4 h-[1000px] sm:h-full">
            <div className="w-72 h-72 flex-1 flex-col mb-12 sm:mt-0">
              {dataAnalyticWithQuantityHelper(data, "size", "Số lượng cont")
                ?.datasets[0]?.data.length === 0 ? (
                <div>
                  Không có thông tin của số lượng container trong tháng này!
                </div>
              ) : (
                <>
                  <Doughnut
                    data={dataAnalyticWithQuantityHelper(
                      data,
                      "size",
                      "Số lượng container"
                    )}
                    options={optionDoughnutChart}
                  />
                  <div className="text-center font-medium text-xl mt-4 italic">
                    Biểu đồ kích thước container
                  </div>
                </>
              )}
            </div>
            <div className="w-72 h-72 flex-1 mb-12 sm:mt-0">
              {dataAnalyticWithQuantityHelper(
                data,
                "containerCondition",
                "Tình trạng container"
              )?.datasets[0]?.data.length === 0 ? (
                <div>
                  Không có thông tin của tình trạng container trong tháng này!
                </div>
              ) : (
                <>
                  <Doughnut
                    data={dataAnalyticWithQuantityHelper(
                      data,
                      "containerCondition",
                      "Tình trạng container"
                    )}
                    options={optionDoughnutChart}
                  />
                  <div className="text-center font-medium text-xl mt-4 italic">
                    Biểu đồ tình trạng container
                  </div>
                </>
              )}
            </div>
            <div className="w-72 h-72 flex-1">
              {dataAnalyticHelper(
                data,
                "paymentStatus",
                "Trạng thái thanh toán"
              )?.datasets[0]?.data.length === 0 ? (
                <div>
                  Không có thông tin của trạng thái thanh toán trong tháng này!
                </div>
              ) : (
                <>
                  <Doughnut
                    data={dataAnalyticHelper(
                      data,
                      "paymentStatus",
                      "Trạng thái thanh toán"
                    )}
                    options={optionDoughnutChart}
                  />
                  <div className="text-center font-medium text-xl mt-4 italic">
                    Biểu đồ trạng thái thanh toán
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full h-[200px] sm:h-[400px] mt-20 flex flex-col sm:flex-row">
            {dataSelectOptionAnalyticHelper(
              data,
              "supplier",
              "label",
              "Hãng khai thác"
            )?.datasets[0]?.data.length === 0 ? (
              <div>Không có thông tin của hãng khai thác!</div>
            ) : (
              <>
                <Line
                  options={optionLineChart("Biểu đồ hãng khai thác")}
                  data={dataSelectOptionAnalyticHelper(
                    data,
                    "supplier",
                    "label",
                    "Hãng khai thác"
                  )}
                />
                <div className="flex-col gap-4">
                  <span className="text-center font-medium text-xl mt-4 italic">
                    Các hãng khai thác không hợp tác bao gồm:
                  </span>
                  <div className="flex gap-2 mt-5 flex-wrap">
                    {supplierNotIncluded?.map((item) => {
                      return (
                        <div key={uuidv4()}>
                          <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-red-400">
                            {item.codeSupplier}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="w-full h-[400px] sm:h-[400px] mt-32 sm:mt-20 flex flex-col sm:flex-row">
            {dataSelectOptionAnalyticHelper(
              data,
              "serviceSupplier",
              "label",
              "Vị trí container"
            )?.datasets[0]?.data.length === 0 ? (
              <div>Không có thông tin của vị trí container!</div>
            ) : (
              <>
                <Line
                  options={optionLineChart("Biểu đồ vị trí container")}
                  data={dataSelectOptionAnalyticHelper(
                    data,
                    "serviceSupplier",
                    "label",
                    "Vị trí container"
                  )}
                />
                <div className="flex-col gap-4">
                  <span className="text-center font-medium text-xl mt-4 italic">
                    Các depot không hợp tác bao gồm:
                  </span>
                  <div className="flex gap-2 mt-5 flex-wrap">
                    {serviceSupplierNotIncluded?.map((item) => {
                      return (
                        <div key={uuidv4()}>
                          <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-red-400">
                            {item.codeServiceSupplier}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceAnalyst;
