import React from "react";
// icons
import { BsFillCloudUploadFill, BsImages } from "react-icons/bs";
import { GiOpenGate } from "react-icons/gi";
import { FaPencilAlt } from "react-icons/fa";
//redux
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { updateMNRAction } from "../../../redux/slices/Container/containerSlice";
//types
import { ContainerApiProps, UserApiProps } from "../../../types/apiType";
//libraries
import Swal from "sweetalert2";
import { Socket } from "socket.io-client";
//utils
import {
  containerSizeHelper,
  containerStatusHelper,
} from "../../../utils/StatusHelper";
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import { dateTimeGateInOutHelper } from "../../../utils/DateHelper";
import { updateMessageWithStatusNotification } from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import { getAllNotificationAction } from "../../../redux/slices/User/UserSlice";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
// Define the props type for ListItem
interface ListItemProps {
  data: Array<ContainerApiProps> | null;
  openFormUpdate: (id: string) => void;
  openFormUpdateImages: (id: string) => void;
  openFormUpdateImagesAfterRepair: (id: string) => void;
  openFormUpdateImagesGateOut: (id: string) => void;
  openFormSellCont: (id: string) => void;
  openFormGateIn: (id: string) => void;
  openFormGateOut: (id: string) => void;
  openSlide: (id: string, currentNameField: string) => void;
  socket: Socket;
  userAuth?: UserApiProps;
}

export const ListItem: React.FC<ListItemProps> = ({
  data,
  openFormUpdate,
  socket,
  userAuth,
  openSlide,
  openFormUpdateImages,
  openFormUpdateImagesAfterRepair,
  openFormUpdateImagesGateOut,
  openFormSellCont,
  openFormGateIn,
  openFormGateOut,
}) => {
  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getAllNotificationAction(paramsNotification));
  };
  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);

  const dispatch = useAppDispatch();

  const handleOpenFormUpdate = (id: string | undefined) => {
    if (id !== undefined) {
      openFormUpdate(id);
    }
  };

  const openSlideShow = (id: string, currentNameField: string) => {
    openSlide(id, currentNameField);
  };
  const openFormUpdateImagesAfterRepairForm = (id: string) => {
    openFormUpdateImagesAfterRepair(id);
  };
  const handleOpenFormUpdateGateOut = (id: string | undefined) => {
    if (id !== undefined) {
      openFormGateOut(id);
    }
  };

  const handleStartMNR = (id: string | undefined) => {
    Swal.fire({
      title: "Xác nhận bắt đầu sửa chữa container?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed && id !== undefined) {
        const dataUpdate = {
          _id: id,
          contStatus: 4,
          alreadyMNR: true,
          dateReceiveMNR: new Date(),
        };
        const action = await dispatch(updateMNRAction(dataUpdate));

        if (updateMNRAction.fulfilled.match(action)) {
          socket.emit("update-data", {
            room: "container",
            updateData: updateMessageWithStatusNotification(
              userAuth?.currentName,
              action.payload.newData.containerNumber,
              action.payload.newData.supplier.label,
              action.payload.newData.serviceSupplier.label,
              "bắt đầu sửa chữa",
              containerStatusHelper(action.payload.newData.contStatus)
            ),
          });
          dispatch(getAllNotificationAction(paramsNotification));
          ToastSuccess(action.payload.message);
        } else {
          ToastError(action.payload as string);
        }
      } else if (result.isDenied) {
        Swal.fire("Bạn vẫn chưa bắt đầu sửa chữa!", "", "info");
      }
    });
  };
  return (
    <>
      {data?.map((item, index) => (
        <tr className="bg-gray-50" key={item._id}>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {index + 1}
          </td>
          {userAuth?.role === 0 ? (
            <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
              <div>
                {!item?.supplier?.label ? "---" : item?.supplier?.label}
              </div>
            </td>
          ) : null}
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.lineOperation?.label ? "---" : item?.lineOperation?.label}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.serviceSupplier?.label
                ? "---"
                : item?.serviceSupplier?.label}
            </div>
            {/* <div>{!item?.client?.label ? "---" : item?.client?.label}</div> */}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.containerNumber ? "---" : item?.containerNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.containerCondition ? "---" : item?.containerCondition}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {containerSizeHelper(item?.size)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.brandOfUnit?.label ? "---" : item?.brandOfUnit?.label}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.yearOfManufacture ? "---" : item?.yearOfManufacture}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.hasImage && !item?.images?.length ? (
              "Chưa có hình"
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "images")}
              >
                <div className="bg-blue-600 p-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5" />
                  Xem hình
                </div>
              </div>
            )}
            {/* <div
              className="bg-blue-600 px-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
              onClick={() => openFormUpdateImagesForm(item?._id!)}
            >
              <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer mt-3" />
              Upload
            </div> */}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 ">
            {!item?.hasImagesAfterRepair && !item?.imagesAfterRepair?.length ? (
              "Chưa có hình"
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "imagesAfterRepair")}
              >
                <div className="bg-blue-600 p-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
              </div>
            )}

            <div
              className="bg-blue-600 px-2 rounded flex items-center mt-2 gap-2 text-white w-[102px] group cursor-pointer"
              onClick={() => openFormUpdateImagesAfterRepairForm(item?._id!)}
            >
              <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer mt-3" />
              Upload
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.hasImagesGateOut && !item?.imagesGateOut?.length ? (
              "Chưa có hình"
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "imagesGateOut")}
              >
                <div className="bg-blue-600 p-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
              </div>
            )}

            {/* <div
              onClick={() => openFormUpdateImagesGateOutForm(item?._id!)}
              className="bg-blue-600 px-2 rounded flex items-center mt-2 gap-2 text-white w-[102px] group cursor-pointer"
            >
              <BsFillCloudUploadFill className="w-5 h-5  mt-3" />
              Upload
            </div> */}
          </td>

          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item?.contStatus === 0
              ? "Đã sửa xong"
              : containerStatusHelper(item?.contStatus)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.listLifecycle !== undefined &&
              item.listLifecycle.at(-1)?.alreadyGateIn &&
              !item.listLifecycle.at(-1)?.alreadyGateOut &&
              item.listLifecycle.at(-1)?.noteGateIn}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.MNR?.receiverNMR}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {dateTimeGateInOutHelper(item.MNR?.dateReceiveMNR)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.MNR?.dateFinishMNR &&
              dateTimeGateInOutHelper(item.MNR?.dateFinishMNR)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              parseInt(!item?.repairCost ? "0" : item?.repairCost)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.MNR?.noteMNR}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 space-x-2">
            {item.contStatus === 3 && (
              <div
                onClick={() => handleStartMNR(item?._id)}
                className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-amber-500 hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <FaPencilAlt
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span className="text-white">Bắt đầu sửa chữa</span>
              </div>
            )}
            {item.contStatus === 0 || item.contStatus === 4 ? (
              <div
                onClick={() => handleOpenFormUpdate(item?._id)}
                className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <FaPencilAlt
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span className="text-white">Cập nhật container</span>
              </div>
            ) : null}
          </td>
        </tr>
      ))}
    </>
  );
};
