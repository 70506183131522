import React from "react";
import PublicNavbar from "./Public/PublicNavbar";
import { PrivateNavbar } from "./Private/PrivateNavbar";
import { AdminNavbar } from "./Admin/AdminNavbar";
import { useSelector } from "react-redux";
import { selectUser } from "./../../redux/slices/User/UserSlice";
export const Navbar = () => {
  //get user from local storage
  const userData = useSelector(selectUser);
  const { userAuth, notifies, showRedDot, totalResult, totalUnRead } = userData;
  const isAdmin = userAuth?.role === 0 ? true : false;

  return (
    <>
      {!userAuth ? (
        <PublicNavbar isLogin={userAuth} />
      ) : isAdmin ? (
        <AdminNavbar
          isLogin={userAuth}
          notificationData={notifies}
          showRedDot={showRedDot}
          totalResult={totalResult}
          totalUnRead={totalUnRead}
        />
      ) : (
        <PrivateNavbar
          isLogin={userAuth}
          notificationData={notifies}
          showRedDot={showRedDot}
          totalResult={totalResult}
          totalUnRead={totalUnRead}
        />
      )}
    </>
  );
};
