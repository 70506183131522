// const inputArray = [
//   {
//     0: {
//       "Vòng đời": 1,
//       "Từ vị trí": "TMS",
//     },
//     1: {
//       "Vòng đời": 2,
//       "Từ vị trí": "TMS",
//       "Đến vị trí": "CTA",
//     },
//     "Số hóa đơn": "hoadon6CAI",
//     "Số booking": "booking6CAI",
//     "Hãng khai thác": "CAI",
//     "Vị trí container": "CTA",
//   },
//   // ... other objects
// ];

export const transformedArray = (inputArray) => {
  const transformedArray = inputArray.flatMap((obj) => {
    const result = [];

    // Extract properties without nested objects
    const mainObject = {};
    for (const key in obj) {
      if (typeof obj[key] !== "object") {
        mainObject[key] = obj[key];
      }
    }
    result.push(mainObject);

    // Extract properties from nested objects
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        const nestedObject = {};
        for (const subKey in obj[key]) {
          nestedObject[subKey] = obj[key][subKey];
        }
        result.push(nestedObject);
      }
    }

    return result;
  });

  return transformedArray;
};
