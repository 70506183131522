import {
  AnalyticParams,
  ContainerApiParams,
  ContainerApiProps,
  UpdateContNumberProps,
} from "../types/apiType";
import axiosClient, { axiosUpload } from "./axiosClient";

const module = "admin/container";

const containersApi = {
  create: (body: ContainerApiProps) => {
    const url = `/${module}/`;
    return axiosClient.post(url, body);
  },
  update: (id: string | undefined, body: ContainerApiProps) => {
    const url = `/${module}/${id}`;
    return axiosClient.put(url, body);
  },
  updateContainerInDepot: (id: string | undefined, body: ContainerApiProps) => {
    const url = `/${module}/update-container-depot/${id}`;
    return axiosClient.put(url, body);
  },
  sellContainer: (id: string | undefined, body: ContainerApiProps) => {
    const url = `/${module}/sell-container/${id}`;
    return axiosClient.put(url, body);
  },
  cancelDepositContainer: (id: string | undefined) => {
    const url = `/${module}/cancel-deposit-container/${id}`;
    return axiosClient.put(url);
  },
  updateMany: (body: ContainerApiProps) => {
    const url = `/${module}/update-many-container`;
    return axiosClient.put(url, body);
  },
  updateManyReceipt: (body: ContainerApiProps) => {
    const url = `/${module}/update-receipt-many-container`;
    return axiosClient.put(url, body);
  },
  updateContNumber: (body: UpdateContNumberProps) => {
    const url = `/${module}/update-cont-number`;
    return axiosClient.put(url, body);
  },
  updateGateIn: (id: string | undefined, body: ContainerApiProps) => {
    const url = `/${module}/gate-in/${id}`;
    return axiosClient.put(url, body);
  },
  updateGateOut: (id: string | undefined, body: ContainerApiProps) => {
    const url = `/${module}/gate-out/${id}`;
    return axiosClient.put(url, body);
  },
  importContainer: (body: ContainerApiProps) => {
    const url = `/${module}/import-container`;
    return axiosClient.put(url, body);
  },
  sellManyContainer: (body: ContainerApiProps) => {
    const url = `/${module}/sell-many-container/`;
    return axiosClient.put(url, body);
  },
  updateImages: (id: string | undefined, body: FormData) => {
    const url = `/${module}/update-images/${id}`;
    return axiosUpload.put(url, body);
  },
  updateMNR: (id: string | undefined, body: ContainerApiProps) => {
    const url = `/${module}/mnr/${id}`;
    return axiosClient.put(url, body);
  },
  updateSaleCompleted: (id: string | undefined) => {
    const url = `/${module}/sale-completed/${id}`;
    return axiosClient.put(url);
  },
  updateImageAfterRepair: (id: string | undefined, body: FormData) => {
    const url = `/${module}/update-images-after-repair/${id}`;
    return axiosUpload.put(url, body);
  },
  updateImageGateOut: (id: string | undefined, body: FormData) => {
    const url = `/${module}/update-images-gate-out/${id}`;
    return axiosUpload.put(url, body);
  },
  delete: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.delete(url);
  },
  getByID: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.get(url);
  },
  getAll: (params: ContainerApiParams) => {
    const url = `/${module}/search`;
    return axiosClient.get(url, { params });
  },
  getContainerSoldAdmin: (params: ContainerApiParams) => {
    const url = `/${module}/searchContainerSoldAdmin`;
    return axiosClient.get(url, { params });
  },
  getQueryContainer: (params: ContainerApiParams) => {
    const url = `/${module}/query-container`;
    return axiosClient.get(url, { params });
  },
  getMNR: (params: ContainerApiParams) => {
    const url = `/${module}/mnr`;
    return axiosClient.get(url, { params });
  },
  getContainerDepot: (params: ContainerApiParams) => {
    const url = `/${module}/get-container-depot`;
    return axiosClient.get(url, { params });
  },
  getContainerGateIn: (params: ContainerApiParams) => {
    const url = `/${module}/container-gate-in`;
    return axiosClient.get(url, { params });
  },
  getContainerGateOut: (params: ContainerApiParams) => {
    const url = `/${module}/container-gate-out`;
    return axiosClient.get(url, { params });
  },
  getContainerByUser: (params: ContainerApiParams) => {
    const url = `/${module}/get-container-by-user`;
    return axiosClient.get(url, { params });
  },
  getAllWithoutLimit: (params: AnalyticParams) => {
    const url = `/${module}/getAll`;
    return axiosClient.get(url, { params });
  },
  getAllInDepotWithoutLimit: (params: AnalyticParams) => {
    const url = `/${module}/getAllInDepot`;
    return axiosClient.get(url, { params });
  },
  getAllAnalyticByAdmin: (params: AnalyticParams) => {
    const url = `/${module}/getAll`;
    return axiosClient.get(url, { params });
  },
  getAllAnalyticByUser: (params: AnalyticParams) => {
    const url = `/${module}/getAllByUser`;
    return axiosClient.get(url, { params });
  },
  getAllContainerConta: () => {
    const url = `/${module}/get-all-container-conta`;
    return axiosClient.get(url);
  },
  getAllContainerStorage: () => {
    const url = `/${module}/get-all-container-storage`;
    return axiosClient.get(url);
  },
  getAllContainerInContaFullOptionsWithoutLimit: () => {
    const url = `/${module}/get-all-container-conta-full`;
    return axiosClient.get(url);
  },
  getAllContainerSoldByMonthWithoutLimit: (params: AnalyticParams) => {
    const url = `/${module}/get-all-container-sold-by-month`;
    return axiosClient.get(url, { params });
  },
};

export default containersApi;
