import { useEffect } from "react";
import { Socket } from "socket.io-client";

const useSocketJoinRoom = (socket: Socket, roomName: string) => {
  useEffect(() => {
    socket.emit("join-room", roomName);
    return () => {
      socket.emit("leave-room", roomName);
    };
  }, [socket, roomName]);
};

export default useSocketJoinRoom;
