// src/features/user/userSlice.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import clientUser from "../../../api/clientUser";
import {
  ApiParams,
  AppErrorProps,
  ClientApiProps,
} from "../../../types/apiType";
import { ClientUserState } from "../../../types/reduxTypes";

const initialDepot: ClientApiProps = {
  clientName: "",
  email: "",
  phoneNumber: "",
  phoneNumberBackup: "",
  listUser: [],
  user: { currentName: "", userName: "" },
  createdAt: "",
};

const initialAppError: AppErrorProps = {
  message: "",
  stack: "",
  statusCode: 0, // You can set the default values you prefer
};

const initialState: ClientUserState = {
  loading: false,
  data: [],
  appError: initialAppError,
  serverError: undefined,
  totalPage: 0,
  clientUser: initialDepot,
};

export const addAction = createAsyncThunk(
  "clientUser/add",
  async (
    clientAdmin: ClientApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      //make http call
      const response = await clientUser.create(clientAdmin);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all action
export const getAllAction = createAsyncThunk(
  "clientUser/getAll",
  async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await clientUser.getAll(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get all without limit action
export const getAllWithoutLimitAction = createAsyncThunk(
  "clientUser/getAllWithoutLimit",
  async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await clientUser.getAllByUserId();
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//update action
export const updateAction = createAsyncThunk(
  "clientUser/update",
  async (params: ClientApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await clientUser.update(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//share action
export const shareAction = createAsyncThunk(
  "clientUser/share",
  async (params: ClientApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await clientUser.share(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//update profile
export const getByIdAction = createAsyncThunk(
  "clientUser/supplier",
  async (id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await clientUser.getByID(id);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//delete user
export const deleteAction = createAsyncThunk(
  "clientUser/delete",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await clientUser.delete(_id);
      if (response.data.result) {
        const results = {
          _id,
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

const clientUserSlice = createSlice({
  name: "clientUser",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addAction.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action?.payload;
      state.data = state.data?.length > 0 ? state.data : [];
      state.data = [data, ...state.data];
      state.appError.message = undefined;
      state.serverError = undefined;
    });
    builder.addCase(addAction.rejected, (state, action) => {
      state.loading = false;
      state.appError.message = action?.payload as string;
      state.serverError = action.error.message;
    });

    //get All
    builder
      .addCase(getAllAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get All without limit
    builder
      .addCase(getAllWithoutLimitAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllWithoutLimitAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get profile
    builder
      .addCase(getByIdAction.pending, (state, action) => {
        // state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.fulfilled, (state, action) => {
        // state.loading = false;
        state.clientUser = action?.payload?.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //delete data by id
    builder
      .addCase(deleteAction.fulfilled, (state, action) => {
        // delete row data in store
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(deleteAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //update data
    builder
      .addCase(updateAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //share data
    builder
      .addCase(shareAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(shareAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
  },
  reducers: {},
});
export const selectClientUser = (state: RootState) => state.clientUsers;
export default clientUserSlice.reducer;
