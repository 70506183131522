import React from "react";

interface HeaderProps {
  titles: Array<String>;
}

const TableHeaderNoSelect: React.FC<HeaderProps> = ({ titles }) => {
  return (
    <tr>
      {titles.map((title, index) => (
        <th
          key={index}
          scope="col"
          className="px-6 py-3 whitespace-nowrap text-left text-xs font-bold text-gray-800 uppercase tracking-wider border border-slate-500"
        >
          {title}
        </th>
      ))}
    </tr>
  );
};

export default TableHeaderNoSelect;
