// src/features/user/userSlice.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import usersApi from "../../../api/userApi";
import { ApiParams, AppErrorProps, UserApiProps } from "../../../types/apiType";
import { UserState } from "../../../types/reduxTypes";

export const initialProfile: UserApiProps = {
  userName: "",
  password: "",
  newPassword: "",
  token: "",
  role: 1, // You can set the default values you prefer
  createdAt: "",
};

export const initialAppError: AppErrorProps = {
  message: "",
  stack: "",
  statusCode: 0, // You can set the default values you prefer
};

export const userLoginFromStorage = () => {
  const userInfoString = localStorage.getItem("userInfo") ?? "";
  return userInfoString ? JSON.parse(userInfoString) : null;
};

const initialState: UserState = {
  userAuth: userLoginFromStorage(),
  loading: false,
  data: [],
  appError: initialAppError,
  serverError: undefined,
  totalPage: 0,
  profile: initialProfile,
  notifies: [],
  showRedDot: false,
  totalResult: 0,
  totalUnRead: 0,
};

export const adminRegisterAction = createAsyncThunk(
  "users/register",
  async (user: UserApiProps, { rejectWithValue, getState, dispatch }) => {
    try {
      //make http call
      const response = await usersApi.adminRegister(user);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//TODO: Login Action
export const loginUserAction = createAsyncThunk(
  "user/login",
  async (user: UserApiProps, { rejectWithValue, getState, dispatch }) => {
    try {
      //make http call
      const response = await usersApi.login(user);
      if (response.data.result) {
        //save user in the local storage
        localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        const results = {
          data: response.data.data,
          notifies: response.data.notifies,
          showRedDot: response.data.showRedDot,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue("Xuất hiện lỗi trong quá trình đăng nhập!");
    }
  }
);

//Logout action
export const logoutAction = createAsyncThunk(
  "/user/logout",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      localStorage.removeItem("userInfo");
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//get all action
export const getAllAction = createAsyncThunk(
  "user/getAll",
  async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await usersApi.getAll(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//get all without limit action
export const getAllWithoutLimitAction = createAsyncThunk(
  "user/getAllWithoutLimit",
  async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await usersApi.getAllWithoutLimit();
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//resetPassword action
export const updateAction = createAsyncThunk(
  "user/reset-password",
  async (
    params: {
      id: string | undefined;
      userName: string | undefined;
      password: string | undefined;
      currentName: string | undefined;
      role: number | undefined;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await usersApi.update(params.id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//update user action
export const updateUserAction = createAsyncThunk(
  "user/update-user",
  async (
    params: {
      id: string | undefined;
      userName: string | undefined;
      password: string | undefined;
      currentName: string | undefined;
      role: number | undefined;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    //http call
    try {
      const response = await usersApi.update(params.id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//update user password action
export const updatePasswordAction = createAsyncThunk(
  "user/update-password",
  async (params: UserApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await usersApi.updatePassword(params._id, params);
      if (response.data.result) {
        const results = {
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//update profile
export const getByIdAction = createAsyncThunk(
  "user/profile",
  async (id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await usersApi.getUser(id);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//delete user
export const deleteAction = createAsyncThunk(
  "user/delete",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await usersApi.delete(_id);
      if (response.data.result) {
        const results = {
          _id,
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteANotificationAction = createAsyncThunk(
  "user/delete-a-notification",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await usersApi.deleteANotification(_id);
      if (response.data.result) {
        const results = {
          _id,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteAllNotificationAction = createAsyncThunk(
  "user/delete-all-notification",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await usersApi.deleteAllNotification();
      if (response.data.result) {
        const results = {
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateStatusNotificationAction = createAsyncThunk(
  "user/update-status-notification",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await usersApi.updateStatusNotification(_id);
      if (response.data.result) {
        const results = {
          id: _id,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const updateAllStatusNotificationAction = createAsyncThunk(
  "user/update-all-status-notification",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await usersApi.updateAllStatusNotification();
      if (response.data.result) {
        const results = {
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const getAllNotificationAction = createAsyncThunk(
  "user/get-all-notifications",
  async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await usersApi.getAllNotification(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalResult: response.data.totalResult,
          totalUnRead: response.data.totalUnRead,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(adminRegisterAction.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action?.payload;
      state.data = state.data?.length > 0 ? state.data : [];
      state.data = [data, ...state.data];
      state.appError.message = undefined;
      state.serverError = undefined;
    });
    builder.addCase(adminRegisterAction.rejected, (state, action) => {
      state.loading = false;
      state.appError.message = action?.payload as string;
      state.serverError = action.error.message;
    });

    //login
    builder
      .addCase(loginUserAction.pending, (state, action) => {
        state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(loginUserAction.fulfilled, (state, action) => {
        state.loading = false;
        state.userAuth = action.payload.data;
        state.notifies = action.payload.notifies;
        state.showRedDot = action.payload.showRedDot;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(loginUserAction.rejected, (state, action) => {
        state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //login
    builder
      .addCase(getAllNotificationAction.fulfilled, (state, action) => {
        state.loading = false;
        const { data } = action?.payload;
        state.notifies = state.notifies?.length > 0 ? state.notifies : [];
        // state.notifies = [...data, ...state.notifies];

        // Merge new data with existing notifies array
        const mergedNotifies = [...state.notifies, ...data];
        // Create a Set to store unique _id values
        const uniqueIds = new Set();
        // Filter the mergedNotifies array based on _id uniqueness
        const filteredNotifies = mergedNotifies.filter((item) => {
          if (!uniqueIds.has(item._id)) {
            uniqueIds.add(item._id);
            return true;
          }
          return false;
        });
        // Update state.notifies with the filtered data
        state.notifies = filteredNotifies;

        state.totalResult = action?.payload?.totalResult;
        state.totalUnRead = action?.payload?.totalUnRead;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllNotificationAction.rejected, (state, action) => {
        state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //logout
    builder
      .addCase(logoutAction.pending, (state) => {
        state.loading = false;
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.loading = false;
        state.appError = initialAppError;
        state.userAuth = undefined;
        state.notifies = [];
        state.serverError = undefined;
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //get All
    builder
      .addCase(getAllAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get All without limit
    builder
      .addCase(getAllWithoutLimitAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllWithoutLimitAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get profile
    builder
      .addCase(getByIdAction.pending, (state, action) => {
        // state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.fulfilled, (state, action) => {
        // state.loading = false;
        state.profile = action?.payload?.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //delete data by id
    builder
      .addCase(deleteAction.fulfilled, (state, action) => {
        // delete row data in store
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(deleteAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(deleteANotificationAction.fulfilled, (state, action) => {
        // delete row data in store
        state.notifies = state.notifies.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(deleteANotificationAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(deleteAllNotificationAction.fulfilled, (state, action) => {
        // delete row data in store
        state.notifies = [];
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(deleteAllNotificationAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //update password
    builder
      .addCase(updatePasswordAction.fulfilled, (state, action) => {
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updatePasswordAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //update data
    builder
      .addCase(updateAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateStatusNotificationAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.notifies.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.notifies[checkIndex].status = true;
        }

        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateStatusNotificationAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(updateAllStatusNotificationAction.fulfilled, (state, action) => {
        state.notifies = state.notifies.map((item) => {
          item.status = true;
          return item;
        });

        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateAllStatusNotificationAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
    //update user data
    builder
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.profile = action?.payload?.newData;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
  },
  reducers: {},
});
export const selectUser = (state: RootState) => state.users;
export default userSlice.reducer;
