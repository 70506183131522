import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Navigation from "./Navigation/NavItem";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/User/UserSlice";
export default function Depot() {
  const location = useLocation();
  const title = "Quản lý Depot";

  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  useEffect(() => {
    document.title = title;
  }, []);

  const isContainerRoute = location.pathname === ROUTES.CONTA_DEPOT;
  // if the current route is Container_MANAGEMENT, redirect to the Container route
  if (userAuth?.role === 4 && isContainerRoute) {
    return <Navigate to={`${ROUTES.CONTA_DEPOT_MNR}`} replace />;
  } else if (isContainerRoute) {
    return <Navigate to={`${ROUTES.CONT_IN_CONTA}`} replace />;
  }

  return (
    <>
      <div className="bg-blue-100 h-full pt-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-xl p-4 drop-shadow-sm">
          {/* Nav features */}
          <Navigation />
        </div>
        <div className="mt-2">
          <Outlet />
        </div>
      </div>
    </>
  );
}
