import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { SupplierApiProps } from "../../../types/apiType";
import { phoneRegExp } from "../../../constants/regex/numberPhone";

interface FormProps {
  closeForm: () => void;
  updateData: (id: string | undefined, data: SupplierApiProps) => Promise<void>;
  addData: (data: SupplierApiProps) => Promise<void>;
  isUpdate: boolean;
  dataUpdate: SupplierApiProps;
}

export const Form = (props: FormProps) => {
  const formSchema = Yup.object().shape({
    codeSupplier: Yup.string().required("*Dữ liệu bắt buộc!"),
    supplierName: Yup.string().required("*Dữ liệu bắt buộc!"),
    phoneNumber: Yup.string().matches(
      phoneRegExp,
      "Số điện thoại không hợp lệ!"
    ),
  });
  //declare value in fields
  const [supplierName, setSupplierName] = useState("");
  const [codeSupplier, setCodeSupplier] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [address, setAddress] = useState("");
  const [representative, setRepresentative] = useState("");

  // get props to index components
  const { closeForm, isUpdate, updateData, addData, dataUpdate } = props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  useEffect(() => {
    focus();
    if (isUpdate) {
      if (dataUpdate) {
        if (dataUpdate.supplierName !== undefined) {
          setSupplierName(dataUpdate.supplierName);
        }
        if (dataUpdate.codeSupplier !== undefined) {
          setCodeSupplier(dataUpdate.codeSupplier);
        }
        if (dataUpdate.address !== undefined) {
          setAddress(dataUpdate.address);
        }
        if (dataUpdate.email !== undefined) {
          setEmail(dataUpdate.email);
        }
        if (dataUpdate.representative !== undefined) {
          setRepresentative(dataUpdate.representative);
        }
        if (dataUpdate.taxCode !== undefined) {
          setTaxCode(dataUpdate.taxCode);
        }
        if (dataUpdate.phoneNumber !== undefined) {
          setPhoneNumber(dataUpdate.phoneNumber);
        }
      }
    }
  }, [dataUpdate]);

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataUpdateNew: SupplierApiProps = {
      supplierName: formik.values.supplierName,
      codeSupplier: formik.values.codeSupplier,
      email: formik.values.email,
      address: formik.values.address,
      phoneNumber: formik.values.phoneNumber,
      taxCode: formik.values.taxCode,
      representative: formik.values.representative,
    };
    updateData(dataUpdate._id, dataUpdateNew);
  };

  const handleAddData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let dataAdd: SupplierApiProps = {
      supplierName: formik.values.supplierName.trim(),
      codeSupplier: formik.values.codeSupplier.trim(),
      email: formik.values.email,
      address: formik.values.address,
      phoneNumber: formik.values.phoneNumber,
      taxCode: formik.values.taxCode,
      representative: formik.values.representative,
    };
    addData(dataAdd);
  };

  // check show button action
  const showButtonAction = () => {
    if (isUpdate) {
      return (
        <button
          type="submit"
          onClick={(e) => handleUpdateData(e)}
          className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Cập nhật
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          onClick={(e) => handleAddData(e)}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Lưu
        </button>
      );
    }
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      supplierName,
      codeSupplier,
      email,
      phoneNumber,
      taxCode,
      address,
      representative,
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">
          {isUpdate ? "Cập nhật dữ liệu" : "Thêm dữ liệu mới"}
        </p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          <div className="mb-8">
            <div className="flex flex-col w-full">
              <div className="relative z-0 w-full group border border-gray-300 rounded-md ">
                <input
                  type="supplierName"
                  name="floating_supplierName"
                  id="floating_supplierName"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.supplierName}
                  onChange={formik.handleChange("supplierName")}
                  onBlur={formik.handleBlur("supplierName")}
                  ref={inputRef}
                />
                <label
                  htmlFor="floating_supplierName"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Tên nhà cung cấp <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.supplierName && formik.errors.supplierName}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="floating_codeSupplier"
                  id="floating_codeSupplier"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.codeSupplier}
                  onChange={formik.handleChange("codeSupplier")}
                  onBlur={formik.handleBlur("codeSupplier")}
                />
                <label
                  htmlFor="floating_codeSupplier"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Mã nhà cung cấp <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.codeSupplier && formik.errors.codeSupplier}
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <input
                  type="text"
                  name="floating_representative"
                  id="floating_representative"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.representative}
                  onChange={formik.handleChange("representative")}
                  onBlur={formik.handleBlur("representative")}
                />
                <label
                  htmlFor="floating_representative"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Người đại diện
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.representative && formik.errors.representative}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.email}
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur("email")}
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Email
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.email && formik.errors.email}
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <input
                  type="text"
                  name="floating_phoneNumber"
                  id="floating_phoneNumber"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange("phoneNumber")}
                  onBlur={formik.handleBlur("phoneNumber")}
                />
                <label
                  htmlFor="floating_phoneNumber"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Số điện thoại
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.phoneNumber && formik.errors.phoneNumber}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="floating_taxCode"
                  id="floating_taxCode"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.taxCode}
                  onChange={formik.handleChange("taxCode")}
                  onBlur={formik.handleBlur("taxCode")}
                />
                <label
                  htmlFor="floating_taxCode"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Mã số thuế
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.taxCode && formik.errors.taxCode}
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <input
                  type="text"
                  name="floating_address"
                  id="floating_address"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.address}
                  onChange={formik.handleChange("address")}
                  onBlur={formik.handleBlur("address")}
                />
                <label
                  htmlFor="floating_address"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Địa chỉ
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.address && formik.errors.address}
              </div>
            </div>
          </div>
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
