import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosNotToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosUpload = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosClient.interceptors.request.use(async (config) => {
  // Retrieve the token from local storage
  const userInfo = localStorage.getItem("userInfo");

  // Check if userInfo is not null
  if (userInfo !== null) {
    // Parse userInfo JSON
    const parsedUserInfo = JSON.parse(userInfo);

    // Check if the token is a string
    if (typeof parsedUserInfo.token === "string") {
      // Remove any surrounding double quotes if present
      const token = parsedUserInfo.token.replace(/(^"|"$)/g, "");
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

axiosUpload.interceptors.request.use(async (config) => {
  // Retrieve the token from local storage
  const userInfo = localStorage.getItem("userInfo");

  // Check if userInfo is not null
  if (userInfo !== null) {
    // Parse userInfo JSON
    const parsedUserInfo = JSON.parse(userInfo);

    // Check if the token is a string
    if (typeof parsedUserInfo.token === "string") {
      // Remove any surrounding double quotes if present
      const token = parsedUserInfo.token.replace(/(^"|"$)/g, "");
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    // if (response && response.data) {
    //   // console.log(response.data);
    //   return response.data;
    // }
    return response;
  },
  (error) => {
    // Handle errors
    throw error.response?.data;
  }
);

axiosNotToken.interceptors.response.use(
  (response) => {
    // if (response && response.data) {
    //   // console.log(response.data);
    //   return response.data;
    // }
    return response;
  },
  (error) => {
    // Handle errors
    throw error;
  }
);

export default axiosClient;
