// import { electricityTariffTier } from "./electricityTariff";
// import electricityTariff from "./electricityTariff";
import { logoContaBase64 } from "./logoContaBase64";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { dateDDMMYYStringHelper, plusAnyDateHelper } from "../DateHelper";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const bookingSalePDF = (data) => {
  const documentDefinition = {
    content: [
      {
        columns: [
          {
            // Image on the left
            image: `${logoContaBase64}`,
            width: 150,
            height: 100,
          },

          {
            // Texts on the right
            stack: [
              {
                text: "CÔNG TY TNHH CONTA VIỆT NAM",
                style: "text",
                bold: true,
                margin: [60, 20, 0, 3],
              },
              // {
              //   text: "CONTA COMPANY LIMITED",
              //   style: "text",
              //   bold: true,
              //   margin: [60, 0, 0, 3],
              // },
              {
                text: "17/4B Điện Biên Phủ, P. 22, Quận Bình Thạnh, TPHCM",
                style: "text",
                margin: [20, 0, 0, 3],
              },
              {
                text: "Email: info@conta.vn",
                style: "text",
                margin: [100, 0, 0, 3],
              },
              {
                text: "Phone: +84 94 355 9993",
                style: "text",
                margin: [90, 0, 0, 0],
              },
            ],
            alignment: "left",
          },
        ],
        margin: [20, 0, 0, 20], // Optional: adjust the spacing below the columns
      },
      {
        text: "DELIVERY ORDER",
        style: "header",
        alignment: "center", // Center-align the header text
      },
      { text: "\n" },
      {
        table: {
          headerRows: 1,
          widths: [120, "*", 50, 80],
          body: [
            [
              { text: "Ref no", fontSize: 12, margin: [2, 18] },
              {
                text: data.bookingManySaleNumber,
                fontSize: 15,
                color: "black",
                bold: true,
                margin: [2, 18],
              },
              { text: "Booking Date", style: "text" },
              {
                text: dateDDMMYYStringHelper(data.bookingDate),
                margin: [2, 18],
                fontSize: 12,
              },
            ],
            [
              { text: "Empty Depot", color: "#FF0000", style: "text" },
              {
                text:
                  data.serviceSupplier.label === "CTA"
                    ? "CONTA DEPOT"
                    : data.serviceSupplier.label,
                color: "#FF0000",
                style: "text",
                fontSize: 16,
              },
              {
                text: "",
                italics: true,
                color: "gray",
              },
              {
                text: "",
                italics: true,
                color: "gray",
              },
            ],
            [
              { text: "Depot Add", fontSize: 12, margin: [2, 18] },
              {
                text: "My Phuoc Tan Van Highway, Tan Dong Hiep ward, Di An city, Binh Duong Province, Vietnam",
                style: "text",
              },

              {
                text: `HOTLINE: ${data.hotline}`,
                bold: true,
                color: "#FF0000",
                colSpan: 2,
                fontSize: 12,
                margin: [2, 18],
              },
            ],
            [
              {
                text: "CUSTOMER:",
                colSpan: 4,
                style: "bigText",
                // decoration: "underline",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
            ],
            [
              {
                text: `SIZE/TYPE: ${data.size}`,
                style: "bigText",
                // decoration: "underline",
              },
              {
                text: `QUANTITY: ${data.contQuantity}`,
                // colSpan: 4,
                style: "bigText",
                // decoration: "underline",
              },
              {
                text: `COMMODITY: ${data.containerCondition}`,
                // colSpan: 4,
                style: "bigText",
                // decoration: "underline",
                colSpan: 2,
              },
              {
                text: "",
                style: "text",
              },
            ],
            [
              {
                text: `CONTAINER NUMBER: ${data.containerNumberBookingSale}`,
                colSpan: 4,
                style: "bigText",
                // decoration: "underline",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
            ],
            [
              {
                text: `COMMENT:\n# Please check container condition carefully before go out of depot`,
                colSpan: 4,
                style: "bigText",
                // decoration: "underline",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
            ],
            [
              {
                text: `Terms and Conditions:\n- Buyer should present this release form at Depot for pick up containers.
- Buyer is required to contact the Depot at least 24 hours prior to pick up to verify availability of the equipment.
- Buyer shall pick up all purchased units within 7 calendar days of release. Buyer is responsible for all storage and related fees on the equipment after ${plusAnyDateHelper(
                  data.createdAt,
                  7
                )}
- Trucker should adhere to Depot regulations. It is the Buyer’s responsibility to communicate all trucker requirements for sales container pickup at depot.`,
                colSpan: 4,
                style: "bigText",
                // decoration: "underline",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
              {
                text: "",
                style: "text",
              },
            ],
          ],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 20, 0, 10], // Optional: adjust the spacing around the header
      },
      text: {
        fontSize: 12,
        margin: [2, 6], // Optional: adjust the spacing between text lines
      },
      textCenter: {
        fontSize: 12,
        margin: [2, 18],
      },
      bigText: {
        fontSize: 14,
        margin: [2, 6], // Optional: adjust the spacing between text lines
      },
    },
  };
  // Create the PDF document
  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  // Download the PDF document
  pdfDocGenerator.download(`${data.bookingManySaleNumber}.pdf`);
};
