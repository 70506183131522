import { useEffect } from "react";
import { Socket } from "socket.io-client";

const useSocketEventHandler = (
  socket: Socket,
  eventName: string,
  eventHandler: (msg: any) => void
) => {
  useEffect(() => {
    if (socket) {
      socket.on(eventName, eventHandler);
      return () => {
        socket.off(eventName, eventHandler);
      };
    }
  }, [socket, eventName, eventHandler]);
};

export default useSocketEventHandler;
