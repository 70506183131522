// src/features/user/userSlice.ts
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import supplierApi from "../../../api/supplierApi";
import {
  ApiParams,
  AppErrorProps,
  SupplierApiProps,
} from "../../../types/apiType";
import { SupplierState } from "../../../types/reduxTypes";

export const initialSupplier: SupplierApiProps = {
  supplierName: "",
  codeSupplier: "",
  email: "",
  phoneNumber: "",
  taxCode: "",
  address: "",
  representative: "",
  createdAt: "",
};

const initialAppError: AppErrorProps = {
  message: "",
  stack: "",
  statusCode: 0, // You can set the default values you prefer
};

const initialState: SupplierState = {
  loading: false,
  data: [],
  appError: initialAppError,
  serverError: undefined,
  totalPage: 0,
  supplier: initialSupplier,
};

export const addAction = createAsyncThunk(
  "suppliers/add",
  async (
    supplier: SupplierApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      //make http call
      const response = await supplierApi.create(supplier);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all action
export const getAllAction = createAsyncThunk(
  "suppliers/getAll",
  async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await supplierApi.getAll(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//resetPassword action
export const updateAction = createAsyncThunk(
  "suppliers/update",
  async (params: SupplierApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await supplierApi.update(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//update profile
export const getByIdAction = createAsyncThunk(
  "suppliers/supplier",
  async (id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await supplierApi.getByID(id);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//delete user
export const deleteAction = createAsyncThunk(
  "suppliers/delete",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await supplierApi.delete(_id);
      if (response.data.result) {
        const results = {
          _id,
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get all without limit action
export const getAllWithoutLimitAction = createAsyncThunk(
  "supplier/getAllWithoutLimit",
  async (params: ApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await supplierApi.getAllWithoutLimit();
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const clearSuppliersAction = createAction("suppliers/REVERT_ALL");

const supplierSlice = createSlice({
  name: "suppliers",
  initialState,
  extraReducers: (builder) => {
    //reset store
    builder.addCase(clearSuppliersAction, () => initialState);
    //add actions
    builder.addCase(addAction.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action?.payload;
      state.data = state.data?.length > 0 ? state.data : [];
      state.data = [data, ...state.data];
      state.appError.message = undefined;
      state.serverError = undefined;
    });
    builder.addCase(addAction.rejected, (state, action) => {
      state.loading = false;
      state.appError.message = action?.payload as string;
      state.serverError = action.error.message;
    });

    //get All
    builder
      .addCase(getAllAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get All without limit
    builder
      .addCase(getAllWithoutLimitAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllWithoutLimitAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get profile
    builder
      .addCase(getByIdAction.pending, (state, action) => {
        // state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.fulfilled, (state, action) => {
        // state.loading = false;
        state.supplier = action?.payload?.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //delete data by id
    builder
      .addCase(deleteAction.fulfilled, (state, action) => {
        // delete row data in store
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(deleteAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //update data
    builder
      .addCase(updateAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
  },
  reducers: {},
});
export const selectSupplier = (state: RootState) => state.suppliers;
export default supplierSlice.reducer;
