import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addAction,
  getAllAction,
  getByIdAction,
  updateAction,
  selectClientUser,
} from "../../../redux/slices/ClientUser/ClientUserSlice";
import { HiOutlinePlusSm } from "react-icons/hi";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import { ListItem } from "./ListItem";
import { Search } from "./Search";
import { Form } from "./Form";
import { Transition } from "@headlessui/react";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { ClientApiProps } from "../../../types/apiType";
import { AiFillWarning } from "react-icons/ai";
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import { useNavigate } from "react-router-dom";
import { getAllNotificationAction } from "../../../redux/slices/User/UserSlice";
import { paramsNotification } from "../../../utils/paramsHelper";
import ReactPaginate from "react-paginate";
import { clearSelectionAction } from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import { Socket } from "socket.io-client";
import TableHeader from "../../../components/TableHeader";
import { headersClientUser } from "../../../constants/headersTable";

interface SocketProps {
  socket: Socket;
}

export const ClientUser = ({ socket }: SocketProps) => {
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  const dispatch = useAppDispatch();

  //redux
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Quản lý khách hàng";
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(5);
  const [keyword, setKeyword] = useState("");
  const [searchKey, setSearchKey] = useState(0);
  //set offset
  let offset = currentPage - 1;
  //set params
  // const params = {
  //   keyword: keyword,
  //   offset: offset,
  //   limit: limit,
  // };

  const [params] = useState({
    keyword: keyword,
    offset: offset,
    limit: limit,
  });

  const getData = () => {
    document.title = title;
    // console.log("keyword", params.keyword);
    dispatch(getAllAction(params));
  };

  useEffect(() => {
    dispatch(getAllNotificationAction(paramsNotification));
    getData();
  }, []);

  //get data from redux
  const serviceSupplierData = useSelector(selectClientUser);
  const { data, loading, totalPage, serverError, clientUser, appError } =
    serviceSupplierData;
  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    dispatch(clearSelectionAction());
    getData();
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (keyword: string) => {
    params.keyword = keyword;
    setKeyword(keyword);
    params.offset = 0;
    getData();
    setSearchKey((prevKey) => prevKey + 1);
  };

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (data: ClientApiProps) => {
    setFormStatusState(false);
    const action = await dispatch(addAction(data));
    if (addAction.fulfilled.match(action)) {
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // update data event
  const handleUpdateData = async (
    _id: string | undefined,
    data: ClientApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateAction(dataUpdate));

    if (updateAction.fulfilled.match(action)) {
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(getByIdAction(id));
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };
  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={clientUser}
        />
      );
    }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="bg-blue-100 h-screen ">
        <Transition
          show={formStatusState}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>

        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4">
          <div className="flex flex-row ml-2">
            <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
            <div className="flex-none flex-shrink-0">
              <p className="font-sans font-semibold text-3xl">{title}</p>
              <p className="font-sans text-sm italic">{title} Conta</p>
            </div>
            {/* Add button */}
            <div className="flex items-center grow justify-end flex-shrink-0">
              <HiOutlinePlusSm
                onClick={() => handleOpenFormAdd()}
                className="text-4xl bg-blue-600 rounded-full text-white hover:bg-blue-500 cursor-pointer "
              />
            </div>
          </div>
          <Search handleSearch={handleSearch} />
          <div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto">
                      <thead className="bg-gray-50">
                      <TableHeader
                          titles={headersClientUser}
                          isCheckbox={false}
                        />
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              Đang tải dữ liệu...
                            </td>
                          </tr>
                        ) : appError?.statusCode === 500 ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <div className="bg-red-500 text-white rounded-lg text-2xl p-4 flex">
                                <AiFillWarning className="text-7xl w-32 mr-2" />
                                <p>
                                  {serverError && "Thông báo:"}{" "}
                                  {appError.message}
                                </p>
                              </div>
                            </td>
                          </tr>
                        ) : (data && data?.length <= 0) || data == null ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              Không tìm thấy dữ liệu
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            data={data}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                          />
                        )}
                      </tbody>
                    </table>
                    {totalPage > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
