import { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
import {
  addAction,
  getByIdAction,
  updateAction,
  selectContainer,
  clearAContainerAction,
  updateImagesAction,
  updateImagesAfterRepairAction,
  updateImagesGateOutAction,
  getContainerGateInAction,
  clearContainersAction,
  getAllInDepotWithoutLimitAction,
} from "../../../redux/slices/Container/containerSlice";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import {
  getAllNotificationAction,
  selectUser,
} from "../../../redux/slices/User/UserSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import {
  selectServiceSuppliers,
  getAllWithoutLimitAction as getAllServiceSupplier,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
//types
import { ContainerApiParams, ContainerApiProps } from "../../../types/apiType";
import { ImageApiProps } from "../../../types";
//icons
//libraries
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
//components
import { Socket } from "socket.io-client";
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import { Slider } from "../../../components/Slider";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//utils
import {
  dateTimeSearchHelper,
  dateTimeSearchStartOfWeekHelper,
} from "../../../utils/DateHelper";
//files linked
import { ListItem } from "./ListItem";
import { Search } from "./Search";
import {
  headersAdminContainerGateInOutDepot,
  headersContainerGateInOutDepot,
} from "../../../constants/headersTable";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import { FormUpdateImages } from "../../Container/FormUpdate/FormUpdateImages";
import { Form } from "../../Container/FormUpdate/Form";
import { FormUpdateImageAfterRepair } from "../../Container/FormUpdate/FormUpdateImageAfterRepair";
import { FormUpdateImagesGateOut } from "../../Container/FormUpdate/FormUpdateImagesGateOut";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { ExportDataGateInFormatter, ExportDataGateInOutFormatter } from "../../../utils/ExportDataFormatter";
import { updateMessageNotification } from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import TableHeaderGateInOut from "../../../components/TableHeader/TableHeaderGateInOut";
import LoadingComponent from "../../../components/LoadingComponent";
import NotFoundLoading from "../../../components/LoadingComponent/NotFoundLoading";

interface GateInProps {
  socket: Socket;
}
export const GateIn = ({ socket }: GateInProps) => {
  useSocketJoinRoom(socket, "container");
  const [contStatus, setContStatus] = useState(5);
  const [limit, setLimit] = useState(5);
  const [startDate, setStartDate] = useState(dateTimeSearchStartOfWeekHelper());
  const [endDate, setEndDate] = useState(dateTimeSearchHelper());

  const handleAddContainerFromInvoice = (msg: any) => {
    dispatch(getContainerGateInAction(params));
  };

  const handleUpdate = (msg: any) => {
    dispatch(getContainerGateInAction(params));
    dispatch(getAllNotificationAction(paramsNotification));
  };

  // Handle "add-receive" and "update-receive" events with event handlers
  // useSocketEventHandler(socket, "add-receive", handleAdd);
  useSocketEventHandler(socket, "update-receive", handleUpdate);
  useSocketEventHandler(socket, "add-receive", handleAddContainerFromInvoice);
  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Quản lý Gate In";
  const [isUpdate, setIsUpdate] = useState(false);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [isUpdateImages, setIsUpdateImages] = useState(false);
  const [isUpdateImagesAfterRepair, setIsUpdateImagesAfterRepair] =
    useState(false);
  const [isUpdateImagesGateOut, setIsUpdateImagesGateOut] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [reportGateIn, setReportGateIn] = useState<Array<ContainerApiProps>>(
    []
  );
  const [reportGateInOut, setReportGateInOut] = useState<
    Array<ContainerApiProps>
  >([]);
  const [reportGateOut, setReportGateOut] = useState<Array<ContainerApiProps>>(
    []
  );
  const [currentNameField, setCurrentNameField] = useState("");
  //set offset
  let offset = currentPage - 1;

  //set params
  // const params: ContainerApiParams = {
  //   keyword: keyword,
  //   offset: offset,
  //   limit: limit,
  //   // contStatus: contStatus,
  //   startDate,
  //   endDate,
  // };

  const [params] = useState<ContainerApiParams>({
    keyword: keyword,
    offset: offset,
    limit: limit,
    // contStatus: contStatus,
    startDate,
    endDate,
  });

  const getData = () => {
    document.title = title;
    // console.log("keyword", params.keyword);
    dispatch(getContainerGateInAction(params));
  };

  //get data from redux
  const containerData = useSelector(selectContainer);
  const {
    dataInDepot,
    loading,
    loadingImagesGateIn,
    loadingImagesNMR,
    loadingImagesGateOut,
    totalPageInDepot,
    serverError,
    container,
    appError,
    loadingGateIn,
    dataGateIn,
    dataGateInAllByDate,
  } = containerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const serviceSupplierData = useSelector(selectServiceSuppliers);
  const { data: dataServiceSupplier } = serviceSupplierData;
  const getSelects = useSelector(selectSelects);
  const { selected } = getSelects;
  //start get data
  // const handleExportExcelGateIn = async () => {
  //   if (
  //     appError?.statusCode === 500 &&
  //     appError.message?.includes("Token đã hết hạn")
  //   ) {
  //     LogoutConfirmation(
  //       navigate,
  //       dispatch,
  //       "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
  //     );
  //     // Return a placeholder or loading component, for example
  //     return <div>Loading...</div>;
  //   } else if (params.startDate !== undefined && params.endDate !== undefined) {
  //     let dateTimeSearch1 = new Date(params.startDate);
  //     let dateTimeSearch2 = new Date(params.endDate);
  //     dateTimeSearch1.setDate(dateTimeSearch1.getDate());
  //     dateTimeSearch1.setHours(0, 0, 0, 0);
  //     dateTimeSearch2.setDate(dateTimeSearch2.getDate());
  //     dateTimeSearch2.setHours(23, 59, 59, 999);

  //     const action = await dispatch(
  //       getAllInDepotWithoutLimitAction({
  //         from: dateTimeSearch1,
  //         to: dateTimeSearch2,
  //         serviceSupplierId: dataServiceSupplier.find(
  //           (item) => item.codeServiceSupplier === "CTA"
  //         ),
  //       })
  //     );

  //     if (getAllInDepotWithoutLimitAction.fulfilled.match(action)) {
  //       //Get all containers gate in and gate out
  //       setReportGateInOut(action.payload.containerGateIn);

  //       setReportGateIn(selected);
  //     } else {
  //       ToastError(action.payload as string, serverError);
  //     }
  //   }
  // };

  // const handleExportExcelGateInOut = async () => {
  //   if (
  //     appError?.statusCode === 500 &&
  //     appError.message?.includes("Token đã hết hạn")
  //   ) {
  //     LogoutConfirmation(
  //       navigate,
  //       dispatch,
  //       "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
  //     );
  //     // Return a placeholder or loading component, for example
  //     return <div>Loading...</div>;
  //   } else if (params.startDate !== undefined && params.endDate !== undefined) {
  //     let dateTimeSearch1 = new Date(params.startDate);
  //     let dateTimeSearch2 = new Date(params.endDate);
  //     dateTimeSearch1.setDate(dateTimeSearch1.getDate());
  //     dateTimeSearch1.setHours(0, 0, 0, 0);
  //     dateTimeSearch2.setDate(dateTimeSearch2.getDate());
  //     dateTimeSearch2.setHours(23, 59, 59, 999);

  //     await dispatch(
  //       getAllInDepotWithoutLimitAction({
  //         from: dateTimeSearch1,
  //         to: dateTimeSearch2,
  //         serviceSupplierId: dataServiceSupplier.find(
  //           (item) => item.codeServiceSupplier === "CTA"
  //         ),
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    dispatch(clearContainersAction());
    getData();
    dispatch(clearSelectionAction());
    dispatch(getAllNotificationAction(paramsNotification));
    const params1 = {
      keyword: "",
      offset: 0,
      limit: 100,
    };
    dispatch(getAllServiceSupplier(params1));
    // handleExportExcelGateIn();
    // handleExportExcelGateInOut();
  }, []);

  useEffect(() => {
    setReportGateIn(selected);
  }, [selected]);

  //end get data
  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    getData();
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (
    keyword: string,
    contStatus: number,
    numberOfLimit: number,
    startDate: string,
    endDate: string
  ) => {
    params.keyword = keyword;
    params.contStatus = contStatus;
    params.limit = numberOfLimit;
    params.startDate = startDate;
    params.endDate = endDate;
    setStartDate(startDate);
    setEndDate(endDate);
    setKeyword(keyword);
    setContStatus(contStatus);
    setLimit(numberOfLimit);
    params.offset = 0;

    getData();
    // handleExportExcelGateIn();
    // handleExportExcelGateInOut();
    setSearchKey((prevKey) => prevKey + 1);
  };

  // open create form event
  const handleOpenFormAdd = () => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
  };

  // create data event
  const handleAddData = async (data: ContainerApiProps) => {
    setFormStatusState(false);

    const action = await dispatch(addAction(data));

    if (addAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // update data event
  const handleUpdateData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateAction(dataUpdate));

    if (updateAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: "ok",
      });
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    // Set the currentNameField to the selected nameField
    setCurrentNameField(currentNameField);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };

  // close form slide event
  const handleCloseSlide = () => {
    setSlideStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImages = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImages(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImages = () => {
    setIsUpdateImages(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImagesAfterRepair = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesAfterRepair(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormUpdateImagesGateOut = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesGateOut(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImagesAfterRepair = () => {
    setIsUpdateImagesAfterRepair(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesGateOut = () => {
    setIsUpdateImagesGateOut(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateImagesData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImages(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAction(dataUpdate));

    if (updateImagesAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesAfterRepairData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesAfterRepair(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAfterRepairAction(dataUpdate));

    if (updateImagesAfterRepairAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh sau sửa chữa container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesGateOutData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesGateOut(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesGateOutAction(dataUpdate));

    if (updateImagesGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh gate out container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImages) {
      return (
        <FormUpdateImages
          closeForm={handleCloseUpdateImages}
          updateData={handleUpdateImagesData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesAfterRepair) {
      return (
        <FormUpdateImageAfterRepair
          closeForm={handleCloseUpdateImagesAfterRepair}
          updateData={handleUpdateImagesAfterRepairData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesGateOut) {
      return (
        <FormUpdateImagesGateOut
          closeForm={handleCloseUpdateImagesGateOut}
          updateData={handleUpdateImagesGateOutData}
          dataUpdate={container}
        />
      );
    }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="bg-blue-100 h-screen ">
        <Transition
          show={formStatusState}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImages}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImagesAfterRepair}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        <Transition
          show={isUpdateImagesGateOut}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {displayForm()}
        </Transition>
        {slideStatusState && (
          <div className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16">
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-row ml-2 justify-between overflow-hidden overflow-x-auto">
            <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
            <div className="flex-none flex-shrink-0">
              <p className="font-sans font-semibold text-3xl">{title}</p>
              <p className="font-sans text-sm italic">{title} Conta</p>
            </div>

            <div className="flex ">
              {/* Gate in button */}
              <div className="flex items-center grow justify-end flex-shrink-0">
                {loadingGateIn ? (
                  <div className="text-center pl-4 pt-4">
                    <LoadingComponent />
                  </div>
                ) : (
                  <CSVLink
                    filename={`Gate In-${Date.now()}.csv`}
                    data={ExportDataGateInFormatter(dataGateInAllByDate!)}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel Gate In</span>
                  </CSVLink>
                )}
              </div>

              {/* Gate in button */}
              <div className="flex items-center grow justify-end flex-shrink-0">
                {reportGateIn !== undefined && reportGateIn.length > 0 && (
                  <CSVLink
                    filename={`Gate In-${Date.now()}.csv`}
                    data={ExportDataGateInFormatter(reportGateIn)}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel Gate In đã chọn</span>
                  </CSVLink>
                )}
              </div>
            </div>
          </div>

          <Search handleSearch={handleSearch} socket={socket} />

          <div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto mb-4">
                      <thead className="bg-gray-50">
                        <TableHeaderGateInOut
                          // titles={headersContainerGateInOutDepot}
                          titles={
                            userAuth?.role === 0
                              ? headersAdminContainerGateInOutDepot
                              : headersContainerGateInOutDepot
                          }
                          isCheckbox={true}
                        />
                      </thead>
                      <tbody>
                        {loadingGateIn ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <LoadingComponent />
                            </td>
                          </tr>
                        ) : (dataGateIn && dataGateIn?.length <= 0) ||
                          dataGateIn == null ? (
                          <tr>
                            <td colSpan={4} className="text-center pl-4 pt-4">
                              <NotFoundLoading />
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            containerUpdate={container}
                            loadingImagesGateIn={loadingImagesGateIn}
                            loadingImagesMNR={loadingImagesNMR}
                            loadingImagesGateOut={loadingImagesGateOut}
                            data={dataGateIn}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                            openFormUpdateImages={(id) =>
                              handleOpenFormUpdateImages(id)
                            }
                            openFormUpdateImagesAfterRepair={(id) =>
                              handleOpenFormUpdateImagesAfterRepair(id)
                            }
                            openFormUpdateImagesGateOut={(id) =>
                              handleOpenFormUpdateImagesGateOut(id)
                            }
                            socket={socket}
                            userAuth={userAuth}
                            openSlide={(imageId, currentNameField) =>
                              handleOpenSlide(imageId, currentNameField)
                            }
                          />
                        )}
                      </tbody>
                    </table>
                    {/* paging */}
                    {totalPageInDepot && totalPageInDepot > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPageInDepot} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
