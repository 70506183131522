import React from "react";
import { SoldBySizeProps } from "../../types/apiType";

interface TableSoldByProps {
  data?: Array<SoldBySizeProps>;
  isRest: boolean;
  totalSize?: string;
  restSize?: string;
}

const TableSoldBySize = ({
  data,
  isRest,
  totalSize,
  restSize,
}: TableSoldByProps) => {
  return (
    <div className="w-full overflow-x-auto ">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-4">
        <thead className="text-xs uppercase text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="whitespace-nowrap">
            <th scope="col" className="px-6 py-3 w-56">
              Size container
            </th>
            {isRest ? (
              <th scope="col" className="px-6 py-3 w-56">
                {restSize}
              </th>
            ) : (
              <th scope="col" className="px-6 py-3 w-56">
                {totalSize}
              </th>
            )}

            {isRest ? (
              <th scope="col" className="px-6 py-3 w-56">
                Tổng số tiền tạm tính
              </th>
            ) : (
              <th scope="col" className="px-6 py-3 w-56">
                Tổng số tiền bán được
              </th>
            )}
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {data?.map((item) => (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              key={item._id}
            >
              <td className="p-4">{item._id}</td>
              <td className="p-4">
                {new Intl.NumberFormat("de-DE").format(item.totalSize)} cont
              </td>
              {isRest ? (
                <td className="p-4">
                  {new Intl.NumberFormat("de-DE").format(item.totalSizeCost)}{" "}
                  &#8363;
                </td>
              ) : (
                <td className="p-4">
                  {new Intl.NumberFormat("de-DE").format(
                    item.totalActualSellingPrice
                  )}{" "}
                  &#8363;
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSoldBySize;
