import { useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
import {
  getByIdAction,
  selectContainer,
  clearAContainerAction,
  updateImagesAction,
  updateImagesAfterRepairAction,
  updateImagesGateOutAction,
  getContainerMNRAction,
  updateMNRAction,
} from "../../../redux/slices/Container/containerSlice";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { openForm, closeForm } from "../../../redux/slices/formSlices";
import {
  getAllNotificationAction,
  selectUser,
} from "../../../redux/slices/User/UserSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import {
  getAllWithoutLimitAction as getAllServiceSupplierWithoutLimitAction,
  selectServiceSuppliers,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import {
  getAllWithoutLimitAction as getAllSupplierWithoutLimitAction,
  selectSupplier,
} from "../../../redux/slices/Supplier/SupplierSlice";
//icons
//libraries
import ReactPaginate from "react-paginate";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
//types
import { ContainerApiParams, ContainerApiProps } from "../../../types/apiType";
import { ImageApiProps } from "../../../types";
//utils
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
import TableHeader from "../../../components/TableHeader";
import { Slider } from "../../../components/Slider";
//hooks
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
//constants
import { headersAdminContainerMNRDepot, headersContainerMNRDepot } from "../../../constants/headersTable";
//file link
import { ListItem } from "./ListItem";
import { Search } from "./Search";
import { FormUpdateImages } from "../../Container/FormUpdate/FormUpdateImages";
import { Form } from "./Form";
import { FormUpdateImageAfterRepair } from "../../Container/FormUpdate/FormUpdateImageAfterRepair";
import { FormUpdateImagesGateOut } from "../../Container/FormUpdate/FormUpdateImagesGateOut";
import {
  dateTimeSearchHelper,
  dateTimeSearchStartOfWeekHelper,
} from "../../../utils/DateHelper";
import {
  updateMessageNotification,
  updateMessageWithStatusNotification,
} from "../../../constants/messageNotification";
import { paramsNotification } from "../../../utils/paramsHelper";
import { containerStatusHelper } from "../../../utils/StatusHelper";

interface SocketProps {
  socket: Socket;
}
export const MNR = ({ socket }: SocketProps) => {
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getContainerMNRAction(params));
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  //redux
  const dispatch = useAppDispatch();
  //navigation
  const navigate = useNavigate();

  const [formStatusState, setFormStatusState] = useState(false);
  const title = "Quản lý MNR";
  const [isUpdate, setIsUpdate] = useState(false);
  const [slideStatusState, setSlideStatusState] = useState(false);
  const [isUpdateImages, setIsUpdateImages] = useState(false);
  const [isUpdateImagesAfterRepair, setIsUpdateImagesAfterRepair] =
    useState(false);
  const [isUpdateImagesGateOut, setIsUpdateImagesGateOut] = useState(false);
  const [isUpdateSellCont, setIsUpdateSellCont] = useState(false);
  const [isUpdateGateIn, setIsUpdateGateIn] = useState(false);
  const [isUpdateGateOut, setIsUpdateGateOut] = useState(false);
  const [isGateIn, setIsGateIn] = useState(false);
  const [isSellMany, setIsSellMany] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [keyword, setKeyword] = useState("");
  const [contStatus, setContStatus] = useState(0);
  const [currentNameField, setCurrentNameField] = useState("");
  const [serviceSupplierId, setServiceSupplierId] = useState<
    string | undefined
  >(undefined);
  const [supplierId, setSupplierId] = useState<string | undefined>(undefined);
  const [size, setSize] = useState<string | undefined>(undefined);
  const [searchKey, setSearchKey] = useState(0);
  const [startDate, setStartDate] = useState(dateTimeSearchStartOfWeekHelper());
  const [endDate, setEndDate] = useState(dateTimeSearchHelper());
  //set offset
  let offset = currentPage - 1;

  const [params] = useState<ContainerApiParams>({
    keyword: keyword,
    offset: offset,
    limit: limit,
    // contStatus: contStatus,
    serviceSupplierId: serviceSupplierId,
    supplierId: supplierId,
    size: size,
    startDate,
    endDate,
  });

  const getData = () => {
    document.title = title;
    dispatch(getContainerMNRAction(params));
  };

  useEffect(() => {
    getData();
    dispatch(getAllNotificationAction(paramsNotification));
    dispatch(getAllServiceSupplierWithoutLimitAction(params));
    dispatch(getAllSupplierWithoutLimitAction(params));
    dispatch(clearSelectionAction());
  }, []);

  //get data from redux
  const containerData = useSelector(selectContainer);
  const { data, loading, totalPage, serverError, container, appError } =
    containerData;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const supplierInStore = useSelector(selectSupplier);
  const { data: supplierData } = supplierInStore;
  const serviceSupplierInStore = useSelector(selectServiceSuppliers);
  const { data: serviceSupplierData } = serviceSupplierInStore;
  // ==== paging ==== //
  const handlePageClick = (event: { selected: number }) => {
    params.offset = event.selected * limit;
    dispatch(clearSelectionAction());
    getData();
  };
  // ==== paging END ==== //
  // search data
  const handleSearch = (
    keyword: string,
    numberOfLimit: number,
    supplierId: string | undefined,
    serviceSupplierId: string | undefined,
    size: string | undefined,
    startDate: string,
    endDate: string
  ) => {
    setKeyword(keyword);
    setLimit(numberOfLimit);
    supplierId?.charAt(0) === "-"
      ? setSupplierId(undefined)
      : setSupplierId(supplierId);
    serviceSupplierId?.charAt(0) === "-"
      ? setServiceSupplierId(undefined)
      : setServiceSupplierId(serviceSupplierId);

    if (size?.charAt(0) === "-") {
      setSize(undefined);
      params.size = undefined;
    } else {
      setSize(size);
      params.size = size;
    }
    if (serviceSupplierId?.charAt(0) === "-") {
      setServiceSupplierId(undefined);
      params.serviceSupplierId = undefined;
    } else {
      setServiceSupplierId(serviceSupplierId);
      params.serviceSupplierId = serviceSupplierId;
    }
    if (supplierId?.charAt(0) === "-") {
      setSupplierId(undefined);
      params.supplierId = undefined;
    } else {
      setSupplierId(supplierId);
      params.supplierId = supplierId;
    }
    params.keyword = keyword;
    params.limit = numberOfLimit;
    params.startDate = startDate;
    params.endDate = endDate;
    setStartDate(startDate);
    setEndDate(endDate);
    params.offset = 0;

    setSearchKey((prevKey) => prevKey + 1);
    getData();
  };

  const handleOpenFormSellMany = () => {
    setIsSellMany(true);
    const action = openForm();
    dispatch(action);
  };

  const handleUpdateData = async (
    _id: string | undefined,
    data: ContainerApiProps
  ) => {
    setFormStatusState(false);
    setIsUpdate(false);
    const dataUpdate = {
      _id,
      ...data,
    };
    const action = await dispatch(updateMNRAction(dataUpdate));

    if (updateMNRAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageWithStatusNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật",
          containerStatusHelper(action.payload.newData.contStatus)
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // open update form event
  const handleOpenFormUpdate = (id: string) => {
    setFormStatusState(true);
    const action = openForm();
    dispatch(action);
    setIsUpdate(true);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenSlide = (id: string, currentNameField: string) => {
    setSlideStatusState(true);
    const action = openForm();
    dispatch(action);
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
    // Set the currentNameField to the selected nameField
    setCurrentNameField(currentNameField);
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
    setIsUpdate(false);
  };

  const handleCloseSlide = () => {
    setSlideStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImages = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImages(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImages = () => {
    setIsUpdateImages(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleOpenFormUpdateImagesAfterRepair = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesAfterRepair(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormUpdateImagesGateOut = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateImagesGateOut(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormSellCont = (id: string) => {
    // setFormStatusState(true);
    setIsUpdateSellCont(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormGateIn = (id: string) => {
    setIsUpdateGateIn(true);
    setIsGateIn(true);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleOpenFormGateOut = (id: string) => {
    setIsUpdateGateIn(true);
    setIsGateIn(false);
    const action = openForm();
    dispatch(action);
    // get data by ID
    dispatch(clearAContainerAction());
    dispatch(getByIdAction(id));
  };

  const handleCloseUpdateImagesAfterRepair = () => {
    setIsUpdateImagesAfterRepair(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleCloseUpdateImagesGateOut = () => {
    setIsUpdateImagesGateOut(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateImagesData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImages(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAction(dataUpdate));

    if (updateImagesAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesAfterRepairData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesAfterRepair(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesAfterRepairAction(dataUpdate));

    if (updateImagesAfterRepairAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh sau sửa chữa container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleUpdateImagesGateOutData = async (
    _id: string | undefined,
    data: FormData
  ) => {
    setIsUpdateImagesGateOut(false);
    const dataUpdate: ImageApiProps = {
      _id,
      formData: data,
    };
    const action = await dispatch(updateImagesGateOutAction(dataUpdate));

    if (updateImagesGateOutAction.fulfilled.match(action)) {
      socket.emit("update-data", {
        room: "container",
        updateData: updateMessageNotification(
          userAuth?.currentName,
          action.payload.newData.containerNumber,
          action.payload.newData.supplier.label,
          action.payload.newData.serviceSupplier.label,
          "cập nhật hình ảnh gate out container"
        ),
      });
      dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  // check show form
  const displayForm = () => {
    if (formStatusState) {
      return (
        <Form
          closeForm={handleCloseForm}
          isUpdate={isUpdate}
          updateData={handleUpdateData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImages) {
      return (
        <FormUpdateImages
          closeForm={handleCloseUpdateImages}
          updateData={handleUpdateImagesData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesAfterRepair) {
      return (
        <FormUpdateImageAfterRepair
          closeForm={handleCloseUpdateImagesAfterRepair}
          updateData={handleUpdateImagesAfterRepairData}
          dataUpdate={container}
        />
      );
    } else if (isUpdateImagesGateOut) {
      return (
        <FormUpdateImagesGateOut
          closeForm={handleCloseUpdateImagesGateOut}
          updateData={handleUpdateImagesGateOutData}
          dataUpdate={container}
        />
      );
    }
    // else if (isUpdateGateIn) {
    //   return (
    //     <FormUpdateGateInOut
    //       closeForm={handleCloseGateIn}
    //       updateData={handleUpdateGateInData}
    //       updateGateOut={handleUpdateGateOutData}
    //       dataUpdate={container}
    //       isGateIn={isGateIn}
    //     />
    //   );
    // }
  };

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  const generateTransition = (show: boolean) => (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {displayForm()}
    </Transition>
  );
  return (
    <>
      <div className="bg-blue-100 h-screen ">
        {generateTransition(formStatusState)}
        {generateTransition(isUpdateImages)}
        {generateTransition(isUpdateImagesAfterRepair)}
        {generateTransition(isUpdateImagesGateOut)}
        {generateTransition(isUpdateSellCont)}
        {generateTransition(isSellMany)}
        {generateTransition(isUpdateGateIn)}
        {slideStatusState && (
          <div className="z-10 max-w-[540px] h-[620px] m-auto py-16 px-4 group w-3/4 fixed left-1/2 ml-[-37.5%] min-[2000px]:ml-[-30%] top-16">
            <Slider
              closeForm={handleCloseSlide}
              isBigger={false}
              currentNameField={currentNameField}
            />
          </div>
        )}
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 ">
          <div className="flex flex-row ml-2">
            <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
            <div className="flex-none flex-shrink-0">
              <p className="font-sans font-semibold text-3xl">{title}</p>
              <p className="font-sans text-sm italic">{title}</p>
            </div>
          </div>

          <Search
            handleSearch={handleSearch}
            openFormSellMany={handleOpenFormSellMany}
            dataServiceSupplier={serviceSupplierData}
            dataSupplier={supplierData}
          />

          <div className="overflow-hidden">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 table-auto mb-4">
                      <thead className="bg-gray-50">
                        <TableHeader
                          // titles={headersContainerMNRDepot}
                          titles={
                            userAuth?.role === 0
                              ? headersAdminContainerMNRDepot
                              : headersContainerMNRDepot
                          }
                          isCheckbox={false}
                        />
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={4} className="text-center">
                              Đang tải dữ liệu...
                            </td>
                          </tr>
                        ) : (data && data?.length <= 0) || data == null ? (
                          <tr>
                            <td colSpan={4} className="text-center">
                              Không tìm thấy dữ liệu
                            </td>
                          </tr>
                        ) : (
                          <ListItem
                            data={data}
                            openFormUpdate={(id) => handleOpenFormUpdate(id)}
                            openFormUpdateImages={(id) =>
                              handleOpenFormUpdateImages(id)
                            }
                            openFormUpdateImagesAfterRepair={(id) =>
                              handleOpenFormUpdateImagesAfterRepair(id)
                            }
                            openFormUpdateImagesGateOut={(id) =>
                              handleOpenFormUpdateImagesGateOut(id)
                            }
                            socket={socket}
                            userAuth={userAuth}
                            openSlide={(imageId, currentNameField) =>
                              handleOpenSlide(imageId, currentNameField)
                            }
                            openFormSellCont={(id) =>
                              handleOpenFormSellCont(id)
                            }
                            openFormGateIn={(id) => handleOpenFormGateIn(id)}
                            openFormGateOut={(id) => handleOpenFormGateOut(id)}
                          />
                        )}
                      </tbody>
                    </table>
                    {/* paging */}
                    {totalPage > 1 ? (
                      <ReactPaginate
                        key={searchKey}
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={totalPage} // Replace with the total number of pages
                        previousLabel="<"
                        pageClassName="inline-block px-4 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        pageLinkClassName="block w-full h-full"
                        previousClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        previousLinkClassName="block w-full h-full"
                        nextClassName="inline-block px-2 py-1 rounded-lg mx-1 bg-gray-200 text-gray-800"
                        nextLinkClassName="block w-full h-full"
                        breakLabel="..."
                        breakClassName="inline-block px-2 py-1 mx-1 text-gray-600"
                        breakLinkClassName=""
                        containerClassName="flex justify-start my-4 "
                        activeClassName="background-color-base-paging text-white"
                        disabledClassName="opacity-50 cursor-not-allowed"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
