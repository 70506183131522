import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "../Column/Column";
import { useDragDrop } from "../DragDropProvider";
import { ColumnDropshadow, Container } from "./Board.styled";
import { useSelector } from "react-redux";
import {
  selectLocationContainers,
  updateRowLocationAction,
} from "../../../../../redux/slices/LocationContainer/LocationContainerSlice";
import { CompareTwoArrayHelper } from "../../../../../utils/CompareTwoArrayHelper";
import { useAppDispatch } from "../../../../../hooks/reduxHooks";
import ToastSuccess from "../../../../../components/Toast/success";
import ToastError from "../../../../../components/Toast/error";
import { UpdateLocationContainerApiProps } from "../../../../../types/apiType";
import { selectUser } from "../../../../../redux/slices/User/UserSlice";

interface BoardProps {
  openFormUpdateRowLocation: (data: UpdateLocationContainerApiProps) => void;
}

const Board: React.FC<BoardProps> = ({ openFormUpdateRowLocation }) => {
  //redux
  const dispatch = useAppDispatch();
  const {
    handleDragEnd,
    handleDragStart,
    handleDragUpdate,
    colDropshadowProps,
    columns,
    rowContainerNameArray,
    pickUpDirectionArray,
    idArray,
  } = useDragDrop();

  const [isSaveOneRow, setIsSaveOneRow] = useState(false);

  const selectDirection = useSelector(selectLocationContainers);
  const { data, serverError } = selectDirection;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;

  useEffect(() => {
    const dataChecker = data.filter(
      (item) => item.rowContainerName === rowContainerNameArray
    );

    const isChanged = CompareTwoArrayHelper(
      dataChecker[0].columnContainer,
      columns
    );
    setIsSaveOneRow(isChanged);
  }, [columns, rowContainerNameArray, isSaveOneRow, data]);

  const [isRoleApprove, setIsRoleApprove] = useState(false);

  useEffect(() => {
    if (userAuth?.role === 0 || userAuth?.role === 3) {
      setIsRoleApprove(true);
    } else {
      setIsRoleApprove(false);
    }
  }, []);

  const handleUpdateRowContainer = async () => {
    const params = {
      rowContainerName: rowContainerNameArray,
      columnContainer: columns,
      pickUpDirection: pickUpDirectionArray,
      _id: "",
    };

    const action = await dispatch(updateRowLocationAction(params));

    if (updateRowLocationAction.fulfilled.match(action)) {
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const handleOpenFormUpdateRowLocation = (
    data: UpdateLocationContainerApiProps
  ) => {
    openFormUpdateRowLocation(data);
  };

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided, snapshot) => (
          <Container
            id="task-board"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className="space-y-8 flex-row items-end h-full">
              <div
                onClick={() =>
                  isRoleApprove === true &&
                  handleOpenFormUpdateRowLocation({
                    _id: idArray,
                    rowContainerName: rowContainerNameArray,
                    pickUpDirection: pickUpDirectionArray,
                  })
                }
                className="text-lg font-bold bg-blue-600 p-2 mr-3 rounded-md text-white flex-none"
              >
                {rowContainerNameArray}
              </div>
              <p className="bg-yellow-500 [writing-mode:vertical-lr] text-xl px-2 py-7 flex-initial rounded-md">
                {pickUpDirectionArray}
              </p>
            </div>
            {columns.map((column, columnIndex) => (
              <Column
                key={column._id}
                column={column}
                columnIndex={columnIndex}
                rowContainerNameArray={rowContainerNameArray}
              />
            ))}
            {provided.placeholder}
            {snapshot.isDraggingOver && (
              <ColumnDropshadow
                marginLeft={colDropshadowProps.marginLeft}
                height={colDropshadowProps.height}
              />
            )}
          </Container>
        )}
      </Droppable>
      {isRoleApprove && isSaveOneRow && (
        <button
          onClick={() => handleUpdateRowContainer()}
          type="button"
          className="z-10 group mt-4 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          <span>Lưu vị trí</span>
        </button>
      )}
    </DragDropContext>
  );
};

export default Board;
