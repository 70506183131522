import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiFillEye, AiFillEyeInvisible, AiOutlineClose } from "react-icons/ai";
import { UserApiProps } from "../../../types/apiType";
import RoleDropdown from "../../../components/RoleDropdown";
import { RoleHelper } from "../../../utils/StatusHelper";

interface FormProps {
  closeForm: () => void;
  updateData: (id: string | undefined, data: UserApiProps) => Promise<void>;
  addData: (data: UserApiProps) => Promise<void>;
  isUpdate: boolean;
  profile: UserApiProps;
}

interface RoleProps {
  label: string;
  value: number;
}

export const Form = (props: FormProps) => {
  const formSchema = Yup.object().shape({
    role: Yup.object().required("*Dữ liệu bắt buộc!"),
    currentName: Yup.string().required("*Dữ liệu bắt buộc!"),
    password: Yup.string().min(
      6,
      "*Mật khẩu quá ngắn, phải có ít nhất 6 ký tự!"
    ),
  });
  //declare value in fields
  const [userName, setUserName] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<RoleProps>();

  //set eyes password
  const [passwordCurrentType, setPasswordCurrentType] = useState("password");

  const togglePasswordCurrent = () => {
    if (passwordCurrentType === "password") {
      setPasswordCurrentType("text");
      return;
    }
    setPasswordCurrentType("password");
  };
  // get props to index components
  const { closeForm, isUpdate, updateData, addData, profile } = props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  useEffect(() => {
    focus();
    if (isUpdate) {
      if (profile) {
        if (profile.userName !== undefined) {
          setUserName(profile.userName);
        }
        if (profile.currentName !== undefined) {
          setCurrentName(profile.currentName);
        }
        if (profile.role !== undefined) {
          const roleUpdate = {
            value: profile.role,
            label: RoleHelper(profile.role),
          };
          setRole(roleUpdate);
        }
      }
    }
  }, [profile]);

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let resetPassword = {
      userName: formik.values.userName,
      currentName: formik.values.currentName,
      password: formik.values.password,
      role: formik.values.role?.value,
    };

    updateData(profile._id, resetPassword);
  };

  const handleAddData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let addUser = {
      userName: formik.values.userName,
      currentName: formik.values.currentName,
      password: formik.values.password,
      role: formik.values.role?.value,
    };
    addData(addUser);
  };

  // check show button action
  const showButtonAction = () => {
    if (isUpdate) {
      return (
        <button
          type="submit"
          onClick={(e) => handleUpdateData(e)}
          className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Cập nhật
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          onClick={(e) => handleAddData(e)}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Lưu
        </button>
      );
    }
  };
  const updatedSchema = isUpdate
    ? formSchema
    : formSchema.shape({
        userName: Yup.string().required("*Dữ liệu bắt buộc!"),
        currentName: Yup.string().required("*Dữ liệu bắt buộc!"),
        password: Yup.string()
          .required("*Dữ liệu bắt buộc!")
          .min(6, "*Mật khẩu quá ngắn, phải có ít nhất 6 ký tự!"),
      });
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName,
      currentName,
      password,
      role,
    },
    validationSchema: isUpdate ? formSchema : updatedSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">
          {isUpdate ? "Cập nhật tài khoản" : "Thêm tài khoản mới"}
        </p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>
        {isUpdate ? (
          <form>
            <div className="mb-8">
              <div className="flex flex-col w-full">
                <div className="relative z-0 w-full group border border-gray-300 rounded-md ">
                  <input
                    type={passwordCurrentType}
                    name="floating_password"
                    id="floating_password"
                    className="block relative ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.password}
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                  />
                  <p
                    className="absolute right-2 top-1/4 cursor-pointer text-gray-500 text-2xl"
                    onClick={togglePasswordCurrent}
                  >
                    {passwordCurrentType === "password" ? (
                      <AiFillEye />
                    ) : (
                      <AiFillEyeInvisible />
                    )}
                  </p>
                  <label
                    htmlFor="floating_password"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Mật khẩu
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.password && formik.errors.password}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex flex-col w-full">
                <div className="relative z-0 w-full group border border-gray-300 rounded-md ">
                  <input
                    type="currentName"
                    name="floating_currentName"
                    id="floating_currentName"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.currentName}
                    onChange={formik.handleChange("currentName")}
                    onBlur={formik.handleBlur("currentName")}
                  />
                  <label
                    htmlFor="floating_currentName"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Tên hiện tại <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.currentName && formik.errors.currentName}
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="flex flex-col w-full">
                <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                  <label
                    htmlFor="small"
                    className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                  >
                    Chọn vai trò <span className="text-red-500">*</span>
                  </label>
                  <RoleDropdown
                    value={formik.values.role?.label}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    error={formik.errors.role}
                    touched={formik.touched.role}
                    isUpdating={role}
                  />
                </div>
              </div>
            </div>
            {showButtonAction()}
            <button
              type="button"
              className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              onClick={() => handleCloseForm()}
            >
              Hủy
            </button>
          </form>
        ) : (
          <form>
            <div className="mb-8">
              <div className="flex flex-col w-full">
                <div className="relative z-0 w-full group border border-gray-300 rounded-md ">
                  <input
                    type="userName"
                    name="floating_userName"
                    id="floating_userName"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.userName}
                    onChange={formik.handleChange("userName")}
                    onBlur={formik.handleBlur("userName")}
                    ref={inputRef}
                  />
                  <label
                    htmlFor="floating_userName"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    User Name <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.userName && formik.errors.userName}
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="flex flex-col w-full">
                <div className="relative z-0 w-full group border border-gray-300 rounded-md ">
                  <input
                    type="currentName"
                    name="floating_currentName"
                    id="floating_currentName"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.currentName}
                    onChange={formik.handleChange("currentName")}
                    onBlur={formik.handleBlur("currentName")}
                  />
                  <label
                    htmlFor="floating_currentName"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Tên hiện tại <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.currentName && formik.errors.currentName}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="flex flex-col w-full">
                <div className="relative z-0 w-full group border border-gray-300 rounded-md ">
                  <input
                    type={passwordCurrentType}
                    name="floating_password"
                    id="floating_password"
                    className="block relative ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.password}
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                  />
                  <p
                    className="absolute right-2 top-1/4 cursor-pointer text-gray-500 text-2xl"
                    onClick={togglePasswordCurrent}
                  >
                    {passwordCurrentType === "password" ? (
                      <AiFillEye />
                    ) : (
                      <AiFillEyeInvisible />
                    )}
                  </p>
                  <label
                    htmlFor="floating_password"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Mật khẩu <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.password && formik.errors.password}
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="flex flex-col w-full">
                <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                  <label
                    htmlFor="small"
                    className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                  >
                    Chọn vai trò <span className="text-red-500">*</span>
                  </label>
                  <RoleDropdown
                    value={formik.values.role?.label}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    error={formik.errors.role}
                    touched={formik.touched.role}
                    isUpdating={role}
                  />
                </div>
              </div>
            </div>
            {showButtonAction()}
            <button
              type="button"
              className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              onClick={() => handleCloseForm()}
            >
              Hủy
            </button>
          </form>
        )}
      </div>
    </>
  );
};
