import { configureStore } from "@reduxjs/toolkit";
import userSelector from "./slices/User/UserSlice";
import supplierSelector from "./slices/Supplier/SupplierSlice";
import serviceSupplierSelector from "./slices/ServiceSupplier/ServiceSupplierSlice";
import clientAdminSelector from "./slices/ClientAdmin/ClientAdminSlice";
import clientUserSelector from "./slices/ClientUser/ClientUserSlice";
import invoiceSelector from "./slices/Invoice/invoiceSlice";
import containerSelector from "./slices/Container/containerSlice";
import selectSelector from "./slices/SelectCheckbox/SelectCheckboxSlice";
import selectCounter from "./slices/Counter/CounterSlice";
import brandOfUnitSelector from "./slices/BrandOfUnit/BrandOfUnitSlice";
import locationContainerSelector from "./slices/LocationContainer/LocationContainerSlice";
import bookingSaleSelector from "./slices/BookingSale/bookingSaleSlices";

export const store = configureStore({
  reducer: {
    users: userSelector,
    suppliers: supplierSelector,
    serviceSuppliers: serviceSupplierSelector,
    clientAdmins: clientAdminSelector,
    clientUsers: clientUserSelector,
    invoices: invoiceSelector,
    containers: containerSelector,
    selects: selectSelector,
    counters: selectCounter,
    brandOfUnits: brandOfUnitSelector,
    locationContainer: locationContainerSelector,
    bookingSales: bookingSaleSelector,
  },
});

export const createStore = () =>
  configureStore({
    reducer: {
      users: userSelector,
      suppliers: supplierSelector,
      serviceSuppliers: serviceSupplierSelector,
      clientAdmins: clientAdminSelector,
      clientUsers: clientUserSelector,
      invoices: invoiceSelector,
      containers: containerSelector,
      selects: selectSelector,
      counters: selectCounter,
      brandOfUnits: brandOfUnitSelector,
      locationContainer: locationContainerSelector,
      bookingSales: bookingSaleSelector,
    },
  });
export const storeTest = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
