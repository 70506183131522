import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserState } from "../../types/reduxTypes";
import { useAppDispatch } from "../../hooks/reduxHooks";
import LogoutConfirmation from "../../components/Logout/LogoutConfirmation";

interface UserProtectRouteProps {
  user: UserState;
}

const OutletProtectUserRoute: React.FC<UserProtectRouteProps> = ({ user }) => {
  const { userAuth } = user;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (
    userAuth?.role !== 1 &&
    userAuth?.role !== 2 &&
    userAuth?.role !== 3 &&
    userAuth?.role !== 4
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Chỉ có nhân viên mới thực hiện thao tác này!"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  } else if (userAuth) {
    return <Outlet />;
  } else {
    return <div>Unauthorized</div>;
  }
};

export default OutletProtectUserRoute;
