import { useEffect } from "react";
import NavItem from "./Navigation/NavItem";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
export default function PartnerManagement() {
  const location = useLocation();
  const title = "Quản lý Đối Tác";

  const getData = () => {
    document.title = title;
  };

  useEffect(() => {
    getData();
  }, []);

  const isSupplierRoute = location.pathname === ROUTES.PARTNER_MANAGEMENT;
  // if the current route is PARTNER_MANAGEMENT, redirect to the SUPPLIER route
  if (isSupplierRoute) {
    return <Navigate to={ROUTES.SUPPLIER} replace />;
  }
  return (
    <>
      <div className="bg-blue-100 h-screen pt-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-xl p-4 drop-shadow-sm">
          {/* Nav features */}
          <NavItem />
        </div>
        <div className="mt-2">
          <Outlet />
        </div>
      </div>
    </>
  );
}
