import React, { useEffect } from "react";
import Select, { ActionMeta } from "react-select";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useSelector } from "react-redux";
import {
  selectClientUser,
  getAllWithoutLimitAction,
} from "../../redux/slices/ClientUser/ClientUserSlice";
import { SelectOptionProps } from "../../types";
import { ClientApiProps } from "../../types/apiType";
import { FormikErrors, FormikTouched } from "formik";

type CustomStyles = {
  container: (provided: any) => any;
  control: (provided: any, state: { isFocused: boolean }) => any;
  option: (provided: any, state: { isSelected: boolean }) => any;
};

const customStyles: CustomStyles = {
  container: (provided) => ({
    ...provided,
    borderRadius: "0.5rem",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.5rem",
    "&:hover": {
      borderColor: state.isFocused ? "#9CA3AF" : "#D1D5DB",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E5E7EB" : "#FFFFFF",
    color: state.isSelected ? "#111827" : "#374151",
    "&:hover": {
      backgroundColor: "#D1D5DB",
    },
  }),
};

interface SelectOptionDropDownProps {
  value: SelectOptionProps;
  onChange: (
    fieldName: string,
    value: SelectOptionProps | null,
    actionMeta: ActionMeta<SelectOptionProps>
  ) => void;
  onBlur: (fieldName: string, value: boolean) => void;
  isUpdating: SelectOptionProps | null;
  error?: string | FormikErrors<SelectOptionProps> | undefined;
  touched?: boolean | FormikTouched<SelectOptionProps> | undefined;
  getByUserId?: string;
}

export default function ClientDropDownByUser(props: SelectOptionDropDownProps) {
  const dispatch = useAppDispatch();
  const allUserClients = useSelector(selectClientUser);
  const { data } = allUserClients;

  useEffect(() => {
    const params = {
      keyword: "",
      offset: 0,
      limit: 10,
    };
    dispatch(getAllWithoutLimitAction(params));
  }, [dispatch]);

  const getData = data?.map((client: ClientApiProps) => {
    return {
      label: client?.clientName,
      value: client?._id,
      _id: client?._id,
    };
  });

  const handleChange = (
    value: SelectOptionProps | null,
    actionMeta: ActionMeta<SelectOptionProps>
  ) => {
    props.onChange("client", value, actionMeta);
  };

  const handleBlur = () => {
    props.onBlur("client", true);
  };

  return (
    <div>
      <Select
        placeholder={<span className="text-sm text-gray-500">-- Chọn --</span>}
        options={getData}
        styles={customStyles}
        onChange={handleChange}
        onBlur={handleBlur}
        value={props.value}
        isMulti={false}
        isClearable={true}
        autoFocus
      />
      {/* Display */}
      {props.error && props.touched && (
        <div className="text-red-400 ">{props.error.toString()}</div>
      )}
    </div>
  );
}
