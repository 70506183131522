import {
  AddLocationApiProps,
  locationContainerApiProps,
  LocationApiUpdateProps,
  UpdateLocationContainerApiProps,
} from "../types/apiType";
import axiosClient from "./axiosClient";

const module = "location-container";

const suppliersApi = {
  create: (body: AddLocationApiProps) => {
    const url = `/${module}/row`;
    return axiosClient.post(url, body);
  },
  update: (id: string | undefined, body: LocationApiUpdateProps) => {
    const url = `/${module}/${id}`;
    return axiosClient.put(url, body);
  },
  updateRowLocation: (body: locationContainerApiProps) => {
    const url = `/${module}/update-row-location`;
    return axiosClient.put(url, body);
  },
  updateNameRowLocation: (
    id: string,
    body: UpdateLocationContainerApiProps
  ) => {
    const url = `/${module}/update-name-row-location/${id}`;
    return axiosClient.put(url, body);
  },
  // delete: (id: string) => {
  //   const url = `/${module}/delete/${id}`;
  //   return axiosClient.delete(url);
  // },
  // getByID: (id: string) => {
  //   const url = `/${module}/${id}`;
  //   return axiosClient.get(url);
  // },
  // getAll: (params: ApiParams) => {
  //   const url = `/${module}/search`;
  //   return axiosClient.get(url, { params });
  // },
  getAllWithoutLimit: (params: string) => {
    const url = `/${module}/getAll/${params}`;
    return axiosClient.get(url);
  },
};

export default suppliersApi;
