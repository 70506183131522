import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { BookingSaleApiProps, CounterProps } from "../../../types/apiType";
import SupplierDropDown from "../../../components/SupplierDropdown";
import { SelectOptionProps } from "../../../types";
import ServiceSupplierDropDown from "../../../components/ServiceSupplierDropdown";
import MyDatePicker from "../../../components/DatetimePicker";
import {
  PaymentStatusHelper,
  containerSizeHelper,
} from "../../../utils/StatusHelper";
import { NumericFormat } from "react-number-format";
import { dataPaymentStatus, dataSizeCont } from "../../../constants/dropdown";
import ComponentDropDown from "../../../components/ComponentDropDown";
import ClientDropDown from "../../../components/ClientDropDown";
import { CTABookingSaleCodeHelper } from "../../../utils/DateHelper";
import { getCounterBookingSaleLatestAction } from "../../../redux/slices/Counter/CounterSlice";
import { useAppDispatch } from "../../../hooks/reduxHooks";

interface FormProps {
  closeForm: () => void;
  updateData: (
    id: string | undefined,
    data: BookingSaleApiProps
  ) => Promise<void>;
  addData: (data: BookingSaleApiProps) => Promise<void>;
  isUpdate: boolean;
  dataUpdate: BookingSaleApiProps;
  counter: CounterProps;
}

export const Form = (props: FormProps) => {
  const dispatch = useAppDispatch();
  const formSchema = Yup.object().shape({
    // bookingManySaleNumber: Yup.string().required("*Dữ liệu bắt buộc!"),
    containerNumberBookingSale: Yup.string().required("*Dữ liệu bắt buộc!"),
    paymentStatus: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    containerCondition: Yup.string().required("*Dữ liệu bắt buộc!"),
    size: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    contQuantity: Yup.number()
      .required("*Dữ liệu bắt buộc!")
      .test(
        "Phải là số dương!",
        "Giá trị phải lớn hơn 0!",
        (value) => value > 0
      ),
    remainingQuantity: Yup.number()
      .required("*Dữ liệu bắt buộc!")
      .test(
        "Phải là số dương!",
        "Giá trị phải lớn hơn bằng 0!",
        (value) => value >= 0
      ),
    paymentDue: Yup.string().required("*Dữ liệu bắt buộc!"),
    bookingDate: Yup.string().required("*Dữ liệu bắt buộc!"),
    sellingPrice: Yup.string()
      .required("*Dữ liệu bắt buộc!")
      .test(
        "Is positive?",
        "Giá trị phải lớn hơn 0!",
        (value) => parseFloat(value) > 0
      ),

    supplier: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    serviceSupplier: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    client: Yup.object()
      .required("*Dữ liệu bắt buộc!")
      .test(
        "Dữ liệu bắt buộc!",
        "Dữ liệu bắt buộc!",
        (value: SelectOptionProps) => value.value !== undefined
      ),
    hotline: Yup.string().required("*Dữ liệu bắt buộc!"),
  });
  //declare value in fields
  const [bookingManySaleNumber, setBookingManySaleNumber] = useState("");
  const [autoIncrementId, setAutoIncrementId] = useState(0);
  const [bookingNumber, setBookingNumber] = useState("");
  const [hotline, setHotline] = useState("");
  const [supplier, setSupplier] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [serviceSupplier, setServiceSupplier] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [lineOperation, setLineOperation] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [client, setClient] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [paymentStatus, setPaymentStatus] = useState<SelectOptionProps>({
    label: "",
    value: "",
  });
  const [containerCondition, setContainerCondition] = useState<string | number>(
    ""
  );
  const [size, setSize] = useState<SelectOptionProps>({
    label: "",
    value: "",
  });
  // const [containerCondition, setContainerCondition] = useState("");
  // const [size, setSize] = useState("");
  const [contQuantity, setContQuantity] = useState(0);
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [VAT, setVAT] = useState(0);
  // const [paymentStatus, setPaymentStatus] = useState(0);
  const [paymentDue, setPaymentDue] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [bookingManySalePrice, setBookingManySalePrice] = useState("");
  const [containerNumberBookingSale, setContainerNumberBookingSale] =
    useState("");

  // get props to index components
  const { closeForm, isUpdate, updateData, addData, dataUpdate, counter } =
    props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  useEffect(() => {
    if (!isUpdate) {
      dispatch(getCounterBookingSaleLatestAction());
    }
  }, []);

  useEffect(() => {
    focus();
    if (isUpdate) {
      if (dataUpdate) {
        if (dataUpdate.bookingManySaleNumber !== undefined) {
          setBookingManySaleNumber(dataUpdate.bookingManySaleNumber);
        }
        if (dataUpdate.bookingNumber !== undefined) {
          setBookingNumber(dataUpdate.bookingNumber);
        }
        if (dataUpdate.supplier !== undefined && dataUpdate.supplier !== null) {
          setSupplier(dataUpdate.supplier);
        }
        if (
          dataUpdate.serviceSupplier !== undefined &&
          dataUpdate.serviceSupplier !== null
        ) {
          setServiceSupplier(dataUpdate.serviceSupplier);
        }
        if (dataUpdate.buyer !== undefined && dataUpdate.buyer !== null) {
          setClient(dataUpdate.buyer);
        }
        if (
          dataUpdate.lineOperation !== undefined &&
          dataUpdate.lineOperation !== null
        ) {
          setLineOperation(dataUpdate.lineOperation);
        }
        if (dataUpdate.client !== undefined && dataUpdate.client !== null) {
          setClient(dataUpdate.client);
        }
        if (dataUpdate.containerCondition !== undefined) {
          setContainerCondition(dataUpdate.containerCondition);
        }
        if (dataUpdate.containerNumberBookingSale !== undefined) {
          setContainerNumberBookingSale(dataUpdate.containerNumberBookingSale);
        }
        if (dataUpdate.size !== undefined) {
          const sizeUpdate = {
            value: dataUpdate.size,
            label: containerSizeHelper(dataUpdate.size),
          };
          setSize(sizeUpdate);
        }
        if (dataUpdate.contQuantity !== undefined) {
          setContQuantity(dataUpdate.contQuantity);
        }
        if (dataUpdate.remainingQuantity !== undefined) {
          setRemainingQuantity(dataUpdate.remainingQuantity);
        }
        if (dataUpdate.paymentDue !== undefined) {
          setPaymentDue(dataUpdate.paymentDue);
        }
        if (dataUpdate.bookingDate !== undefined) {
          setBookingDate(dataUpdate.bookingDate);
        }
        if (dataUpdate.paymentStatus !== undefined) {
          const paymentStatusUpdate = {
            value: dataUpdate.paymentStatus,
            label: PaymentStatusHelper(dataUpdate.paymentStatus),
          };
          setPaymentStatus(paymentStatusUpdate);
        }
        if (dataUpdate.sellingPrice !== undefined) {
          setSellingPrice(dataUpdate.sellingPrice);
        }
        if (dataUpdate.buyingPrice !== undefined) {
          setBuyingPrice(dataUpdate.buyingPrice);
        }
        if (dataUpdate.bookingManySalePrice !== undefined) {
          setBookingManySalePrice(dataUpdate.bookingManySalePrice);
        }
        if (dataUpdate.hotline !== undefined) {
          setHotline(dataUpdate.hotline);
        }
      }
    }
  }, [dataUpdate]);

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let dataUpdateNew: BookingSaleApiProps = {
      // bookingManySaleNumber: CTABookingSaleCodeHelper(
      //   formik.values.autoIncrementId.toString().padStart(5, "0")
      // ),
      bookingManySaleNumber: formik.values.bookingManySaleNumber.trim(),
      sellingPrice:
        typeof formik.values.sellingPrice == "string"
          ? formik.values.sellingPrice.replace(/,/g, "")
          : formik.values.sellingPrice,
      // containerCondition: formik.values.containerCondition.value,
      containerCondition: formik.values.containerCondition,
      contQuantity: formik.values.contQuantity,
      VAT: formik.values.VAT,
      bookingManySalePrice: formik.values.bookingManySalePrice,
      paymentDue: formik.values.paymentDue,
      bookingDate: formik.values.bookingDate,
      paymentStatus: formik.values.paymentStatus.value,
      size: formik.values.size.value,
      supplier:
        formik.values.supplier?.value === "" ? null : formik.values?.supplier,
      buyer: formik.values.client?.value === "" ? null : formik.values?.client,
      serviceSupplier:
        formik.values.serviceSupplier?.value === ""
          ? null
          : formik.values?.serviceSupplier,
      remainingQuantity: formik.values.remainingQuantity,
      containerNumberBookingSale: formik.values.containerNumberBookingSale,
      hotline: formik.values.hotline,
    };
    updateData(dataUpdate._id, dataUpdateNew);
  };

  const handleAddData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let dataAdd: BookingSaleApiProps = {
      bookingManySaleNumber: CTABookingSaleCodeHelper(
        formik.values.autoIncrementId.toString().padStart(5, "0")
      ),
      // bookingManySaleNumber: formik.values.bookingManySaleNumber.trim(),
      sellingPrice:
        typeof formik.values.sellingPrice == "string"
          ? formik.values.sellingPrice.replace(/,/g, "")
          : formik.values.sellingPrice,
      // containerCondition: formik.values.containerCondition.value,
      containerCondition: formik.values.containerCondition,
      contQuantity: formik.values.contQuantity,
      VAT: formik.values.VAT,
      bookingManySalePrice: formik.values.bookingManySalePrice,
      paymentDue: formik.values.paymentDue,
      bookingDate: formik.values.bookingDate,
      paymentStatus: formik.values.paymentStatus?.value,
      size: formik.values.size.value,
      supplier:
        formik.values.supplier?.value === "" ? null : formik.values?.supplier,
      buyer: formik.values.client?.value === "" ? null : formik.values?.client,
      serviceSupplier:
        formik.values.serviceSupplier?.value === ""
          ? null
          : formik.values?.serviceSupplier,
      remainingQuantity: formik.values.remainingQuantity,
      containerNumberBookingSale: formik.values.containerNumberBookingSale,
      hotline: formik.values.hotline,
    };
    addData(dataAdd);
    // console.log("🚀 ~ Form ~ dataAdd:", dataAdd);
  };

  // check show button action
  const showButtonAction = () => {
    if (isUpdate) {
      return (
        <button
          type="submit"
          onClick={(e) => handleUpdateData(e)}
          className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Cập nhật
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          onClick={(e) => handleAddData(e)}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Lưu
        </button>
      );
    }
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bookingManySaleNumber,
      bookingNumber,
      supplier,
      serviceSupplier,
      client,
      containerCondition,
      size,
      contQuantity,
      paymentStatus,
      paymentDue,
      bookingDate,
      buyingPrice,
      sellingPrice,
      bookingManySalePrice,
      VAT,
      lineOperation,
      remainingQuantity,
      containerNumberBookingSale,
      hotline,
      autoIncrementId,
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    setBookingManySaleNumber(formik.values.bookingManySaleNumber);
    setBookingNumber(formik.values.bookingNumber);
    setHotline(formik.values.hotline);
    setSupplier(formik.values.supplier);
    setServiceSupplier(formik.values.serviceSupplier);
    setLineOperation(formik.values.lineOperation);
    setClient(formik.values.client);
    setPaymentStatus(formik.values.paymentStatus);
    setContainerCondition(formik.values.containerCondition);
    setSize(formik.values.size);

    setContQuantity(formik.values.contQuantity);
    setRemainingQuantity(formik.values.remainingQuantity);
    setPaymentStatus(formik.values.paymentStatus);
    setPaymentDue(formik.values.paymentDue);
    setBookingDate(formik.values.bookingDate);
    setBuyingPrice(formik.values.buyingPrice);
    setSellingPrice(formik.values.sellingPrice);
    setBookingManySalePrice(formik.values.bookingManySalePrice);
    setContainerNumberBookingSale(formik.values.containerNumberBookingSale);
    setAutoIncrementId(counter.autoIncrementId);
  }, [counter]);

  // Define a useEffect to automatically calculate bookingManySalePrice
  useEffect(() => {
    const sellingPrice = parseInt(formik.values.sellingPrice.replace(/,/g, ""));
    const quantity = formik.values.contQuantity;
    const calculatedBookingManySalePrice = sellingPrice * quantity;

    // Update formik's bookingManySalePrice field
    formik.setFieldValue(
      "bookingManySalePrice",
      calculatedBookingManySalePrice
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.sellingPrice, formik.values.contQuantity]);

  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">
          {isUpdate ? "Cập nhật booking" : "Thêm booking mới"}
        </p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          <div className="flex lg:flex-row flex-col justify-between mb-5">
            {isUpdate ? (
              <div className="flex flex-col w-full lg:mr-1 mr-0">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="text"
                    name="floating_bookingManySaleNumber"
                    id="floating_bookingManySaleNumber"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer "
                    placeholder=" "
                    value={formik.values.bookingManySaleNumber}
                    onChange={formik.handleChange("bookingManySaleNumber")}
                    onBlur={formik.handleBlur("bookingManySaleNumber")}
                  />
                  <label
                    htmlFor="floating_bookingManySaleNumber"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Số booking bán
                    <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.bookingManySaleNumber &&
                    formik.errors.bookingManySaleNumber}
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-full lg:mr-1 mr-0">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="text"
                    name="floating_bookingManySaleNumber"
                    id="floating_bookingManySaleNumber"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer bg-slate-300"
                    placeholder=" "
                    // value={formik.values.bookingManySaleNumber}
                    value={CTABookingSaleCodeHelper(
                      formik.values.autoIncrementId.toString().padStart(5, "0")
                    )}
                    onChange={formik.handleChange("bookingManySaleNumber")}
                    onBlur={formik.handleBlur("bookingManySaleNumber")}
                    readOnly
                    disabled
                  />
                  <label
                    htmlFor="floating_bookingManySaleNumber"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Số booking bán (tạo tự động)
                    <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.bookingManySaleNumber &&
                    formik.errors.bookingManySaleNumber}
                </div>
              </div>
            )}
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-6">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Nhà cung cấp <span className="text-red-500">*</span>
                </label>
                <SupplierDropDown
                  value={formik.values.supplier}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.supplier}
                  touched={formik.touched.supplier}
                  isUpdating={supplier}
                />
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Khách mua <span className="text-red-500">*</span>
              </label>
              <ClientDropDown
                value={formik.values.client}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.client}
                touched={formik.touched.client}
                isUpdating={client}
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-6 lg:mb-12">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Vị trí <span className="text-red-500">*</span>
              </label>
              <ServiceSupplierDropDown
                value={formik.values.serviceSupplier}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.serviceSupplier}
                touched={formik.touched.serviceSupplier}
                isUpdating={serviceSupplier}
                fieldNameUpdate="serviceSupplier"
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-4 lg:mb-10">
            <div className="flex flex-col w-full mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <textarea
                  name="floating_containerCondition"
                  id="floating_containerCondition"
                  className="block ml-2 py-2.5 px-0 w-full h-24 text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.containerCondition}
                  onChange={formik.handleChange("containerCondition")}
                  onBlur={formik.handleBlur("containerCondition")}
                />
                <label
                  htmlFor="floating_containerCondition"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Tình trạng container <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.containerCondition &&
                  formik.errors.containerCondition}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-0 lg:mb-4">
            <div className="flex flex-col w-full mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <textarea
                  name="floating_containerNumberBookingSale"
                  id="floating_containerNumberBookingSale"
                  className="block ml-2 py-2.5 px-0 w-full h-24 text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.containerNumberBookingSale}
                  onChange={formik.handleChange("containerNumberBookingSale")}
                  onBlur={formik.handleBlur("containerNumberBookingSale")}
                />
                <label
                  htmlFor="floating_containerNumberBookingSale"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Số container booking <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.containerNumberBookingSale &&
                  formik.errors.containerNumberBookingSale}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-6">
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Kích thước <span className="text-red-500">*</span>
              </label>
              <ComponentDropDown
                value={formik.values.size}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.size}
                touched={formik.touched.size}
                isUpdating={size}
                nameField="size"
                dataArray={dataSizeCont}
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between lg:mb-10 mb-10">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <label
                htmlFor="floating_paymentDue"
                className="block text-sm font-sm text-gray-500 "
              >
                Hạn thanh toán <span className="text-red-500">*</span>
              </label>
              <MyDatePicker
                value={formik.values.paymentDue}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                error={formik.errors.paymentDue}
                touched={formik.touched.paymentDue}
                isUpdating={paymentDue}
                nameField="paymentDue"
              />
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-8 lg:mt-0">
              <label
                htmlFor="small"
                className="block text-sm font-sm text-gray-500"
              >
                Trạng thái thanh toán <span className="text-red-500">*</span>
              </label>
              <ComponentDropDown
                value={formik.values.paymentStatus}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.paymentStatus}
                touched={formik.touched.paymentStatus}
                isUpdating={paymentStatus}
                nameField="paymentStatus"
                dataArray={dataPaymentStatus}
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between lg:mb-6 mb-6">
            {/* <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  type="text"
                  name="floating_buyingPrice"
                  id="floating_buyingPrice"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.buyingPrice}
                  onChange={formik.handleChange("buyingPrice")}
                  onBlur={formik.handleBlur("buyingPrice")}
                />
                <label
                  htmlFor="floating_buyingPrice"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Giá mua <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.buyingPrice && formik.errors.buyingPrice}
              </div>
            </div> */}
            <div className="flex flex-col w-full lg:ml-0 ml-0 mt-8 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  type="text"
                  name="floating_sellingPrice"
                  id="floating_sellingPrice"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.sellingPrice}
                  onChange={formik.handleChange("sellingPrice")}
                  onBlur={formik.handleBlur("sellingPrice")}
                />
                <label
                  htmlFor="floating_sellingPrice"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Giá bán (VNĐ)<span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.sellingPrice && formik.errors.sellingPrice}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full lg:mr-1 mr-0 mt-6 lg:mt-3 mb-8 lg:mb-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <input
                  type="number"
                  name="floating_contQuantity"
                  id="floating_contQuantity"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.contQuantity}
                  onChange={formik.handleChange("contQuantity")}
                  onBlur={formik.handleBlur("contQuantity")}
                />
                <label
                  htmlFor="floating_contQuantity"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Số lượng container <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.contQuantity && formik.errors.contQuantity}
              </div>
            </div>
            <div
              className={`flex flex-col w-full ml-0 mt-3 ${
                isUpdate ? "lg:ml-0" : "lg:ml-1 "
              }`}
            >
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="number"
                  name="floating_remainingQuantity"
                  id="floating_remainingQuantity"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.remainingQuantity}
                  onChange={formik.handleChange("remainingQuantity")}
                  onBlur={formik.handleBlur("remainingQuantity")}
                />
                <label
                  htmlFor="floating_remainingQuantity"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Số lượng container còn lại
                  <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.remainingQuantity &&
                  formik.errors.remainingQuantity}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between lg:mb-6 mb-6">
            <div className="flex flex-col w-full lg:ml-0 ml-0 mt-8 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <input
                  type="text"
                  name="floating_hotline"
                  id="floating_hotline"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.hotline}
                  onChange={formik.handleChange("hotline")}
                  onBlur={formik.handleBlur("hotline")}
                />
                <label
                  htmlFor="floating_hotline"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Hotline <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.hotline && formik.errors.hotline}
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row flex-col justify-between lg:mb-10 mb-10">
            <div className="flex flex-col w-full lg:mr-0 mr-0">
              <label
                htmlFor="floating_bookingDate"
                className="block text-sm font-sm text-gray-500 "
              >
                Thời gian lấy container <span className="text-red-500">*</span>
              </label>
              <MyDatePicker
                value={formik.values.bookingDate}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                error={formik.errors.bookingDate}
                touched={formik.touched.bookingDate}
                isUpdating={bookingDate}
                nameField="bookingDate"
              />
            </div>
          </div>

          <div className="flex justify-between border-2 border-l-4 border-l-blue-500 bg-blue-100 rounded-lg p-2 mb-12">
            <div className="flex flex-col">
              <div>Giá trị booking</div>
              <div className="font-bold">
                {new Intl.NumberFormat("de-DE").format(
                  parseInt(formik.values.bookingManySalePrice)
                )}{" "}
                đ
              </div>
            </div>
          </div>
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
