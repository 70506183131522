import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { logoutAction } from "../../redux/slices/User/UserSlice";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { UserState } from "../../types/reduxTypes";
import NotFoundPage from "../../components/NotFoundPage";

interface AdminProtectRouteProps {
  user: UserState;
  children: React.ReactNode;
}

const PrivateProtectRoute: React.FC<AdminProtectRouteProps> = ({
  user,
  children,
}) => {
  const { userAuth, appError } = user;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    Swal.fire({
      title: "Đã hết phiên làm việc, vui lòng đăng nhập lại?",
      showDenyButton: true,
      confirmButtonText: "OK",
      denyButtonText: `Chờ một tí`,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/login");
        dispatch(logoutAction());
      }
    });

    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  } else if (userAuth) {
    return <>{children}</>;
  } else {
    return (
      <div>
        <NotFoundPage />
      </div>
    );
  }
};

export default PrivateProtectRoute;
