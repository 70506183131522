import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { ClientApiProps } from "../../../types/apiType";
import { phoneRegExp } from "../../../constants/regex/numberPhone";
import { UserSelectOption } from "../../../types";
import UserDropDown from "../../../components/UserDropDown";

interface FormProps {
  closeForm: () => void;
  updateData: (id: string | undefined, data: ClientApiProps) => Promise<void>;
  addData: (data: ClientApiProps) => Promise<void>;
  shareData: (id: string | undefined, data: ClientApiProps) => Promise<void>;
  isUpdate: boolean;
  isSecondForm: boolean;
  dataUpdate: ClientApiProps;
}

export const Form = (props: FormProps) => {
  const formSchema = Yup.object().shape({
    clientName: Yup.string().required("*Dữ liệu bắt buộc!"),
    address: Yup.string().required("*Dữ liệu bắt buộc!"),
    phoneNumber: Yup.string().required("*Dữ liệu bắt buộc!"),
    // .matches(phoneRegExp, "Số điện thoại không hợp lệ!"),
  });
  const formSecondSchema = Yup.object().shape({
    listUser: Yup.array()
      .min(1, "Danh sách hiển thị phải lớn hơn 1") // Define your custom error message here.
      .of(
        Yup.object().shape({
          value: Yup.string().required("*Dữ liệu bắt buộc!"), // Define your custom error message here.
          label: Yup.string().required("*Dữ liệu bắt buộc!"), // Define your custom error message here.
        })
      ),
  });

  //declare value in fields
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [listUser, setListUser] = useState<Array<UserSelectOption>>([]);

  // get props to index components
  const {
    closeForm,
    isUpdate,
    updateData,
    addData,
    shareData,
    dataUpdate,
    isSecondForm,
  } = props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  useEffect(() => {
    focus();
    if (isUpdate || isSecondForm) {
      if (dataUpdate) {
        if (dataUpdate.clientName !== undefined) {
          setClientName(dataUpdate.clientName);
        }
        if (dataUpdate.email !== undefined) {
          setEmail(dataUpdate.email);
        }
        if (dataUpdate.phoneNumber !== undefined) {
          setPhoneNumber(dataUpdate.phoneNumber);
        }
        if (dataUpdate.companyName !== undefined) {
          setCompanyName(dataUpdate.companyName);
        }
        if (dataUpdate.address !== undefined) {
          setAddress(dataUpdate.address);
        }
        if (dataUpdate.listUser !== undefined) {
          setListUser(dataUpdate.listUser);
        }
      }
    }
  }, [dataUpdate]);

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataUpdateNew: ClientApiProps = {
      clientName: formik.values.clientName,
      email: formik.values.email,
      phoneNumber: formik.values.phoneNumber,
      companyName: formik.values.companyName,
      address: formik.values.address,
      listUser: formik.values.listUser,
    };
    updateData(dataUpdate._id, dataUpdateNew);
  };

  const handleAddData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let dataAdd: ClientApiProps = {
      clientName: formik.values.clientName.trim(),
      email: formik.values.email,
      phoneNumber: formik.values.phoneNumber,
      companyName: formik.values.companyName,
      address: formik.values.address,
    };
    addData(dataAdd);
  };

  const handleShareData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    let dataShare: ClientApiProps = {
      listUser: formik.values.listUser,
    };
    shareData(dataUpdate._id, dataShare);
  };

  // check show button action
  const showButtonAction = () => {
    if (isSecondForm) {
      return (
        <button
          type="submit"
          onClick={(e) => handleShareData(e)}
          className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Chia sẻ
        </button>
      );
    } else if (isUpdate) {
      return (
        <button
          type="submit"
          onClick={(e) => handleUpdateData(e)}
          className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Cập nhật
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          onClick={(e) => handleAddData(e)}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:cursor-not-allowed"
          disabled={!formik.isValid}
        >
          Lưu
        </button>
      );
    }
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientName,
      email,
      phoneNumber,
      listUser,
      companyName,
      address,
    },
    validationSchema: isSecondForm ? formSecondSchema : formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">
          {isUpdate ? "Cập nhật dữ liệu" : "Thêm khách hàng mới"}
        </p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        {isSecondForm ? (
          <form>
            <div className="mb-8">
              <div className="flex flex-col w-full">
                <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                  <label
                    htmlFor="small"
                    className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                  >
                    Quản lý hiển thị <span className="text-red-500">*</span>
                  </label>
                  <UserDropDown
                    value={formik.values.listUser}
                    onChange={(fieldName, value) =>
                      formik.setFieldValue(fieldName, value)
                    }
                    onBlur={formik.setFieldTouched}
                    error={formik.errors.listUser}
                    touched={formik.touched.listUser}
                    isUpdating={listUser}
                  />
                </div>
              </div>
            </div>
            {showButtonAction()}
            <button
              type="button"
              className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              onClick={() => handleCloseForm()}
            >
              Hủy
            </button>
          </form>
        ) : (
          <form>
            <div className="flex lg:flex-row flex-col justify-between mb-8">
              <div className="flex flex-col w-full lg:mr-1 mr-0">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="text"
                    name="floating_clientName"
                    id="floating_clientName"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.clientName}
                    onChange={formik.handleChange("clientName")}
                    onBlur={formik.handleBlur("clientName")}
                    ref={inputRef}
                  />
                  <label
                    htmlFor="floating_clientName"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Tên khách hàng <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.clientName && formik.errors.clientName}
                </div>
              </div>
              <div className="flex flex-col w-full lg:ml-1 ml-0 mt-8 lg:mt-0">
                <div className="relative z-0 group border border-gray-300 rounded-md">
                  <input
                    type="text"
                    name="floating_phoneNumber"
                    id="floating_phoneNumber"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange("phoneNumber")}
                    onBlur={formik.handleBlur("phoneNumber")}
                  />
                  <label
                    htmlFor="floating_phoneNumber"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Số điện thoại <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.phoneNumber && formik.errors.phoneNumber}
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col justify-between mb-8">
              <div className="flex flex-col w-full lg:mr-1 mr-0">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="text"
                    name="floating_address"
                    id="floating_address"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.address}
                    onChange={formik.handleChange("address")}
                    onBlur={formik.handleBlur("address")}
                    ref={inputRef}
                  />
                  <label
                    htmlFor="floating_address"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Địa chỉ <span className="text-red-500">*</span>
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.address && formik.errors.address}
                </div>
              </div>
              <div className="flex flex-col w-full lg:ml-1 ml-0 mt-8 lg:mt-0">
                <div className="relative z-0 group border border-gray-300 rounded-md">
                  <input
                    type="text"
                    name="floating_companyName"
                    id="floating_companyName"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.companyName}
                    onChange={formik.handleChange("companyName")}
                    onBlur={formik.handleBlur("companyName")}
                  />
                  <label
                    htmlFor="floating_companyName"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Tên công ty
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.companyName && formik.errors.companyName}
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col justify-between mb-8">
              <div className="flex flex-col w-full lg:mr-1 mr-0">
                <div className="relative z-0 group border border-gray-300 rounded-md ">
                  <input
                    type="text"
                    name="floating_email"
                    id="floating_email"
                    className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                  />
                  <label
                    htmlFor="floating_email"
                    className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                  >
                    Email
                  </label>
                </div>
                <div className="text-red-400 mb-2">
                  {formik.touched.email && formik.errors.email}
                </div>
              </div>
            </div>
            {showButtonAction()}
            <button
              type="button"
              className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              onClick={() => handleCloseForm()}
            >
              Hủy
            </button>
          </form>
        )}
      </div>
    </>
  );
};
