import { useEffect, useState } from "react";
//libraries
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import "moment/locale/vi"; // Import the Vietnamese locale\
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
//types
//redux
import {
  clearInvoicesAction,
  selectInvoice,
} from "../../../redux/slices/Invoice/invoiceSlice";
import { useSelector } from "react-redux";
import {
  getAllAnalyticUserAction,
  getAllContainerSoldByMonthWithoutLimitAction,
  selectContainer,
} from "../../../redux/slices/Container/containerSlice";
//utils
import { dateHelper } from "../../../utils/DateHelper";
import {
  ExportContainerSoldByMonthFormatter,
  ExportDataContainerSellerFormatter,
} from "../../../utils/ExportDataFormatter";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//icons
//constants
//components
import ToastSuccess from "../../../components/Toast/success";
import LabelComponent from "../../../components/LabelComponent";
import TableSoldBySupplier from "../../../components/ContainerAnalytic/TableSoldBySupplier";
import TableSoldByServiceSupplier from "../../../components/ContainerAnalytic/TableSoldByServiceSupplier";
import TableSoldByClient from "../../../components/ContainerAnalytic/TableSoldByClient";
import TableSoldByContainer from "../../../components/ContainerAnalytic/TableSoldByContainer";
import TableSoldBySize from "../../../components/ContainerAnalytic/TableSoldBySize";
import { Socket } from "socket.io-client";
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import {
  getAllNotificationAction,
  selectUser,
} from "../../../redux/slices/User/UserSlice";
import { paramsNotification } from "../../../utils/paramsHelper";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import LoadingComponent from "../../../components/LoadingComponent";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);
interface SocketProps {
  socket: Socket;
}
const ContainerAnalytic = ({ socket }: SocketProps) => {
  //start setting socket
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  //end setting socket
  const dispatch = useAppDispatch();
  const containerData = useSelector(selectContainer);
  const invoiceData = useSelector(selectInvoice);
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  // const supplierData = useSelector(selectSupplier);
  // const serviceSupplierData = useSelector(selectServiceSuppliers);
  const {
    dataAnalytic: dataContainer,
    totalContainer,
    soldByClient,
    soldBySizes,
    soldBySupplier,
    soldByServiceSupplier,
    soldByContainer,
    dataSoldByMonth,
    loadingSoldContainer,
  } = containerData;
  const { data } = invoiceData;
  // const { data: dataSupplier } = supplierData;
  // const { data: dataServiceSupplier } = serviceSupplierData;

  // const [contQuantityOfMonth, setContQuantityOfMonth] = useState(0);
  // const [totalInvoiceOfMonth, setTotalInvoiceOfMonth] = useState(0);
  // const [totalPaymentStatusOfMonth, setTotalPaymentStatusOfMonth] = useState(0);
  // const [supplierNotIncluded, setSupplierNotIncluded] =
  //   useState<Array<SupplierApiProps>>();
  // const [serviceSupplierNotIncluded, setServiceSupplierNotIncluded] =
  //   useState<Array<ServiceSupplierApiProps>>();

  const currentDate = new Date(); // Get the current date
  currentDate.setDate(1); // Set the day to 1
  const [getByMonth, setGetByMonth] = useState<Date>(currentDate);

  const getQuarter = (date: Date): number => {
    const month = date.getMonth();
    return Math.floor(month / 3) + 1;
  };

  const [startQuarter, setStartQuarter] = useState<Date | null>(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [endQuarter, setEndQuarter] = useState<Date | null>(null);
  let title = "";

  if (startQuarter && endQuarter) {
    const startQuarterNumber = getQuarter(startQuarter);
    const endQuarterNumber = getQuarter(endQuarter);

    if (startQuarterNumber === endQuarterNumber) {
      title = `Thống kê container quý ${startQuarterNumber}`;
    } else {
      title = `Thống kê container từ quý ${startQuarterNumber} đến quý ${endQuarterNumber}`;
    }
  } else {
    title = `Thống kê container tháng ${dateHelper(getByMonth).charAt(
      5
    )}${dateHelper(getByMonth).charAt(6)}`;
  }

  useEffect(() => {
    document.title = title;
    dispatch(getAllNotificationAction(paramsNotification));
  }, []);

  const handleChange = ([newStartQuarter, newEndQuarter]: [
    Date | null,
    Date | null
  ]) => {
    setStartQuarter(newStartQuarter);
    setEndQuarter(newEndQuarter);
  };
  useEffect(() => {
    dispatch(clearInvoicesAction());

    if (startQuarter !== null && endQuarter !== null) {
      let toUpdateEndQuarter = new Date(dateHelper(endQuarter));
      toUpdateEndQuarter.setMonth(endQuarter.getMonth() + 2);
      dispatch(
        getAllAnalyticUserAction({
          from: dateHelper(startQuarter),
          to: dateHelper(toUpdateEndQuarter),
        })
      );
      // dispatch(
      //   getAllContainerSoldByMonthWithoutLimitAction({
      //     from: dateHelper(startQuarter),
      //     to: dateHelper(toUpdateEndQuarter),
      //   })
      // );
    } else {
      if (getByMonth !== null) {
        let toUpdate = new Date(dateHelper(getByMonth));
        toUpdate.setMonth(toUpdate.getMonth() + 1);
        dispatch(
          getAllAnalyticUserAction({
            from: dateHelper(getByMonth),
            to: toUpdate,
            // to: dateHelper(getByMonth),
          })
        );
        // dispatch(
        //   getAllContainerSoldByMonthWithoutLimitAction({
        //     from: dateHelper(getByMonth),
        //     to: toUpdate,
        //     // to: dateHelper(getByMonth),
        //   })
        // );
      }
    }
  }, [dispatch, endQuarter, startQuarter, getByMonth]);

  // useEffect(() => {
  //   const contQuantityAfterCount = dataAnalyticHelper(
  //     data,
  //     "size",
  //     "Số lượng cont"
  //   ).datasets[0].data.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue,
  //     0
  //   );

  //   const totalInvoiceAfterCount = data.reduce(
  //     (accumulator, currentValue) =>
  //       accumulator + parseFloat(currentValue.invoicePrice!),
  //     0
  //   );

  //   const totalPaymentStatusAfterCount = data.reduce(
  //     (accumulator, currentValue) =>
  //       currentValue.paymentStatus === 0 ? accumulator + 1 : accumulator + 0,
  //     0
  //   );

  //   setContQuantityOfMonth(contQuantityAfterCount);
  //   setTotalInvoiceOfMonth(totalInvoiceAfterCount);
  //   setTotalPaymentStatusOfMonth(totalPaymentStatusAfterCount);

  //   const filterSupplierNotIncludes = dataSupplier.filter(
  //     (item) =>
  //       !dataSelectOptionAnalyticHelper(
  //         data,
  //         "supplier",
  //         "label",
  //         "Nhà cung ứng"
  //       ).labels.includes(item.supplierName)
  //   );
  //   setSupplierNotIncluded(filterSupplierNotIncludes);
  //   const filterServiceSupplierNotIncludes = dataServiceSupplier.filter(
  //     (item) =>
  //       !dataSelectOptionAnalyticHelper(
  //         data,
  //         "serviceSupplier",
  //         "label",
  //         "Nhà cung cấp dịch vụ"
  //       ).labels.includes(item.serviceSupplierName)
  //   );
  //   setServiceSupplierNotIncluded(filterServiceSupplierNotIncludes);
  // }, [data, dataServiceSupplier, dataSupplier]);
  return (
    <div className="bg-blue-100 h-full pb-20">
      <div className="bg-slate-50 mx-2 rounded-2xl p-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 overflow-hidden overflow-x-auto">
          <div className="h-16 flex items-center w-full ">
            <div className="flex flex-row ml-2 w-full">
              <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
              <div className="flex-none flex-shrink-0">
                <p className="font-sans font-semibold text-3xl">{title}</p>
                <p className="font-sans text-sm italic">{title} Conta</p>
              </div>
              <div className="flex flex-1 justify-between lg:mt-0 ml-4">
                <div className="flex lg:mt-0 ml-4">
                  <DatePicker
                    selected={startQuarter}
                    onChange={handleChange}
                    selectsRange
                    startDate={startQuarter}
                    endDate={endQuarter}
                    dateFormat="yyyy, QQQ"
                    showQuarterYearPicker
                    className="bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                  <DatePicker
                    selected={getByMonth}
                    onChange={(date) => setGetByMonth(date as Date)}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    className=" bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  {loadingSoldContainer ? (
                    <LoadingComponent />
                  ) : (
                    dataContainer !== undefined &&
                    dataContainer.length > 0 && (
                      <CSVLink
                        filename={`${title}-${Date.now()}.csv`}
                        data={ExportDataContainerSellerFormatter(dataContainer)}
                        onClick={() => {
                          ToastSuccess("Tải về file Excel thành công!");
                        }}
                        className="ml-4 flex-shrink-0 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                      >
                        <span>Xuất excel sản lượng</span>
                      </CSVLink>
                    )
                  )}
                  {/* {userAuth?.role === 2
                    ? dataSoldByMonth !== undefined &&
                      dataSoldByMonth.length > 0 && (
                        <CSVLink
                          filename={`Sản lượng-${Date.now()}.csv`}
                          data={ExportContainerSoldByMonthFormatter(
                            dataSoldByMonth
                          )}
                          onClick={() => {
                            ToastSuccess("Tải về file Excel thành công!");
                          }}
                          className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                        >
                          <span>Xuất excel sản lượng</span>
                        </CSVLink>
                      )
                    : null} */}

                  {/* {loadingSoldContainer ? (
                    <div>
                      <LoadingComponent />
                    </div>
                  ) : (
                    dataSoldByMonth !== undefined &&
                    dataSoldByMonth.length > 0 && (
                      <CSVLink
                        filename={`Sản lượng-${Date.now()}.csv`}
                        data={ExportContainerSoldByMonthFormatter(
                          dataSoldByMonth
                        )}
                        onClick={() => {
                          ToastSuccess("Tải về file Excel thành công!");
                        }}
                        className="ml-4 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                      >
                        <span>Xuất excel sản lượng</span>
                      </CSVLink>
                    )
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" mb-6 p-4">
          <div className=" flex justify-between lg:justify-end space-x-2 overflow-hidden overflow-x-auto">
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-80">
              <span className="text-xl font-medium text-gray-800">
                Tháng này đã bán được
              </span>
              <div className="flex items-center text-green-700 space-x-3">
                {/* <span className="text-xl font-bold text-green-700">
                  {dataContainer &&
                  dataContainer.length > 0 &&
                  dataContainer[0].count !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                      dataContainer[0].count
                      )
                    : "N/A"}{" "}
                  cont
                </span> */}
                <span className="text-xl font-bold text-green-700">
                  {dataContainer && dataContainer.length} container
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết Container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByContainer data={soldByContainer} isRest={false} />
        </div> */}

        {/* <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết nhà cung cấp:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldBySupplier data={soldBySupplier} isRest={false} />
        </div> */}
        {/* <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết vị trí container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByServiceSupplier
            data={soldByServiceSupplier}
            isRest={false}
          />
        </div>
        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết size container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldBySize data={soldBySizes} isRest={false} />
        </div>
        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết khách hàng:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByClient data={soldByClient} />
        </div> */}
      </div>
    </div>
  );
};

export default ContainerAnalytic;
