import { useEffect, useState } from "react";
//libraries
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import "moment/locale/vi"; // Import the Vietnamese locale\
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
//types
//redux
import { clearInvoicesAction } from "../../../redux/slices/Invoice/invoiceSlice";
import { getAllWithoutLimitAction as getAllSupplier } from "../../../redux/slices/Supplier/SupplierSlice";
import {
  getAllWithoutLimitAction as getAllServiceSupplier,
  selectServiceSuppliers,
} from "../../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import { useSelector } from "react-redux";
import {
  clearContainersAction,
  getAllInDepotWithoutLimitAction,
  selectContainer,
} from "../../../redux/slices/Container/containerSlice";
//utils
import { dateHelper } from "../../../utils/DateHelper";
import { ExportContainerStorageFormatter } from "../../../utils/ExportDataFormatter";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//icons
//constants
//components
import ToastSuccess from "../../../components/Toast/success";

import LabelComponent from "../../../components/LabelComponent";
import TableSoldBySupplier from "../../../components/ContainerAnalytic/TableSoldBySupplier";
import TableSoldBySize from "../../../components/ContainerAnalytic/TableSoldBySize";
import TableSoldByContainer from "../../../components/ContainerAnalytic/TableSoldByContainer";
import { getAllNotificationAction } from "../../../redux/slices/User/UserSlice";
import { paramsNotification } from "../../../utils/paramsHelper";
import { Socket } from "socket.io-client";
import useSocketJoinRoom from "../../../hooks/useSocketJoinRoom";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import { useNavigate } from "react-router-dom";
import LogoutConfirmation from "../../../components/Logout/LogoutConfirmation";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
);
interface SocketProps {
  socket: Socket;
}
const RevenueDepot = ({ socket }: SocketProps) => {
  const dispatch = useAppDispatch();
  useSocketJoinRoom(socket, "container");
  useSocketJoinRoom(socket, "invoice");

  const handleUpdateDataSocket = (msg: any) => {
    dispatch(getAllNotificationAction(paramsNotification));
  };
  //navigation
  const navigate = useNavigate();
  useSocketEventHandler(socket, "update-receive", handleUpdateDataSocket);
  useEffect(() => {
    document.title = title;
    const params = {
      keyword: "",
      offset: 0,
      limit: 100,
    };
    dispatch(getAllNotificationAction(paramsNotification));
    dispatch(getAllSupplier(params));
    dispatch(getAllServiceSupplier(params));
  }, []);

  const containerData = useSelector(selectContainer);
  const serviceSupplierData = useSelector(selectServiceSuppliers);
  const {
    containerGateIn,
    containerGateOut,
    totalContainer,
    soldBySupplier,
    restBySupplier,
    soldBySizes,
    restBySizes,
    soldByContainer,
    restByContainer,
    appError,
  } = containerData;
  const { data: dataServiceSupplier } = serviceSupplierData;

  const currentDate = new Date(); // Get the current date
  currentDate.setDate(1); // Set the day to 1
  const [getByMonth, setGetByMonth] = useState<Date>(currentDate);

  const getQuarter = (date: Date): number => {
    const month = date.getMonth();
    return Math.floor(month / 3) + 1;
  };

  const [startQuarter, setStartQuarter] = useState<Date | null>(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [endQuarter, setEndQuarter] = useState<Date | null>(null);
  let title = "";

  if (startQuarter && endQuarter) {
    const startQuarterNumber = getQuarter(startQuarter);
    const endQuarterNumber = getQuarter(endQuarter);

    if (startQuarterNumber === endQuarterNumber) {
      title = `Thống kê doanh thu depot quý ${startQuarterNumber}`;
    } else {
      title = `Thống kê doanh thu depot từ quý ${startQuarterNumber} đến quý ${endQuarterNumber}`;
    }
  } else {
    title = `Thống kê doanh thu depot tháng ${dateHelper(getByMonth).charAt(
      5
    )}${dateHelper(getByMonth).charAt(6)}`;
  }

  const handleChange = ([newStartQuarter, newEndQuarter]: [
    Date | null,
    Date | null
  ]) => {
    setStartQuarter(newStartQuarter);
    setEndQuarter(newEndQuarter);
  };

  useEffect(() => {
    dispatch(clearInvoicesAction());
    dispatch(clearContainersAction());
    if (startQuarter !== null && endQuarter !== null) {
      let toUpdateEndQuarter = new Date(dateHelper(endQuarter));
      toUpdateEndQuarter.setMonth(endQuarter.getMonth() + 2);
      dispatch(
        getAllInDepotWithoutLimitAction({
          from: dateHelper(startQuarter),
          to: dateHelper(toUpdateEndQuarter),
          serviceSupplierId: dataServiceSupplier.find(
            (item) => item.codeServiceSupplier === "CTA"
          ),
        })
      );
    } else {
      if (getByMonth !== null) {
        let toUpdate = new Date(dateHelper(getByMonth));
        toUpdate.setMonth(toUpdate.getMonth() + 1);
        dispatch(
          getAllInDepotWithoutLimitAction({
            from: dateHelper(getByMonth),
            to: toUpdate,
            serviceSupplierId: dataServiceSupplier.find(
              (item) => item.codeServiceSupplier === "CTA"
            ),
          })
        );
      }
    }
  }, [dispatch, endQuarter, startQuarter, getByMonth, dataServiceSupplier]);

  if (
    appError?.statusCode === 500 &&
    appError.message?.includes("Token đã hết hạn")
  ) {
    LogoutConfirmation(
      navigate,
      dispatch,
      "Đã hết phiên làm việc, vui lòng đăng nhập lại?"
    );
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-blue-100 h-full pb-20">
      <div className="bg-slate-50 mx-2 rounded-2xl p-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-2xl p-4 overflow-hidden overflow-x-auto">
          <div className="h-16 flex items-center w-full ">
            <div className="flex flex-row ml-2 w-full">
              <div className="absolute left-5 w-1 bg-blue-400 h-14"></div>
              <div className="flex-none flex-shrink-0">
                <p className="font-sans font-semibold text-3xl">{title}</p>
                <p className="font-sans text-sm italic">{title} Conta</p>
              </div>
              <div className="flex flex-1 justify-between lg:mt-0 ml-4">
                <div className="flex lg:mt-0 ml-4">
                  <DatePicker
                    selected={startQuarter}
                    onChange={handleChange}
                    selectsRange
                    startDate={startQuarter}
                    endDate={endQuarter}
                    dateFormat="yyyy, QQQ"
                    showQuarterYearPicker
                    className="bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                  <DatePicker
                    selected={getByMonth}
                    onChange={(date) => setGetByMonth(date as Date)}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    className=" bg-white ml-4 block w-40 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                {/* {restByContainer !== undefined && (
                  <CSVLink
                    filename={`Tồn kho-${Date.now()}.csv`}
                    data={ExportDataStockFormatter(restByContainer)}
                    onClick={() => {
                      ToastSuccess("Tải về file Excel thành công!");
                    }}
                    className="ml-4 flex-shrink-0 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-md px-4 py-4"
                  >
                    <span>Xuất excel tồn kho</span>
                  </CSVLink>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className=" mb-6 p-4">
          <div className=" flex justify-between space-x-2 overflow-hidden overflow-x-auto">
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-72">
              <span className="text-xl font-medium text-gray-800">
                Tháng này đã Gate In
              </span>
              <div className="flex items-center text-green-700 space-x-3">
                <span className="text-xl font-bold text-green-700">
                  {containerGateIn && containerGateIn.length} container
                </span>
              </div>
            </div>
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-72">
              <span className="text-xl font-medium text-gray-800">
                Tháng này đã Gate Out
              </span>
              <div className="flex items-center text-green-700 space-x-3">
                <span className="text-xl font-bold text-green-700">
                  {containerGateOut && containerGateOut.length} container
                </span>
              </div>
            </div>
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-60">
              <span className="text-xl font-medium text-gray-800">
                Lợi nhuận
              </span>
              <div className="flex items-center text-green-700 space-x-3">
                <span className="text-xl font-bold text-green-700">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].revenue !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].revenue
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>
            <div className="flex flex-col flex-shrink-0 p-8 bg-gray-200 rounded-lg border border-gray-300 w-60">
              <span className="text-xl font-medium text-gray-800">
                Doanh thu Gate In
              </span>
              <div className="flex items-center text-gray-800 space-x-3">
                <span className="text-xl font-bold">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].totalGateInRevenue !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].totalGateInRevenue
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>

            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-62">
              <span className="text-xl font-medium text-gray-800">
                Doanh thu Gate Out
              </span>
              <div className="flex items-center space-x-1">
                {/* <BiDollar className="text-2xl -ml-1" /> */}
                <span className="text-xl font-bold">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].totalGateOutRevenue !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].totalGateOutRevenue
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>
            <div className="flex flex-col p-8 flex-shrink-0 bg-gray-200 rounded-lg border border-gray-300 w-60">
              <span className="text-xl font-medium text-gray-800">
                Phí sửa chữa
              </span>
              <div className="flex items-center space-x-1">
                {/* <BiDollar className="text-2xl -ml-1" /> */}
                <span className="text-xl font-bold">
                  {totalContainer &&
                  totalContainer.length > 0 &&
                  totalContainer[0].totalRepairCost !== undefined
                    ? new Intl.NumberFormat("de-DE").format(
                        totalContainer[0].totalRepairCost
                      )
                    : "N/A"}{" "}
                  &#8363;
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết Container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldByContainer
            data={soldByContainer}
            isRest={false}
            totalSize="Tổng số container gate out"
          />
          <div className="mt-4"></div>
          <TableSoldByContainer
            data={restByContainer}
            isRest={true}
            restSize="Tổng số container trong bãi"
          />
        </div> */}
        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết nhà cung cấp:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldBySupplier
            data={soldBySupplier}
            isRest={false}
            totalSupplier="Tổng số container gate out"
          />
          <div className="mt-4"></div>
          <TableSoldBySupplier
            data={restBySupplier}
            isRest={true}
            restSupplier="Tổng số container trong bãi"
          />
        </div>

        <div className="my-8">
          <LabelComponent
            label="Bảng thống kê chi tiết size container:"
            fontSize="lg"
            rFontSize="xl"
            heightOfLine="h-8"
          />
          <TableSoldBySize
            data={soldBySizes}
            isRest={false}
            totalSize="Tổng số container gate out"
          />
          <div className="mt-4"></div>
          <TableSoldBySize
            data={restBySizes}
            isRest={true}
            restSize="Tổng số container trong bãi"
          />
        </div>
      </div>
    </div>
  );
};

export default RevenueDepot;
