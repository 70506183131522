export function isoCheckDigit(csc: string) {
  // Remove any non-alphanumeric characters and convert to upper-case.
  csc = csc.toUpperCase().replace(/[^A-Z0-9]+/g, "");

  // Check if length fits requirements.
  if (csc.length < 10 || csc.length > 11) return NaN;

  // Calculate check digit.
  var sum = 0;
  for (let i = 0; i < 10; i++) {
    // Map letters to numbers.
    let n = csc.charCodeAt(i);
    n -= n < 58 ? 48 : 55;

    // Numbers 11, 22, 33 are omitted.
    n += (n - 1) / 10;

    // Sum of all numbers multiplied by weighting.
    sum += n << i;
  }

  // Modulus of 11, and map 10 to 0.
  return (sum % 11) % 10;
}
