import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
const NotFoundPage = () => {
  return (
    <div className="bg-gradient-to-r from-blue-300 to-blue-200">
      <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-8">
            <h1 className="text-9xl font-bold text-blue-400">401</h1>
            <h1 className="text-6xl font-medium py-8">
              Không có quyền truy cập trang
            </h1>
            <p className="text-2xl pb-8 px-12 font-medium">
              Vui lòng liên hệ Admin để được cấp quyền truy cập vào trang này.
            </p>
            <button className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-pink-500 hover:to-orange-500 text-white font-semibold px-6 py-3 rounded-md mr-6">
              <Link to={ROUTES.LOGIN}>HOME</Link>
            </button>
            <button className="bg-gradient-to-r from-red-400 to-red-500 hover:from-red-500 hover:to-red-500 text-white font-semibold px-6 py-3 rounded-md">
              <a href="https://conta.vn/">Liên Hệ</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
