import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { ContainerApiProps, ImageParams } from "../../../types/apiType";
import { useDropzone } from "react-dropzone";

interface FormProps {
  closeForm: () => void;
  updateData: (id: string | undefined, formData: FormData) => Promise<void>;
  dataUpdate: ContainerApiProps;
}
const formSchema = Yup.object().shape({
  files: Yup.array()
    .min(1, "*Dữ liệu bắt buộc!")
    .max(2, "*Tối đa hình ảnh là 20")
    .test("fileSize", "*Một trong các hình ảnh lớn hơn 2MB", (values) => {
      // Check if any file size is greater than 2MB
      // console.log("values", values);
      return values?.every((value) =>
        value.size ? !value || value.size <= 2 * 1024 * 1024 : true
      );
    }),
});
export const FormUpdateImages = (props: FormProps) => {
  //declare value in fields
  const [files, setFiles] = useState<Array<ImageParams>>([]);

  // get props to index components
  const { closeForm, updateData, dataUpdate } = props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  useEffect(() => {
    focus();
    if (dataUpdate) {
      if (dataUpdate.images !== undefined && dataUpdate.images !== null) {
        setFiles(dataUpdate.images);
      }
    }
  }, [dataUpdate]);

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let formData = new FormData();
    for (const file of files) {
      if (file?.preview?.startsWith("/")) {
        formData.append("image[type]", file?.type as string);
        formData.append("image[preview]", file?.preview);
        formData.append("image[filename]", file?.filename as string);
      } else {
        formData.append("image", file as string);
      }
    }
    updateData(dataUpdate._id, formData);
  };

  // check show button action
  const showButtonAction = () => {
    return (
      <button
        type="submit"
        onClick={(e) => handleUpdateData(e)}
        className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
        disabled={
          !formik.isValid ||
          files.length > 20 ||
          !files?.every((value) =>
            value.size ? !value || value.size <= 2 * 1024 * 1024 : true
          )
        }
      >
        Cập nhật
      </button>
    );
  };

  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      files,
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  const unFocus = () => {
    inputRef.current?.blur();
  };

  useEffect(() => {
    focus();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      setFiles((prevFiles) => {
        return [...prevFiles, ...newFiles];
      });
      focus();
    },
  });

  const deleteImage = (index: number) => {
    focus();
    let newFiles = [...files];
    URL.revokeObjectURL(files[index].preview as string);
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const thumbs = files.flat().map((file, i) => (
    <div className="inline-flex mb-6 mr-2 h-full p-1" key={i}>
      <div className="flex min-w-0 overflow-hidden">
        <img
          alt="img"
          src={
            file?.preview?.startsWith("/")
              ? `data:image/jpeg;base64,${file.preview}`
              : `${file.preview}`
          }
          // className="block w-[300px] h-full rounded-md"
          className="block w-[250px] h-[400px] rounded-md"
          // Revoke data uri after image is loaded
          onLoad={() => {
            unFocus();
            // URL.revokeObjectURL(file.preview);
          }}
        />
        <div
          aria-label="Use the close button to delete the current image"
          onClick={() => deleteImage(i)}
          className="absolute"
        >
          <span className="text-white bg-red-500 rounded-md text-base px-2 pb-1 cursor-pointer">
            x
          </span>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file) => URL.revokeObjectURL(file?.preview as string));
  }, []);
  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">Cập nhật dữ liệu</p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          <div>
            <section className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input
                  {...getInputProps()}
                  accept="image/png, image/jpeg, image/jpg"
                />
                <p className="border-2 border-dashed p-2 italic text-sm text-gray-500">
                  Cập nhật hình ảnh tại đây (tối đa 20)
                </p>
              </div>
              <aside className="flex flex-row flex-wrap mt-4 justify-evenly">
                {thumbs}
              </aside>
            </section>
            <div className="text-red-400 mb-2">
              {!files?.every((value) =>
                value.size ? !value || value.size <= 2 * 1024 * 1024 : true
              ) && "Đã có hình ảnh lớn hơn 2MB"}
            </div>
            <div className="text-red-400 mb-2">
              {files.length > 20 && "Tối đa chỉ cho phép upload 20 hình"}
            </div>
          </div>
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
