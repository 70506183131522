// src/features/user/userSlice.ts
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import counterApi from "../../../api/counterApi";
import { AppErrorProps, CounterProps } from "../../../types/apiType";
import { CounterState } from "../../../types/reduxTypes";

export const initialSupplier: CounterProps = {
  autoIncrementId: 0,
};

const initialAppError: AppErrorProps = {
  message: "",
  stack: "",
  statusCode: 0, // You can set the default values you prefer
};

const initialState: CounterState = {
  loading: false,
  appError: initialAppError,
  serverError: undefined,
  totalPage: 0,
  counter: initialSupplier,
};

export const getCounterLatestAction = createAsyncThunk(
  "counters/counter",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await counterApi.getCounter();
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
export const getCounterBookingSaleLatestAction = createAsyncThunk(
  "counters/getCounterBookingSale",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await counterApi.getCounterBookingSale();
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const clearCountersAction = createAction("counter/REVERT_ALL");

const counterSlice = createSlice({
  name: "counters",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCounterLatestAction.pending, (state, action) => {
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getCounterLatestAction.fulfilled, (state, action) => {
        state.counter = action?.payload?.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getCounterLatestAction.rejected, (state, action) => {
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    builder
      .addCase(getCounterBookingSaleLatestAction.pending, (state, action) => {
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getCounterBookingSaleLatestAction.fulfilled, (state, action) => {
        state.counter = action?.payload?.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getCounterBookingSaleLatestAction.rejected, (state, action) => {
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
  },
  reducers: {},
});
export const selectCounter = (state: RootState) => state.counters;
export default counterSlice.reducer;
