// src/features/user/userSlice.ts
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import invoiceApi from "../../../api/invoiceApi";
import {
  AnalyticParams,
  AppErrorProps,
  InvoiceApiParams,
  InvoiceApiProps,
} from "../../../types/apiType";
import { InvoiceState } from "../../../types/reduxTypes";
import { SelectOptionProps } from "../../../types";

const initialStateSelectOptions: SelectOptionProps = {
  value: "",
  label: "",
};

const initialInvoice: InvoiceApiProps = {
  invoiceNumber: "",
  bookingNumber: "",
  supplier: initialStateSelectOptions,
  serviceSupplier: initialStateSelectOptions,
  containerCondition: "",
  size: "",
  contQuantity: 0,
  paymentDue: "",
  paymentStatus: 0,
  buyingPrice: "",
  sellingPrice: "",
  invoicePrice: "",
  createdAt: "",
};

const initialAppError: AppErrorProps = {
  message: "",
  stack: "",
  statusCode: 0,
};

const initialState: InvoiceState = {
  loading: false,
  data: [],
  appError: initialAppError,
  serverError: undefined,
  totalPage: 0,
  invoice: initialInvoice,
};

export const addAction = createAsyncThunk(
  "invoices/add",
  async (
    supplier: InvoiceApiProps,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      //make http call
      const response = await invoiceApi.create(supplier);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get all action
export const getAllAction = createAsyncThunk(
  "invoices/getAll",
  async (params: InvoiceApiParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await invoiceApi.getAll(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
          totalPage: response.data.totalPage,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//resetPassword action
export const updateAction = createAsyncThunk(
  "invoices/update",
  async (params: InvoiceApiProps, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await invoiceApi.update(params._id, params);
      if (response.data.result) {
        const results = {
          id: response.data.newData.id,
          message: response.data.message,
          newData: response.data.newData,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//update profile
export const getByIdAction = createAsyncThunk(
  "invoices/invoice",
  async (id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await invoiceApi.getByID(id);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);
//delete user
export const deleteAction = createAsyncThunk(
  "invoices/delete",
  async (_id: string, { rejectWithValue, getState, dispatch }) => {
    try {
      // call Api
      const response = await invoiceApi.delete(_id);
      if (response.data.result) {
        const results = {
          _id,
          data: response.data.data,
          message: response.data.message,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

//get all without limit action
export const getAllWithoutLimitAction = createAsyncThunk(
  "invoices/getAllWithoutLimit",
  async (params: AnalyticParams, { rejectWithValue, getState, dispatch }) => {
    //http call
    try {
      const response = await invoiceApi.getAllWithoutLimit(params);
      if (response.data.result) {
        const results = {
          data: response.data.data,
        };
        return results;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

export const clearInvoicesAction = createAction("invoices/REVERT_ALL");
export const clearAInvoiceAction = createAction("invoices/clear-invoice");

const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  extraReducers: (builder) => {
    //reset store
    builder.addCase(clearInvoicesAction, () => initialState);
    builder.addCase(clearAInvoiceAction, (state) => {
      state.invoice = initialInvoice;
    });
    //add actions
    builder.addCase(addAction.fulfilled, (state, action) => {
      state.loading = false;
      const { data } = action?.payload;
      state.data = state.data?.length > 0 ? state.data : [];
      state.data = [data, ...state.data];
      state.appError.message = undefined;
      state.serverError = undefined;
    });
    builder.addCase(addAction.rejected, (state, action) => {
      state.loading = false;
      state.appError.message = action?.payload as string;
      state.serverError = action.error.message;
    });

    //get All
    builder
      .addCase(getAllAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.totalPage = action?.payload?.totalPage;
        // state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get All Without limit
    builder
      .addCase(getAllWithoutLimitAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action?.payload.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getAllWithoutLimitAction.rejected, (state, action) => {
        state.loading = false;
        state.appError = action?.payload as AppErrorProps;
        state.serverError = action?.error?.message;
      });
    //get profile
    builder
      .addCase(getByIdAction.pending, (state, action) => {
        // state.loading = true;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.fulfilled, (state, action) => {
        // state.loading = false;
        state.invoice = action?.payload?.data;
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(getByIdAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //delete data by id
    builder
      .addCase(deleteAction.fulfilled, (state, action) => {
        // delete row data in store
        state.data = state.data.filter(
          (arrow) => arrow._id !== action.payload._id
        );
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(deleteAction.rejected, (state, action) => {
        // state.loading = false;
        state.appError.message = action?.payload as string | undefined;
        state.serverError = action?.error?.message;
      });
    //update data
    builder
      .addCase(updateAction.fulfilled, (state, action) => {
        // state.loading = false;
        // find and update row data in store
        const checkIndex = state.data.findIndex(
          (row) => row._id?.toString() === action?.payload?.id.toString()
        );
        if (checkIndex >= 0) {
          state.data[checkIndex] = action?.payload?.newData;
        }
        state.appError.message = undefined;
        state.serverError = undefined;
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.appError.message = undefined;
        state.serverError = action?.error?.message;
      });
  },
  reducers: {
    // addSocketAction: (state, action) => {
    //   state.data = state.data?.length > 0 ? state.data : [];
    //   state.data = [action.payload, ...state.data];
    //   state.appError.message = undefined;
    //   state.serverError = undefined;
    // },
    // updateSocketAction: (state, action) => {
    //   const checkIndex = state.data.findIndex(
    //     (row) => row._id?.toString() === action?.payload?._id.toString()
    //   );
    //   if (checkIndex >= 0) {
    //     state.data[checkIndex] = action?.payload;
    //   }
    //   state.appError.message = undefined;
    //   state.serverError = undefined;
    // },
    // deleteSocketAction: (state, action) => {
    //   state.data = state.data.filter((arrow) => arrow._id !== action.payload);
    //   state.appError.message = undefined;
    //   state.serverError = undefined;
    // },
  },
});
export const selectInvoice = (state: RootState) => state.invoices;
// export const { addSocketAction, deleteSocketAction, updateSocketAction } =
//   invoiceSlice.actions;
export default invoiceSlice.reducer;
