import React from "react";
import { Outlet } from "react-router-dom";
import { UserState } from "../../types/reduxTypes";
import NotFoundPage from "../../components/NotFoundPage";

interface AdminProtectRouteProps {
  user: UserState;
}

const OutletProtectDepotRoute: React.FC<AdminProtectRouteProps> = ({
  user,
}) => {
  const { userAuth } = user;

  if (userAuth?.role === 0 || userAuth?.role === 3) {
    return <Outlet />;
  } else {
    return (
      <div>
        <NotFoundPage />
      </div>
    );
  }
};

export default OutletProtectDepotRoute;
