import React, { useContext, useState } from "react";
import { DraggableLocation, DropResult } from "react-beautiful-dnd";
import { v4 } from "uuid";
import { ColumnType } from "../../../../types/apiType";

type DragDropProps = (
  source: DraggableLocation,
  destination: DraggableLocation
) => void;

// handle the manipulation of placeholder for row
type RowDropshadowProps = (
  event: any,
  destinationIndex: number,
  sourceIndex: number
) => void;

// handle the manipulation of placeholder for column
type ColumnDropshadowProps = (
  event: any,
  destinationIndex: number,
  sourceIndex: number
) => void;

type RowDropshadow = { marginTop: number; height: number };
type ColDropshadow = { marginLeft: number; height: number };

type DragDropContextProps = {
  onSubmit: (newRow: string, colIndex: number) => void;
  handleDuplicateTask: (rowIndex: number, colIndex: number) => void;
  handleNewColumn: (newName: string) => void;
  handleRemoveTask: (rowIndex: number, colIndex: number) => void;
  handleDeleteColumn: (colIndex: number) => void;
  handleDragEnd: (result: DropResult) => void;
  handleDragStart: (event: any) => void;
  handleDragUpdate: (event: any) => void;
  rowDropshadowProps: RowDropshadow;
  colDropshadowProps: ColDropshadow;
  columns: ColumnType[];
  setColumns: React.Dispatch<React.SetStateAction<ColumnType[]>>;
  rowContainerNameArray: string;
  idArray: string;
  pickUpDirectionArray: string;
};

const DragDropContext = React.createContext<DragDropContextProps | undefined>(
  undefined
);

// grabbing element currently being dragged from the dom
const getDraggedElement = (draggableId: any) => {
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedElement = document.querySelector(domQuery);
  return draggedElement;
};

// updating the array of the placeholder by switching out the source and destination colIndex
// const getUpdatedChildrenArray = (
//    draggedElement: Element,
//    destinationIndex: number,
//    sourceIndex: number,
// ) => {
//    // grab children of the node
//    const child: Element[] = [...draggedElement!.parentNode!.children]

//    // if the indexes are the same (onDragStart) just return the dom array
//    if (destinationIndex === sourceIndex) return child
//    // get the div of item being dragged
//    const draggedItem = child[sourceIndex]

//    // remove source
//    child.splice(sourceIndex, 1)

//    // return updated array by inputting dragged item
//    return child.splice(0, destinationIndex, draggedItem)
// }

const getUpdatedChildrenArray = (
  draggedElement: Element,
  destinationIndex: number,
  sourceIndex: number
) => {
  // grab children of the node and convert them to an array
  const child: Element[] = Array.from(draggedElement!.parentNode!.children);

  // if the indexes are the same (onDragStart) just return the dom array
  if (destinationIndex === sourceIndex) return child;

  // get the div of item being dragged
  const draggedItem = child[sourceIndex];

  // remove source
  child.splice(sourceIndex, 1);

  // insert dragged item at destination index
  child.splice(destinationIndex, 0, draggedItem);

  // return updated array
  return child;
};

// isolate the number of style desired to pass as props
const getStyle = (
  updatedChildrenArray: Element[],
  destinationIndex: number,
  property: any,
  clientDirection: "clientHeight" | "clientWidth"
) =>
  updatedChildrenArray.slice(0, destinationIndex).reduce((total, curr) => {
    // get the style object of the item
    const style = window.getComputedStyle(curr);
    // isolate the # of the property desired
    const prop = parseFloat(style[property]);
    return total + curr[clientDirection] + prop;
  }, 0);

const DragDropProvider: React.FC<{
  children: React.ReactNode;
  data: ColumnType[];
  rowContainerName: string;
  id: string;
  pickUpDirection: string;
}> = ({ children, data, rowContainerName, pickUpDirection, id }) => {
  const [columns, setColumns] = useState<ColumnType[]>(data);
  const [rowContainerNameArray, setRowContainerNameArray] =
    useState<string>(rowContainerName);
  const [pickUpDirectionArray, setPickUpDirectionArray] =
    useState<string>(pickUpDirection);
  const [idArray, setIdArray] = useState<string>(id);
  const [colDropshadowProps, setColDropshadowProps] = useState<ColDropshadow>({
    marginLeft: 0,
    height: 0,
  });
  const [rowDropshadowProps, setRowDropshadowProps] = useState<RowDropshadow>({
    marginTop: 0,
    height: 0,
  });

  // handling movement of row in the same column
  // [[],[]],[]
  // const moveRowSameColumn: DragDropProps = (source, destination) => {
  //   console.log("🚀 ~ source:", source);
  //   // moving tasks in same column
  //   setColumns((prev) => {
  //     const updated = [...prev];
  //     // isolate the row of the column we want to adjust
  //     const [{ containers }] = updated.filter(
  //       ({ _id }) => _id === source.droppableId
  //     );
  //     console.log("🚀 ~ setColumns ~ containers:", containers);
  //     // remove the source item
  //     const [removed] = containers.splice(source.index, 1);
  //     // insert the source item at the new colIndex
  //     containers.splice(destination.index, 0, removed);
  //     return updated;
  //   });
  // };

  const moveRowSameColumn: DragDropProps = (source, destination) => {
    // console.log("Source:", source);
    // console.log("Destination:", destination);

    setColumns((prevColumns) => {
      const updatedColumns = prevColumns.map((column) => {
        if (column._id === source.droppableId) {
          const updatedContainers = [...column.containers];
          const [removed] = updatedContainers.splice(source.index, 1);
          updatedContainers.splice(destination.index, 0, removed);
          // console.log("Updated containers:", updatedContainers);
          return {
            ...column,
            containers: updatedContainers,
          };
        }
        return column;
      });
      // console.log("Updated columns:", updatedColumns);
      return updatedColumns;
    });
  };

  // // handling movement of row between columns
  // const moveRowDifferentColumn: DragDropProps = (source, destination) => {
  //   // moving tasks between columns
  //   setColumns((prev) => {
  //     // filter out which column is the source and which is the destination
  //     const updated = [...prev];
  //     const [sourceColumn] = updated.filter(
  //       ({ _id }) => _id === source.droppableId
  //     );
  //     const [destinationColumn] = updated.filter(
  //       ({ _id }) => _id === destination.droppableId
  //     );

  //     // extract the tasks from the columnn
  //     const sourceRow = sourceColumn.containers;
  //     const destinationRow = destinationColumn.containers;

  //     // remove the source item
  //     const [removed] = sourceRow.splice(source.index, 1);
  //     // insert the source item at the new colIndex
  //     destinationRow.splice(destination.index, 0, removed);

  //     return updated;
  //   });
  // };

  const moveRowDifferentColumn: DragDropProps = (source, destination) => {
    // moving tasks between columns
    setColumns((prev) => {
      // Deep clone the prev array
      const updated = JSON.parse(JSON.stringify(prev));

      // Find the source and destination columns
      const sourceColumn = updated.find(
        ({ _id }: any) => _id === source.droppableId
      );
      const destinationColumn = updated.find(
        ({ _id }: any) => _id === destination.droppableId
      );

      if (sourceColumn && destinationColumn) {
        // Extract the tasks from the source and destination columns
        const sourceRow = sourceColumn.containers;
        const destinationRow = destinationColumn.containers;

        // Remove the source item
        const [removed] = sourceRow.splice(source.index, 1);

        // Insert the source item at the new index in the destination row
        destinationRow.splice(destination.index, 0, removed);
      }

      return updated;
    });
  };

  // determining if its diff col or same col for row movement
  const handleRowMove: DragDropProps = (source, destination) => {
    // droppableId is in reference to what column it is so if they are the same,
    // then both droppableId's are the same,
    // if its diff columns then they not the same
    // btw since columns are dynamically instantiated, the droppableId i used is uuid

    if (source.droppableId !== destination.droppableId) {
      moveRowDifferentColumn(source, destination);
    } else {
      moveRowSameColumn(source, destination);
    }
  };

  // move columns
  const handleColumnMove: DragDropProps = (source, destination) =>
    // rememeber that source and dest are just { draggableId, index }
    // moving columns (:
    setColumns((prev) => {
      const updated = [...prev];
      // remove source column
      const [removed] = updated.splice(source.index, 1);
      // insert source column at new destination
      updated.splice(destination.index, 0, removed);
      return updated;
    });

  const handleDropshadowRow: RowDropshadowProps = (
    event,
    destinationIndex,
    sourceIndex
  ) => {
    // isolating the element being dragged
    const draggedElement = getDraggedElement(event.draggableId);
    // if we aint draggin anything return
    if (!draggedElement) return;
    // isolate the height of element to determine the height of element being dragged
    const { clientHeight } = draggedElement as Element;
    // returning the manipulated array of dom elements
    const updatedChildrenArray: Element[] = getUpdatedChildrenArray(
      draggedElement as Element,
      destinationIndex,
      sourceIndex
    );
    // grabbing the # for marginTop
    const marginTop = getStyle(
      updatedChildrenArray,
      destinationIndex,
      "marginBottom",
      "clientHeight"
    );
    // setting our props
    setRowDropshadowProps({
      height: clientHeight + 2,
      marginTop: marginTop + 2 * destinationIndex,
    });
  };

  const handleDropshadowColumn: ColumnDropshadowProps = (
    event,
    destinationIndex,
    sourceIndex
  ) => {
    // isolate element we are dragging
    const draggedElement: Element | Node | null = getDraggedElement(
      event.draggableId
    )!.parentNode!.parentNode;
    // if nothing is being dragged return
    if (!draggedElement) return;
    // isolate the height of element to determine the height of element being dragged
    const { clientHeight } = draggedElement as Element;
    // returning the manipulated array of dom elements
    const updatedChildrenArray: Element[] = getUpdatedChildrenArray(
      draggedElement as Element,
      destinationIndex,
      sourceIndex
    );
    // grabbing the # for marginLeft
    const marginLeft = getStyle(
      updatedChildrenArray,
      destinationIndex,
      "marginRight",
      "clientWidth"
    );
    // setting props
    setColDropshadowProps({
      height: clientHeight,
      marginLeft,
    });
  };

  const handleDragUpdate = (event: any) => {
    const { source, destination } = event;
    if (!destination) return;
    if (event.type === "column") {
      handleDropshadowColumn(event, destination.index, source.index);
    } else {
      handleDropshadowRow(event, destination.index, source.index);
    }
  };

  const handleDragStart = (event: any) => {
    // the destination and source colIndex will be the same for start
    const { index } = event.source;
    if (event.type === "column") {
      handleDropshadowColumn(event, index, index);
    } else {
      handleDropshadowRow(event, index, index);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    // if there is no destination, theres nothing to manipulate so lets
    // nope out of there REAL quick
    if (!result.destination) return;
    // we only care about source and destination so lets just grab those
    const { source, destination } = result;
    // if our droppableId is all-columns that means that we are
    // dragging columns around because remember we did not have to
    // dynamically instantiate our top level droppable so we hard coded
    // the id
    if (source.droppableId === "all-columns") {
      // we go this function to handle the column movement
      handleColumnMove(source, destination);
    } else {
      // else its a row move so we go here
      handleRowMove(source, destination);
    }
  };

  const handleDeleteColumn = (colIndex: number) =>
    setColumns((prev) => {
      const updated = [...prev];
      updated.filter((dat, rowIndex) => rowIndex !== colIndex);
      return updated;
    });

  const onSubmit = (newRow: string, colIndex: number) => {
    setColumns((prev) => {
      const updated = [...prev];
      updated[colIndex].containers.push({ containerNumber: newRow, _id: v4() });
      return updated;
    });
  };

  const handleRemoveTask = (rowIndex: number, colIndex: number) => {
    setColumns((prev) => {
      const updated = [...prev];
      updated[colIndex].containers.splice(rowIndex, 1);
      return updated;
    });
  };

  const handleDuplicateTask = (rowIndex: number, colIndex: number) => {
    setColumns((prev) => {
      const updated = [...prev];
      updated[colIndex].containers.push({
        containerNumber: updated[colIndex].containers[rowIndex].containerNumber,
        _id: v4(),
      });
      return updated;
    });
  };

  const handleNewColumn = (newName: string) => {
    setColumns((prev) => {
      const updated = [...prev];
      return [
        ...updated,
        {
          _id: v4(),
          title: newName,
          containers: [],
        },
      ];
    });
  };

  return (
    <DragDropContext.Provider
      value={{
        onSubmit,
        handleDuplicateTask,
        handleNewColumn,
        handleRemoveTask,
        handleDeleteColumn,
        handleDragEnd,
        handleDragStart,
        handleDragUpdate,
        rowDropshadowProps,
        colDropshadowProps,
        columns,
        rowContainerNameArray,
        pickUpDirectionArray,
        idArray,
        setColumns,
      }}
    >
      {children}
    </DragDropContext.Provider>
  );
};

export function useDragDrop() {
  const context = useContext(DragDropContext);
  if (context === undefined) {
    throw new Error("useDragDrop must be used inside DragDropProvider");
  }

  return context;
}

export default DragDropProvider;
