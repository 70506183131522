import React from "react";

const Transport = () => {
  return (
    <div>
      <p className="font-sans font-semibold text-3xl">Quản lý nhà xe</p>
    </div>
  );
};

export default Transport;
