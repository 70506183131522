import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { ContainerNotDescription } from "../../../constants/features";
import SelectedItem from "./SelectedFeatures";
export default function Navigation() {
  const location = useLocation();

  const [path, setPath] = useState(`${location.pathname.split("/")[2]}`);
  useEffect(() => {
    setPath(`${location.pathname.split("/")[2]}`);
  }, [location.pathname]);

  return (
    <div
      className={`flex justify-start items-center space-x-4 overflow-x-scroll xl:overflow-hidden`}
    >
      {ContainerNotDescription?.map((item, index) => (
        <Link to={item.href} key={index}>
          <SelectedItem isSelected={item.href === path}>
            <div
              className={`min-w-[167px] flex flex-col justify-center items-center flex-shrink-0`}
            >
              <img src={item.icon} alt="Quản lý phòng" className="w-14 h-14" />
              <span>{item.title}</span>
            </div>
          </SelectedItem>
        </Link>
      ))}
    </div>
  );
}
