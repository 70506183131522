import {
  ContainerApiProps,
  InvoiceApiProps,
  SoldByContainerProps,
} from "../types/apiType";
import { dateTimeGateInOutHelper } from "./DateHelper";
import { PaymentStatusHelper, containerStatusHelper } from "./StatusHelper";
import { transformedArray } from "./test";

export const ExportDataFormatter = (data: Array<InvoiceApiProps>) => {
  return data.map((item) => ({
    "Số hóa đơn": item.invoiceNumber,
    "Số booking": item.bookingNumber,
    "Hãng khai thác": item.supplier?.label,
    "Vị trí container": item.serviceSupplier?.label,
    // "Nhà cung ứng (khách hàng)": item.client?.label,
    "Container Condition": item.containerCondition,
    "Container Quantity": item.contQuantity,
    "Kích thước": item.size,
    "Trạng thái thanh toán": PaymentStatusHelper(item.paymentStatus),
    // "Hạn thanh toán": new Date(item.paymentDue!).toISOString().slice(0, 10),
    "Hạn thanh toán": item.paymentDue
      ? new Date(item.paymentDue).toISOString().slice(0, 10)
      : "", // You might want to set a default value if paymentDue is undefined or null
    "Giá mua": item.buyingPrice,
    "Giá bán": item.sellingPrice,
    "VAT (%)": item.VAT,
    "Giá trị hóa đơn": item.invoicePrice,
    // "Ngày lập hóa đơn": new Date(item.createdAt!).toISOString().slice(0, 10),
    "Ngày lập hóa đơn": item.createdAt
      ? new Date(item.createdAt).toISOString().slice(0, 10)
      : "", // You might want to set a default value if createdAt is undefined or null
  }));
};

export const ExportDataContainerFormatter = (
  data: Array<ContainerApiProps>
) => {
  return data.map((item, index) => ({
    STT: index + 1,
    "Số hóa đơn": item.invoiceNumber,
    "Số booking": item.bookingNumber,
    "Hãng khai thác": item.supplier?.label,
    "Vị trí container": item.serviceSupplier?.label,
    // "Nhà cung ứng (khách hàng)": item.client?.label,
    "Số container": item.containerNumber
      ? item.containerNumber
      : "Chưa cập nhật",
    "Tình trạng container": item.containerCondition,
    "Kích thước": item.size,
    "Ngày bán": item.sellContDate
      ? new Date(item.sellContDate).toISOString().slice(0, 10)
      : "", // You might want to set a default value if sellContDate is undefined or null
    "Người bán": item.seller?.label,
    "Người mua": item.buyer?.label,
    "Giá mua vào": item.buyingPrice,
    "Giá bán ra": item.sellingPrice,
    "Phí nâng/hạ": item.LOLOCost,
    "Phí kéo container": item.towingCost,
    "Phí sửa chữa": item.repairCost,
    "Chi phí khác": item.otherCosts,
    "Phí phát sinh": item.incurCost,
    "Ghi chú": item.notes,
  }));
};
export const ExportDataContainerSellerFormatter = (
  data: Array<ContainerApiProps>
) => {
  return data.map((item, index) => ({
    STT: index + 1,
    "Ngày bán": item.sellContDate
      ? new Date(item.sellContDate).toISOString().slice(0, 10)
      : "", // You might want to set a default value if sellContDate is undefined or null
    "Người bán": item.seller?.label,
    "Người mua": item.buyer?.label,
    "Kích thước": item.size,
    "Hãng khai thác": item.lineOperation?.label,
    "Vị trí container": item.serviceSupplier?.label,
    // "Nhà cung ứng (khách hàng)": item.client?.label,
    "Số container": item.containerNumber
      ? item.containerNumber
      : "Chưa cập nhật",
    "Tình trạng container": item.containerCondition,
    "Giá bán ra": item.sellingPrice,
    "VAT (%)": item.VAT,
  }));
};

export const ExportDataStockFormatter = (data: Array<SoldByContainerProps>) => {
  return data.map((item, index) => ({
    STT: index + 1,
    "Hãng khai thác": item.supplier?.label,
    "Vị trí": item.serviceSupplier?.label,
    "Kích thước container": item.sizes,
    "Tổng số container còn lại": item.totalContainers,
  }));
};

export const ExportDataGateInFormatter = (
  data: Array<ContainerApiProps>
) => {
  return data?.map((item, index) => ({
    STT: index + 1,
    "Trạng thái":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.alreadyGateIn &&
      item.listLifecycle.at(-1)?.alreadyGateOut
        ? "Gate In&Out"
        : "Gate In",
    // "Nhà cung cấp": item.supplier?.label,
    "Hãng khai thác": item.lineOperation?.label,
    "Số container": item.containerNumber,
    "Tình trạng container": item.containerCondition,
    "Kéo từ depot":
      item.contStatus === 8
        ? "Nhận từ khách"
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.from,
    "Đến depot":
      item.contStatus === 8
        ? item.serviceSupplier?.label
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.to,
    "Kích thước container": item.size,
    "Loại máy": item.brandOfUnit?.label,
    // "Trạng thái container": containerStatusHelper(item.contStatus),
    "Người nhận container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.receiver,
    "Giờ Gate In":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateIn),
    "Số xe Gate In":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateIn,
    "Phân loại":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.noteGateIn,
    "Người giao container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.deliver,
    "Giờ Gate Out":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateOut),
    "Số xe Gate Out":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateOut,
    "Ghi chú":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.noteGateOut,
    "Số ngày lưu bãi": item.storageNumber,
  }));
};

export const ExportDataGateInOutFormatter = (
  data: Array<ContainerApiProps>
) => {
  return data?.map((item, index) => ({
    STT: index + 1,
    "Trạng thái":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.alreadyGateIn &&
      item.listLifecycle.at(-1)?.alreadyGateOut
        ? "Gate Out"
        : "Gate In",
    // "Nhà cung cấp": item.supplier?.label,
    "Hãng khai thác": item.lineOperation?.label,
    "Số container": item.containerNumber,
    "Tình trạng container": item.containerCondition,
    "Kéo từ depot":
      item.contStatus === 8
        ? "Nhận từ khách"
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.from,
    "Đến depot":
      item.contStatus === 8
        ? item.serviceSupplier?.label
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.to,
    "Kích thước container": item.size,
    "Loại máy": item.brandOfUnit?.label,
    // "Trạng thái container": containerStatusHelper(item.contStatus),
    "Người nhận container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.receiver,
    "Giờ Gate In":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateIn),
    "Số xe Gate In":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateIn,
    "Phân loại":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.noteGateIn,
    "Người giao container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.deliver,
    "Giờ Gate Out":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateOut),
    "Số xe Gate Out":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateOut,
    "Ghi chú":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.noteGateOut,
    "Số ngày lưu bãi": item.storageNumber,
  }));
};

export const ExportDataGateOutFormatter = (data: Array<ContainerApiProps>) => {
  return data.map((item, index) => ({
    STT: index + 1,
    "Trạng thái":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.alreadyGateIn &&
      item.listLifecycle.at(-1)?.alreadyGateOut
        ? "Gate Out"
        : "Gate In",
    // "Nhà cung cấp": item.supplier?.label,
    "Hãng khai thác": item.lineOperation?.label,
    "Số container": item.containerNumber,
    "Tình trạng container": item.containerCondition,
    "Kéo từ depot":
      item.contStatus === 8
        ? "Nhận từ khách"
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.from,
    "Đến depot":
      item.contStatus === 8
        ? item.serviceSupplier?.label
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.to,
    "Kích thước container": item.size,
    "Loại máy": item.brandOfUnit?.label,
    "Số booking bán": item.bookingManySaleNumber,
    "Người nhận container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.receiver,
    "Giờ Gate In":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateIn),
    "Số xe Gate In":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateIn,
    "Phân loại":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.noteGateIn,
    "Người giao container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.deliver,
    "Giờ Gate Out":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateOut),
    "Số xe Gate Out":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateOut,
    "Ghi chú":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.noteGateOut,
    "Số ngày lưu bãi": item.storageNumber,
  }));
};

export const ExportDataQueryContainerFormatter = (
  data: ContainerApiProps,
  containerRevenue: string | undefined
) => {
  const dataTransfer = data.listLifecycle!?.map((item, index) => ({
    "Vòng đời": index + 1,
    "Từ vị trí": item.from,
    "Đến vị trí": item.to,
    "Người nhận container": item.receiver,
    "Giờ Gate In": dateTimeGateInOutHelper(item.dateGateIn),
    "Phân loại": item.noteGateIn,
    "Người giao container": item.deliver,
    "Giờ Gate Out": dateTimeGateInOutHelper(item.dateGateOut),
    "Ghi chú": item.noteGateOut,
  }));
  // console.log(">>>check", [
  //   {
  //     "Số hóa đơn": data.invoiceNumber,
  //     "Số booking": data.bookingNumber,
  //     "Hãng khai thác": data.supplier?.label,
  //     "Vị trí container": data.serviceSupplier?.label,
  //     "Số container": data.containerNumber
  //       ? data.containerNumber
  //       : "Chưa cập nhật",
  //     "Tình trạng container": data.containerCondition,
  //     "Kích thước": data.size,
  //     "Giá mua vào": data.buyingPrice,
  //     "Giá bán ra dự kiến": data.sellingPrice,
  //     "Giá bán ra thực tế": data.actualSellingPrice,
  //     "Người bán": data.seller?.label,
  //     "Người mua": data.buyer?.label,
  //     "Số tiền cọc": data.depositAmount,
  //     "Ngày bán": data.sellContDate
  //       ? new Date(data.sellContDate).toISOString().slice(0, 10)
  //       : "",
  //     "Trạng thái container": containerStatusHelper(data.contStatus),
  //     "Trạng thái phiếu thu": data.isReceipt
  //       ? "Đã lập phiếu thu"
  //       : "Chưa lập phiếu thu",
  //     "Phí nâng/hạ": data.LOLOCost,
  //     "Phí kéo container": data.towingCost,
  //     "Phí sửa chữa": data.repairCost,
  //     "Phí phát sinh": data.incurCost,
  //     "Chi phí khác": data.otherCosts,
  //     "Doanh thu Gate In": data.gateInRevenue,
  //     "Doanh thu Gate Out": data.gateOutRevenue,
  //     "Lợi nhuận container": containerRevenue,
  //     "Ghi chú": data.notes,
  //   },
  //   ...dataTransfer,
  // ]);
  return [
    {
      "Số hóa đơn": data.invoiceNumber,
      "Số booking": data.bookingNumber,
      "Hãng khai thác": data.supplier?.label,
      "Vị trí container": data.serviceSupplier?.label,
      "Số container": data.containerNumber
        ? data.containerNumber
        : "Chưa cập nhật",
      "Tình trạng container": data.containerCondition,
      "Kích thước": data.size,
      "Loại máy": data.brandOfUnit?.label,
      "Giá mua vào": data.buyingPrice,
      "Giá bán ra dự kiến": data.sellingPrice,
      "Giá bán ra thực tế": data.actualSellingPrice,
      "Người bán": data.seller?.label,
      "Người mua": data.buyer?.label,
      "Số tiền cọc": data.depositAmount,
      "Ngày bán": data.sellContDate
        ? new Date(data.sellContDate).toISOString().slice(0, 10)
        : "",
      "Trạng thái container": containerStatusHelper(data.contStatus),
      "Trạng thái phiếu thu": data.isReceipt
        ? "Đã lập phiếu thu"
        : "Chưa lập phiếu thu",
      "Phí nâng/hạ": data.LOLOCost,
      "Phí kéo container": data.towingCost,
      "Phí sửa chữa": data.repairCost,
      "Phí phát sinh": data.incurCost,
      "Chi phí khác": data.otherCosts,
      "Doanh thu Gate In": data.gateInRevenue,
      "Doanh thu Gate Out": data.gateOutRevenue,
      "Lợi nhuận container": containerRevenue,
      "Ghi chú": data.notes,
    },
    ...dataTransfer,
  ];
};

export const ExportUserDataQueryContainerFormatter = (
  data: ContainerApiProps,
  containerRevenue: string | undefined
) => {
  const dataTransfer = data.listLifecycle!?.map((item, index) => ({
    "Vòng đời": index + 1,
    "Từ vị trí": item.from,
    "Đến vị trí": item.to,
    "Người nhận container": item.receiver,
    "Giờ Gate In": dateTimeGateInOutHelper(item.dateGateIn),
    "Phân loại": item.noteGateIn,
    "Người giao container": item.deliver,
    "Giờ Gate Out": dateTimeGateInOutHelper(item.dateGateOut),
    "Ghi chú": item.noteGateOut,
  }));

  return [
    {
      "Số hóa đơn": data.invoiceNumber,
      "Số booking": data.bookingNumber,
      "Hãng khai thác": data.supplier?.label,
      "Vị trí container": data.serviceSupplier?.label,
      "Số container": data.containerNumber
        ? data.containerNumber
        : "Chưa cập nhật",
      "Tình trạng container": data.containerCondition,
      "Kích thước": data.size,
      "Loại máy": data.brandOfUnit?.label,
      "Người bán": data.seller?.label,
      "Người mua": data.buyer?.label,
      "Ngày bán": data.sellContDate
        ? new Date(data.sellContDate).toISOString().slice(0, 10)
        : "",
      "Trạng thái container": containerStatusHelper(data.contStatus),
      "Trạng thái phiếu thu": data.isReceipt
        ? "Đã lập phiếu thu"
        : "Chưa lập phiếu thu",
      "Ghi chú": data.notes,
    },
    ...dataTransfer,
  ];
};

// export const ExportBookingDataQueryContainerFormatter = (
//   data: ContainerApiProps,
//   containerRevenue: string | undefined
// ) => {
//   const dataTransfer = data.listLifecycle!?.map((item, index) => ({
//     "Vòng đời": index + 1,
//     "Từ vị trí": item.from,
//     "Đến vị trí": item.to,
//     "Người nhận container": item.receiver,
//     "Giờ Gate In": dateTimeGateInOutHelper(item.dateGateIn),
//     "Phân loại": item.noteGateIn,
//     "Người giao container": item.deliver,
//     "Giờ Gate Out": dateTimeGateInOutHelper(item.dateGateOut),
//     "Ghi chú Gate Out": item.noteGateOut,
//   }));

//   return [
//     {
//       "Số hóa đơn": data.invoiceNumber,
//       "Số booking": data.bookingNumber,
//       "Hãng khai thác": data.supplier?.label,
//       "Vị trí container": data.serviceSupplier?.label,
//       "Số container": data.containerNumber
//         ? data.containerNumber
//         : "Chưa cập nhật",
//       "Tình trạng container": data.containerCondition,
//       "Kích thước": data.size,
//       "Giá mua vào": data.buyingPrice,
//       "Giá bán ra dự kiến": data.sellingPrice,
//       "Giá bán ra thực tế": data.actualSellingPrice,
//       "Người bán": data.seller?.label,
//       "Người mua": data.buyer?.label,
//       "Số tiền cọc": data.depositAmount,
//       "Ngày bán": data.sellContDate
//         ? new Date(data.sellContDate).toISOString().slice(0, 10)
//         : "",
//       "Trạng thái container": containerStatusHelper(data.contStatus),
//       "Trạng thái phiếu thu": data.isReceipt
//         ? "Đã lập phiếu thu"
//         : "Chưa lập phiếu thu",
//       "Phí nâng/hạ": data.LOLOCost,
//       "Phí kéo container": data.towingCost,
//       "Phí sửa chữa": data.repairCost,
//       "Phí phát sinh": data.incurCost,
//       "Chi phí khác": data.otherCosts,
//       "Doanh thu Gate In": data.gateInRevenue,
//       "Doanh thu Gate Out": data.gateOutRevenue,
//       "Lợi nhuận container": containerRevenue,
//       "Ghi chú": data.notes,
//     },
//     ...dataTransfer,
//   ];
// };

export const ExportBookingDataQueryContainerFormatter = (
  dataArray: ContainerApiProps[],
  containerRevenue: string | undefined
) => {
  const formattedDataArray = dataArray.map((data) => {
    const dataTransfer = data.listLifecycle?.map((item, index) => ({
      "Vòng đời": index + 1,
      "Từ vị trí": item.from,
      "Đến vị trí": item.to,
      "Người nhận container": item.receiver,
      "Giờ Gate In": dateTimeGateInOutHelper(item.dateGateIn),
      "Phân loại": item.noteGateIn,
      "Người giao container": item.deliver,
      "Giờ Gate Out": dateTimeGateInOutHelper(item.dateGateOut),
      "Ghi chú": item.noteGateOut,
    }));

    return {
      "Số hóa đơn": data.invoiceNumber,
      "Số booking": data.bookingNumber,
      "Hãng khai thác": data.supplier?.label,
      "Vị trí container": data.serviceSupplier?.label,
      "Số container": data.containerNumber
        ? data.containerNumber
        : "Chưa cập nhật",
      "Tình trạng container": data.containerCondition,
      "Kích thước": data.size,
      "Loại máy": data.brandOfUnit?.label,
      "Giá mua vào": data.buyingPrice,
      "Giá bán ra dự kiến": data.sellingPrice,
      "Giá bán ra thực tế": data.actualSellingPrice,
      "Người bán": data.seller?.label,
      "Người mua": data.buyer?.label,
      "Số tiền cọc": data.depositAmount,
      "Ngày bán": data.sellContDate
        ? new Date(data.sellContDate).toISOString().slice(0, 10)
        : "",
      "Trạng thái container": containerStatusHelper(data.contStatus),
      "Trạng thái phiếu thu": data.isReceipt
        ? "Đã lập phiếu thu"
        : "Chưa lập phiếu thu",
      "Phí nâng/hạ": data.LOLOCost,
      "Phí kéo container": data.towingCost,
      "Phí sửa chữa": data.repairCost,
      "Phí phát sinh": data.incurCost,
      "Chi phí khác": data.otherCosts,
      "Doanh thu Gate In": data.gateInRevenue,
      "Doanh thu Gate Out": data.gateOutRevenue,
      "Lợi nhuận container": containerRevenue,
      "Ghi chú": data.notes,
      ...dataTransfer,
    };
  });

  const result = transformedArray(formattedDataArray);

  return result;
};

export const ExportBookingUserDataQueryContainerFormatter = (
  dataArray: ContainerApiProps[],
  containerRevenue: string | undefined
) => {
  const formattedDataArray = dataArray.map((data) => {
    const dataTransfer = data.listLifecycle?.map((item, index) => ({
      "Vòng đời": index + 1,
      "Từ vị trí": item.from,
      "Đến vị trí": item.to,
      "Người nhận container": item.receiver,
      "Giờ Gate In": dateTimeGateInOutHelper(item.dateGateIn),
      "Phân loại": item.noteGateIn,
      "Người giao container": item.deliver,
      "Giờ Gate Out": dateTimeGateInOutHelper(item.dateGateOut),
      "Ghi chú": item.noteGateOut,
    }));

    return {
      "Số hóa đơn": data.invoiceNumber,
      "Số booking": data.bookingNumber,
      "Hãng khai thác": data.supplier?.label,
      "Vị trí container": data.serviceSupplier?.label,
      "Số container": data.containerNumber
        ? data.containerNumber
        : "Chưa cập nhật",
      "Tình trạng container": data.containerCondition,
      "Kích thước": data.size,
      "Loại máy": data.brandOfUnit?.label,
      "Người bán": data.seller?.label,
      "Người mua": data.buyer?.label,
      "Ngày bán": data.sellContDate
        ? new Date(data.sellContDate).toISOString().slice(0, 10)
        : "",
      "Trạng thái container": containerStatusHelper(data.contStatus),
      "Trạng thái phiếu thu": data.isReceipt
        ? "Đã lập phiếu thu"
        : "Chưa lập phiếu thu",
      "Ghi chú": data.notes,
      ...dataTransfer,
    };
  });

  const result = transformedArray(formattedDataArray);

  return result;
};

export const ExportContainerStorageFormatter = (
  data: Array<ContainerApiProps>
) => {
  return data.map((item, index) => ({
    STT: index + 1,
    "Số hóa đơn": item.invoiceNumber,
    "Số booking": item.bookingNumber,
    "Hãng khai thác": item.lineOperation?.label,
    "Số container": item.containerNumber,
    "Kéo từ depot":
      item.contStatus === 8
        ? "Nhận từ khách"
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.from,
    "Đến depot":
      item.contStatus === 8
        ? item.serviceSupplier?.label
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.to,
    "Kích thước container": item.size,
    "Loại máy": item.brandOfUnit?.label,
    "Tình trạng container": item.containerCondition,
    "Người nhận container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.receiver,
    "Giờ Gate In":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateIn),
    "Phân loại":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.noteGateIn,
    "Số xe Gate In":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateIn,
    "Người giao container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.deliver,
    "Giờ Gate Out":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateOut),
    "Số xe Gate Out":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateOut,
    "Ghi chú":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.noteGateOut,
    "Số ngày lưu bãi": item.storageNumber,
  }));
};

export const ExportContainerSoldByMonthFormatter = (
  data: Array<ContainerApiProps>
) => {
  return data.map((item, index) => ({
    STT: index + 1,
    "Người bán": item.seller?.label,
    "Số booking mua": item?.bookingNumber,
    "Số booking bán": item?.bookingManySaleNumber,
    "Hãng khai thác": item.lineOperation?.label,
    "Số container": item.containerNumber,
    "Kéo từ depot":
      item.contStatus === 8
        ? "Nhận từ khách"
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.from,
    "Đến depot":
      item.contStatus === 8
        ? item.serviceSupplier?.label
        : item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.to,
    "Kích thước container": item.size,
    "Loại máy": item.brandOfUnit?.label,
    "Tình trạng container": item.containerCondition,
    "Giá bán": item.sellingPrice,
    "VAT (%)": item.VAT,
    "Người nhận container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.receiver,
    "Giờ Gate In":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateIn),
    "Phân loại":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.noteGateIn,
    "Số xe Gate In":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateIn,
    "Người giao container":
      item.listLifecycle !== undefined && item.listLifecycle.at(-1)?.deliver,
    "Giờ Gate Out":
      item.listLifecycle !== undefined &&
      dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateOut),
    "Số xe Gate Out":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.trunkNumberGateOut,
    "Ghi chú":
      item.listLifecycle !== undefined &&
      item.listLifecycle.at(-1)?.noteGateOut,
    "Số ngày lưu bãi": item.storageNumber,
  }));
};
