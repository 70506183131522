import * as ROUTES from "./routes";
export const navigationPrivate = [
  // {
  //   title: "Home",
  //   href: ROUTES.HOME,
  //   current: true,
  // },
  {
    title: "Quản lý Container",
    href: ROUTES.USER_CONTAINER_MANAGEMENT,
    current: false,
  },
  {
    title: "Quản lý khách hàng",
    href: ROUTES.CLIENT_USER,
    current: false,
  },
  {
    title: "Quản lý nhập/xuất",
    href: ROUTES.INVOICE_MANAGEMENT,
    current: false,
  },
  {
    title: "Truy vấn",
    href: ROUTES.QUERY_CONT,
    current: false,
  },
];

export const navigationDepotPrivate = [
  {
    title: "Quản lý Container",
    href: ROUTES.USER_CONTAINER_MANAGEMENT,
    current: false,
  },
  {
    title: "Quản lý Depot",
    href: ROUTES.CONTA_DEPOT,
    current: false,
  },
  {
    title: "Quản lý nhập/xuất",
    href: ROUTES.INVOICE_MANAGEMENT,
    current: false,
  },
  {
    title: "Quản lý khách hàng",
    href: ROUTES.CLIENT_USER,
    current: false,
  },
  {
    title: "Truy vấn",
    href: ROUTES.QUERY_CONT,
    current: false,
  },
];

export const navigationPrivateNotVerified = [
  { title: "Home", href: ROUTES.HOME, current: true },
  {
    title: "Bài viết",
    href: ROUTES.POSTS,
    current: false,
  },
];

export const navigationAdmin = [
  {
    title: "Quản lý Container",
    href: ROUTES.CONTAINER_MANAGEMENT,
    current: false,
  },
  {
    title: "Quản lý Depot",
    href: ROUTES.CONTA_DEPOT,
    current: false,
  },
  {
    title: "Quản lý đối tác",
    href: ROUTES.PARTNER_MANAGEMENT,
    current: false,
  },
  {
    title: "Quản lý nhập/xuất",
    href: ROUTES.INVOICE_MANAGEMENT,
    current: false,
  },
  {
    title: "Truy vấn",
    href: ROUTES.QUERY_CONT,
    current: false,
  },
  {
    title: "Quản lý nhân viên",
    href: ROUTES.USERS_MANAGEMENT,
    current: false,
  },
];

export const navigationPublic = [
  // {
  //   title: "Home",
  //   href: ROUTES.HOME,
  //   current: true,
  // },
];
