export const headersContainerAdmin = [
  "Số hóa đơn",
  "Số booking",
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Số tuổi",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Phân loại container",
  "VAT",
  "Giá mua vào",
  "Giá bán ra",
  // "Số tiền cọc",
  // "Thời gian cọc",
  // "Chi phí phát sinh",
  // "Chi phí khác",
  // "Chi phí nâng hạ",
  // "Chi phí kéo",
  // "Chi phí sửa chữa",
  "Hành động",
];

export const headersContainerUser = [
  // "Nhà cung cấp",
  "Số booking",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Phân loại container",
  "VAT",
  "Giá bán ra",
  "Hành động",
];

export const headersContainerDepot = [
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Số tuổi",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Giá bán ra",
  "Phí gate in",
  "Ngày Gate in",
  "Số xe Gate in",
  "Phân loại",
  "Trạng thái sửa chữa",
  "Số ngày lưu bãi",
  "Số booking container",
  "Hành động",
];

export const headersUserContainerDepot = [
  // "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Số tuổi",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Giá bán ra",
  "Phí gate in",
  "Ngày Gate in",
  "Số xe Gate in",
  "Phân loại",
  "Trạng thái sửa chữa",
  "Số ngày lưu bãi",
  "Số booking container",
  "Hành động",
];

export const headersContainerGateOutDepot = [
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Giá bán ra",
  "Phí gate in",
  "Ngày Gate in",
  "Nhân viên nhận container",
  "Phân loại",
  "Số xe Gate in",
  "Phí gate out",
  "Ngày Gate out",
  "Số xe Gate out",
  "Nhân viên giao container",
  "Ghi chú gate out",
  "Số ngày lưu bãi",
  "Số booking container",
  "Số booking bán",
];

export const headersAdminContainerGateOutDepot = [
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Giá bán ra",
  "Phí gate in",
  "Ngày Gate in",
  "Nhân viên nhận container",
  "Phân loại",
  "Số xe Gate in",
  "Phí gate out",
  "Ngày Gate out",
  "Số xe Gate out",
  "Nhân viên giao container",
  "Ghi chú gate out",
  "Số ngày lưu bãi",
  "Số booking container",
  "Số booking bán",
];

export const headersContainerMNRDepot = [
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Phân loại",
  "Nhân viên nhận sửa chữa",
  "Ngày nhận sửa chữa",
  "Ngày hoàn thành",
  "Phí sửa chữa",
  "Ghi chú sửa chữa",
  "Hành động",
];
export const headersAdminContainerMNRDepot = [
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Phân loại",
  "Nhân viên nhận sửa chữa",
  "Ngày nhận sửa chữa",
  "Ngày hoàn thành",
  "Phí sửa chữa",
  "Ghi chú sửa chữa",
  "Hành động",
];

export const headersContainerGateInOutDepot = [
  // "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Giá bán ra",
  "Phí gate in",
  "Ngày Gate in",
  "Số ngày lưu bãi",
  "Phân loại",
  "Số xe Gate in",
  // "Hành động",
];

export const headersAdminContainerGateInOutDepot = [
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "Giá bán ra",
  "Phí gate in",
  "Ngày Gate in",
  "Số ngày lưu bãi",
  "Phân loại",
  "Số xe Gate in",
  // "Hành động",
];

export const headersContainerAdminSold = [
  "Số hóa đơn",
  "Số booking",
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "VAT",
  "Giá mua vào",
  "Giá bán ra",
  "Giá bán thực tế",
  "Người bán",
  "Người mua",
  "Số tiền cọc",
  "Thời gian cọc",
  "Ngày bán",
  "Số booking sale",
  "Hạn lưu bãi",
  "Hành động",
];

export const headersContainerAccountant = [
  "Số hóa đơn",
  "Số booking",
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "VAT",
  "Giá bán ra",
  "Giá bán thực tế",
  "Người bán",
  // "Người mua",
  "Số tiền cọc",
  "Thời gian cọc",
  "Ngày bán",
  "Chi phí phát sinh",
  "Chi phí khác",
  "Chi phí nâng hạ",
  "Chi phí kéo",
  "Chi phí sửa chữa",
  "Ghi chú",
  "Hành động",
];

export const headersContainerUserSold = [
  "Số booking",
  "Hãng khai thác",
  "Vị trí",
  "Số container",
  "Tình trạng container",
  "Kích thước container",
  "Loại máy",
  "Tuổi container",
  "Hình ảnh",
  "Ảnh sữa chữa",
  "Ảnh Gate out",
  "Trạng thái container",
  "VAT",
  "Giá bán ra",
  "Giá bán thực tế",
  "Người bán",
  "Người mua",
  "Số tiền cọc",
  "Thời gian cọc",
  "Ngày bán",
  "Số booking sale",
  "Hạn lưu bãi",
  "Hành động",
];

export const headersInvoice = [
  "STT",
  "Số hóa đơn",
  "Số booking",
  "Nhà cung cấp",
  "Hãng khai thác",
  "Vị trí",
  // "Khách hàng",
  "Số lượng container",
  "Tình trạng container",
  "Kích thước container",
  "Hạn thanh toán",
  "Trạng thái thanh toán",
  "Giá mua vào",
  "Giá bán ra",
  "Giá trị hóa đơn",
  "Hành động",
];

export const headersBookingSale = [
  "STT",
  "Số booking bán",
  "Nhà cung cấp",
  "Vị trí",
  "Khách hàng",
  "Người bán",
  "Số lượng container",
  "Số lượng container còn lại",
  "Tình trạng container",
  "Kích thước container",
  "Hạn thanh toán",
  "Ngày lấy container",
  "Trạng thái thanh toán",
  "Giá bán ra",
  "Giá trị booking",
  "Số hotline",
  "Hành động",
];

export const headersSupplier = [
  "Tên nhà cung cấp",
  "Mã nhà cung cấp",
  "Email đơn",
  "Số điện thoại",
  "Mã số thuế",
  "Địa chỉ đơn",
  "Người đại diện",
  "Hành động",
];
export const headersServiceSupplier = [
  "Tên vị trí container",
  "Mã vị trí container",
  "Email",
  "Số điện thoại",
  "Mã số thuế",
  "Địa chỉ",
  "Hành động",
];

export const headersClientAdmin = [
  "Tên khách hàng",
  "Email",
  "Số điện thoại",
  "Số điện thoại 2",
  "Tên công ty",
  "Địa chỉ",
  "Người tạo",
  "Danh sách hiển thị",
  "Hành động",
];

export const headersClientUser = [
  "Tên khách hàng",
  "Email",
  "Số điện thoại",
  "Tên công ty",
  "Địa chỉ",
  "Người tạo",
  "Hành động",
];

export const headersBrandOfUnit = ["Tên loại máy", "Mãy loại máy", "Hành động"];
export const headersEmployee = [
  "Tài khoản",
  "Tên nhân viên",
  "Vị trí",
  "Ngày tạo",
  "Hành động",
];
