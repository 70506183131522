import moment from "moment";
export const dateHelper = (date: Date) => {
  // return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString()}`;
  return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`;
};

export const dateConvertHelper = (date: Date) => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const dateStringHelper = (date: string | undefined) => {
  if (date !== undefined && date !== null) {
    const convertDate = new Date(date);
    const day = ("0" + convertDate.getDate()).slice(-2);
    const month = ("0" + (convertDate.getMonth() + 1)).slice(-2);
    const year = convertDate.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
};

export const dateDDMMYYStringHelper = (date: string | undefined) => {
  if (date !== undefined && date !== null) {
    const convertDate = new Date(date);
    const day = ("0" + convertDate.getDate()).slice(-2);
    const month = ("0" + (convertDate.getMonth() + 1)).slice(-2);
    const year = convertDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
};

export const plusDateHelper = () => {
  const someDate = new Date();
  const numberOfDaysToAdd = 5;
  const result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  return moment(result).format("YYYY-MM-DD");
};

export const plusOneDateHelper = (date: Date | undefined) => {
  if (date !== undefined) {
    const numberOfDaysToAdd = 1;
    const result = date.setDate(date.getDate() + numberOfDaysToAdd);
    return moment(result).format("YYYY-MM-DD");
  }
};

export const plusAnyDateHelper = (
  date: string | undefined,
  numberOfDaysToAdd: number
) => {
  if (date !== undefined && date !== null) {
    let convertDate = new Date(date);
    convertDate.setDate(convertDate.getDate() + numberOfDaysToAdd);
    const day = ("0" + convertDate.getDate()).slice(-2);
    const month = ("0" + (convertDate.getMonth() + 1)).slice(-2);
    const year = convertDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
};

export const dateTimeSearchHelper = () => {
  const someDate = new Date();
  const result = someDate.setDate(someDate.getDate());
  return moment(result).format("YYYY-MM-DD");
};

export const dateTimeSearchStartOfWeekHelper = () => {
  const someDate = new Date();

  // Calculate the difference between today and the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffDays = someDate.getDay() - 1;

  const result = someDate.setDate(someDate.getDate() - diffDays);
  return moment(result).format("YYYY-MM-DD");
};

export const dateTimeGateInOutHelper = (dateTime: string | undefined) => {
  if (dateTime !== undefined) {
    // Convert string to Date object
    let date = new Date(dateTime);

    // Format the date into dd-MM-YYYY hh:mm:ss
    let formattedDate = `Ngày: ${("0" + date.getDate()).slice(-2)}/${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}/${date.getFullYear()} | Giờ: ${("0" + date.getHours()).slice(
      -2
    )}h:${("0" + date.getMinutes()).slice(-2)}m:${(
      "0" + date.getSeconds()
    ).slice(-2)}s`;

    return formattedDate;
  }
  return false;
};

export const currentTimeUTCPlus7 = Date.now() + 7 * 60 * 60 * 1000;

export const yearCurrentTimeUTCPlus7 = new Date(currentTimeUTCPlus7)
  .getFullYear()
  .toString()
  .slice(-2);

export const convertCurrentTimeUTCPlus7 = (currentTimeUTCPlus7: Date) => {
  const date = new Date(currentTimeUTCPlus7);

  const formattedDate = `${(date.getDate() + "").padStart(2, "0")}-${(
    date.getMonth() +
    1 +
    ""
  ).padStart(2, "0")}-${date
    .getFullYear()
    .toString()
    .slice(-2)} ${date.getHours()}:${(date.getMinutes() + "").padStart(
    2,
    "0"
  )}:${(date.getSeconds() + "").padStart(2, "0")}`;

  return formattedDate;
};

export const CTABookingCodeHelper = (autoIncrementId: string) => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear().toString().slice(-2);
  const formattedDate = `CTA${day}${month}${year}${autoIncrementId}`;
  return formattedDate;
};

export const CTABookingSaleCodeHelper = (autoIncrementId: string) => {
  // const date = new Date();
  // const day = date.getDate().toString().padStart(2, "0");
  // const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  // const year = date.getFullYear().toString().slice(-2);
  const formattedDate = `FS${autoIncrementId}`;
  // const formattedDate = `FS${day}${month}${year}${autoIncrementId}`;
  return formattedDate;
};

export const plusDateHelperWithParams = (numberOfDaysToAdd: number) => {
  const someDate = new Date();
  const result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  return moment(result).format("YYYY-MM-DD");
};
