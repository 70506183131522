import {
  AnalyticParams,
  InvoiceApiParams,
  BookingSaleApiProps,
  ApiParams,
} from "../types/apiType";
import axiosClient from "./axiosClient";

const module = "booking-many-sale";

const bookingSalesApi = {
  create: (body: BookingSaleApiProps) => {
    const url = `/${module}/`;
    return axiosClient.post(url, body);
  },
  update: (id: string | undefined, body: BookingSaleApiProps) => {
    const url = `/${module}/${id}`;
    return axiosClient.put(url, body);
  },
  delete: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.delete(url);
  },
  getByID: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.get(url);
  },
  getAll: (params: InvoiceApiParams) => {
    const url = `/${module}/search`;
    return axiosClient.get(url, { params });
  },
  getAllWithoutLimit: (params: AnalyticParams) => {
    const url = `/${module}/getAll`;
    return axiosClient.get(url, { params });
  },
  getAllBookingToDropdown: (params: ApiParams) => {
    const url = `/${module}/booking-to-dropdown`;
    return axiosClient.get(url, { params });
  },
  getAllByUser: (params: InvoiceApiParams) => {
    const url = `/${module}/booking-by-user`;
    return axiosClient.get(url, { params });
  },
};

export default bookingSalesApi;
