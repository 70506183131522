import React, { useEffect, useState } from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import styled from "styled-components";
import {
  ColumnType,
  LocationApiUpdateProps,
  TaskType,
} from "../../../../../types/apiType";
import { ColorContainerHelper } from "../../../../../utils/ColorContainerHelper";
import { closeForm, openForm } from "../../../../../redux/slices/formSlices";
import { useAppDispatch } from "../../../../../hooks/reduxHooks";
import { Transition } from "@headlessui/react";
import { FormUpdate } from "../Form/FormUpdate";
import { updateAction } from "../../../../../redux/slices/LocationContainer/LocationContainerSlice";
import ToastSuccess from "../../../../../components/Toast/success";
import ToastError from "../../../../../components/Toast/error";
import { useSelector } from "react-redux";
import { selectContainer } from "../../../../../redux/slices/Container/containerSlice";
import { selectUser } from "../../../../../redux/slices/User/UserSlice";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  border: 1px solid;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

type Props = {
  task: TaskType;
  index: number;
  column: ColumnType;
  rowContainerNameArray: string;
};

const Row: React.FC<Props> = ({
  task,
  index,
  column,
  rowContainerNameArray,
}) => {
  //redux
  const dispatch = useAppDispatch();
  const dataContainer = useSelector(selectContainer);
  const { serverError } = dataContainer;
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const [formStatusState, setFormStatusState] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [locationContainer, setLocationContainer] =
    useState<LocationApiUpdateProps>({
      idColumn: "",
      title: "",
      idContainer: "",
      rowContainerNameArray: "",
    });

  const [isRoleApprove, setIsRoleApprove] = useState(false);

  useEffect(() => {
    if (userAuth?.role === 0 || userAuth?.role === 3) {
      setIsRoleApprove(true);
    } else {
      setIsRoleApprove(false);
    }
  }, []);

  const handleOpenFormUpdate = (
    item: TaskType,
    column: ColumnType,
    rowContainerNameArray: string
  ) => {
    setFormStatusState(true);
    setIsUpdate(true);
    const action = openForm();
    dispatch(action);

    setLocationContainer({
      idColumn: column._id,
      title: column.title,
      idContainer: "",
      rowContainerNameArray: rowContainerNameArray,
      _id: item._id,
      dataUpdate: item,
    });
  };

  // close form event
  const handleCloseForm = () => {
    setFormStatusState(false);
    const action = closeForm();
    dispatch(action);
  };

  const handleUpdateData = async (
    id: string | undefined,
    data: LocationApiUpdateProps
  ) => {
    setFormStatusState(false);
    const dataParams: LocationApiUpdateProps = {
      _id: id,
      idColumn: data.idColumn,
      title: data.title,
      idContainer: data.idContainer,
      rowContainerNameArray: data.rowContainerNameArray,
      isDelete: data.isDelete,
    };
    const action = await dispatch(updateAction(dataParams));

    if (updateAction.fulfilled.match(action)) {
      // socket.emit("update-data", {
      //   room: "container",
      //   updateData: updateMessageNotification(
      //     userAuth?.currentName,
      //     action.payload.newData.containerNumber,
      //     action.payload.newData.supplier?.label,
      //     action.payload.newData.serviceSupplier?.label,
      //     "cập nhật 1 container"
      //   ),
      // });
      // dispatch(getAllNotificationAction(paramsNotification));
      ToastSuccess(action.payload.message);
    } else {
      ToastError(action.payload as string, serverError);
    }
  };

  const displayForm = () => {
    if (formStatusState) {
      return (
        <FormUpdate
          closeForm={handleCloseForm}
          // addData={handleAddData}
          updateData={handleUpdateData}
          dataUpdate={locationContainer}
          isUpdate={isUpdate}
        />
      );
    }
  };

  const generateTransition = (show: boolean) => (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {displayForm()}
    </Transition>
  );

  return (
    <Draggable draggableId={task._id} index={index}>
      {(provided: DraggableProvided) => (
        <>
          <div
            className={`flex justify-center items-center rounded-md w-full h-14 mb-4 border border-gray-500 select-none ${ColorContainerHelper(
              task.size,
              task.contStatus
            )}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onClick={() =>
              task.contStatus !== 2 &&
              task.contStatus !== 1 &&
              isRoleApprove === true &&
              handleOpenFormUpdate(task, column, rowContainerNameArray)
            }
          >
            {task.containerNumber === "Empty" ? "" : task.containerNumber}
          </div>
          {generateTransition(formStatusState)}
        </>

        // <Container
        //   {...provided.draggableProps}
        //   {...provided.dragHandleProps}
        //   ref={provided.innerRef}
        //   onClick={() => handleOpenFormUpdate(task, column)}
        // >
        //   {task.containerNumber}
        // </Container>
      )}
    </Draggable>
  );
};

export default Row;
