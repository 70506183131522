import React, { useEffect, useRef, useState } from "react";
//libraries
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
//types
import { ContainerApiProps } from "../../../types/apiType";
import { SelectOptionProps } from "../../../types";
//components
import SupplierDropDown from "../../../components/SupplierDropdown";
import ServiceSupplierDropDown from "../../../components/ServiceSupplierDropdown";
import ComponentDropDown from "../../../components/ComponentDropDown";
//utils
import {
  containerSizeHelper,
} from "../../../utils/StatusHelper";
//constants
import { dataSizeCont } from "../../../constants/dropdown";
import { isoCheckDigit } from "../../../utils/containerCheckLigit";

interface FormProps {
  closeForm: () => void;
  updateData: (
    id: string | undefined,
    data: ContainerApiProps
  ) => Promise<void>;
  dataUpdate: ContainerApiProps;
  isUpdate: boolean;
}

export const Form = (props: FormProps) => {
  const formSchema = Yup.object().shape({
    // containerCondition: Yup.object().shape({
    //   label: Yup.string().required("*Dữ liệu bắt buộc!"),
    //   value: Yup.string().required("*Dữ liệu bắt buộc!"),
    // }),
    containerCondition: Yup.string().required("*Dữ liệu bắt buộc!"),
    size: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    // VAT: Yup.number().required("*Dữ liệu bắt buộc!"),
    // buyingPrice: Yup.string().required("*Dữ liệu bắt buộc!"),
    sellingPrice: Yup.string().required("*Dữ liệu bắt buộc!"),
    containerNumber: Yup.string().required("*Dữ liệu bắt buộc!"),
    fromServiceSupplier: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
      _id: Yup.string(),
    }),
  });
  const formSchemaGateOut = Yup.object().shape({
    // containerCondition: Yup.object().shape({
    //   label: Yup.string().required("*Dữ liệu bắt buộc!"),
    //   value: Yup.string().required("*Dữ liệu bắt buộc!"),
    // }),
    containerCondition: Yup.string().required("*Dữ liệu bắt buộc!"),
    size: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
    }),
    // VAT: Yup.number().required("*Dữ liệu bắt buộc!"),
    // buyingPrice: Yup.string().required("*Dữ liệu bắt buộc!"),
    sellingPrice: Yup.string().required("*Dữ liệu bắt buộc!"),
    containerNumber: Yup.string().required("*Dữ liệu bắt buộc!"),
    toServiceSupplier: Yup.object().shape({
      label: Yup.string().required("*Dữ liệu bắt buộc!"),
      value: Yup.string().required("*Dữ liệu bắt buộc!"),
      _id: Yup.string(),
    }),
    gateOutRevenue: Yup.string()
      .required("*Dữ liệu bắt buộc!")
      .test("Phải là số dương!", "Giá trị phải lớn hơn 0!", (value) => {
        if (value !== undefined) {
          return parseFloat(value) >= 0;
        }
      }),
  });
  //declare value in fields
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [bookingNumber, setBookingNumber] = useState("");
  const [supplier, setSupplier] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [fromServiceSupplier, setFromServiceSupplier] =
    useState<SelectOptionProps>({
      value: "",
      label: "",
      _id: "",
    });
  const [toServiceSupplier, setToServiceSupplier] = useState<SelectOptionProps>(
    {
      value: "",
      label: "",
      _id: "",
    }
  );
  const [client, setClient] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });
  const [paymentStatus, setPaymentStatus] = useState<SelectOptionProps>({
    label: "",
    value: "",
  });
  // const [containerCondition, setContainerCondition] =
  //   useState<SelectOptionProps>({
  //     label: "",
  //     value: "",
  //   });
  const [containerCondition, setContainerCondition] = useState<string | number>(
    ""
  );
  const [size, setSize] = useState<SelectOptionProps>({
    label: "",
    value: "",
  });
  const [VAT, setVAT] = useState(0);
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [containerNumber, setContainerNumber] = useState("");
  const [gateInRevenue, setGateInRevenue] = useState("");
  const [notes, setNotes] = useState("");

  // get props to index components
  const { closeForm, updateData, dataUpdate, isUpdate } = props;

  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  useEffect(() => {
    focus();
    if (dataUpdate) {
      if (dataUpdate.invoiceNumber !== undefined) {
        setInvoiceNumber(dataUpdate.invoiceNumber);
      }
      if (dataUpdate.bookingNumber !== undefined) {
        setBookingNumber(dataUpdate.bookingNumber);
      }
      if (dataUpdate.supplier !== undefined && dataUpdate.supplier !== null) {
        setSupplier(dataUpdate.supplier);
      }
      if (
        dataUpdate.serviceSupplier !== undefined &&
        dataUpdate.serviceSupplier !== null
      ) {
        setFromServiceSupplier(dataUpdate.serviceSupplier);
      }
      if (dataUpdate.client !== undefined && dataUpdate.client !== null) {
        setClient(dataUpdate.client);
      }
      if (dataUpdate.containerCondition !== undefined) {
        // const containerConditionUpdate = {
        //   value: dataUpdate.containerCondition,
        //   label: containerConditionHelper(dataUpdate.containerCondition),
        // };
        // setContainerCondition(containerConditionUpdate);
        setContainerCondition(dataUpdate.containerCondition);
      }
      if (dataUpdate.size !== undefined) {
        const sizeUpdate = {
          value: dataUpdate.size,
          label: containerSizeHelper(dataUpdate.size),
        };
        setSize(sizeUpdate);
      }
      if (dataUpdate.VAT !== undefined) {
        setVAT(dataUpdate.VAT);
      }
      if (dataUpdate.sellingPrice !== undefined) {
        setSellingPrice(dataUpdate.sellingPrice);
      }
      if (dataUpdate.buyingPrice !== undefined) {
        setBuyingPrice(dataUpdate.buyingPrice);
      }
      if (dataUpdate.containerNumber !== undefined) {
        setContainerNumber(dataUpdate.containerNumber);
      }
      if (dataUpdate.gateInRevenue !== undefined) {
        setGateInRevenue(dataUpdate.gateInRevenue);
      }
      if (dataUpdate.notes !== undefined) {
        setNotes(dataUpdate.notes);
      }
    }
  }, [dataUpdate]);

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataUpdateNew: ContainerApiProps = {
      invoiceNumber: formik.values.invoiceNumber,
      bookingNumber: formik.values.bookingNumber,
      buyingPrice:
        typeof formik.values.buyingPrice == "string"
          ? formik.values.buyingPrice.replace(/,/g, "")
          : formik.values.buyingPrice,
      sellingPrice:
        typeof formik.values.sellingPrice == "string"
          ? formik.values.sellingPrice.replace(/,/g, "")
          : formik.values.sellingPrice,
      // containerCondition: formik.values.containerCondition.value,
      containerCondition: formik.values.containerCondition,
      VAT: formik.values.VAT,
      size: formik.values.size.value,
      containerNumber: formik.values.containerNumber,
      supplier:
        formik.values.supplier?.value === "" ? null : formik.values?.supplier,
      client: formik.values.client?.value === "" ? null : formik.values?.client,
      fromServiceSupplier:
        formik.values.fromServiceSupplier?.value === ""
          ? null
          : formik.values?.fromServiceSupplier,
      toServiceSupplier:
        formik.values.toServiceSupplier?.value === ""
          ? null
          : formik.values?.toServiceSupplier,
      gateInRevenue:
        typeof formik.values.gateInRevenue == "string"
          ? formik.values.gateInRevenue.replace(/,/g, "")
          : formik.values.gateInRevenue,
      notes: formik.values.notes,
    };
    updateData(dataUpdate._id, dataUpdateNew);
  };

  // check show button action
  const showButtonAction = () => {
    return (
      <button
        type="submit"
        onClick={(e) => handleUpdateData(e)}
        className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:cursor-not-allowed"
        disabled={!formik.isValid}
      >
        Cập nhật
      </button>
    );
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invoiceNumber,
      bookingNumber,
      supplier,
      fromServiceSupplier,
      toServiceSupplier,
      client,
      containerCondition,
      size,
      paymentStatus,
      buyingPrice,
      sellingPrice,
      VAT,
      containerNumber,
      gateInRevenue,
      notes,
    },
    validationSchema: isUpdate ? formSchema : formSchemaGateOut,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
  }, []);
  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-1/2 max-h-full mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">Cập nhật container</p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          <div className="flex lg:flex-row flex-col justify-between mb-5 mt-9">
            <div className="flex flex-col w-full">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="containerNumber"
                  id="containerNumber"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.containerNumber}
                  onChange={formik.handleChange("containerNumber")}
                  onBlur={formik.handleBlur("containerNumber")}
                  autoFocus
                />
                <label
                  htmlFor="containerNumber"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Số container
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.containerNumber &&
                  formik.errors.containerNumber}
              </div>
              <div className="text-red-400 mb-2">
                {isoCheckDigit(
                  formik.values.containerNumber.trim()
                ).toString() ===
                formik.values.containerNumber.charAt(
                  formik.values.containerNumber.length - 1
                )
                  ? ""
                  : "*Số container không hợp lệ!"}
              </div>
            </div>
          </div>
          <div className="mb-6">
            <div className="flex flex-col w-full">
              <div className="flex flex-col flex-1 lg:mr-1 mr-0">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Hãng khai thác
                </label>
                <SupplierDropDown
                  value={formik.values.supplier}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.supplier}
                  touched={formik.touched.supplier}
                  isUpdating={supplier}
                  isDisabled={true}
                />
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-6 lg:mb-12">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Ví trí container <span className="text-red-500">*</span>
              </label>
              <ServiceSupplierDropDown
                value={formik.values.fromServiceSupplier}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.fromServiceSupplier}
                touched={formik.touched.fromServiceSupplier}
                isUpdating={fromServiceSupplier}
                isDisabled={true}
                fieldNameUpdate="fromServiceSupplier"
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-0 lg:mb-4">
            <div className="flex flex-col w-full mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <textarea
                  name="floating_containerCondition"
                  id="floating_containerCondition"
                  className="block ml-2 py-2.5 px-0 w-full h-24 text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.containerCondition}
                  onChange={formik.handleChange("containerCondition")}
                  onBlur={formik.handleBlur("containerCondition")}
                />
                <label
                  htmlFor="floating_containerCondition"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Tình trạng container <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.containerCondition &&
                  formik.errors.containerCondition}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-10">
            {/* <div className="flex flex-col w-full lg:mr-1 mr-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Tình trạng container <span className="text-red-500">*</span>
              </label>
              <ComponentDropDown
                value={formik.values.containerCondition}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.containerCondition}
                touched={formik.touched.containerCondition}
                isUpdating={containerCondition}
                nameField="containerCondition"
                dataArray={dataContCondition}
                // isDisabled={true}
              />
            </div> */}
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:mt-0">
              <label
                htmlFor="small"
                className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
              >
                Kích thước <span className="text-red-500">*</span>
              </label>
              <ComponentDropDown
                value={formik.values.size}
                onChange={(fieldName, value) =>
                  formik.setFieldValue(fieldName, value)
                }
                onBlur={formik.setFieldTouched}
                error={formik.errors.size}
                touched={formik.touched.size}
                isUpdating={size}
                nameField="size"
                dataArray={dataSizeCont}
                isDisabled={true}
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-0 lg:mb-8">
            <div className="flex flex-col w-full mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  type="text"
                  name="floating_sellingPrice"
                  id="floating_sellingPrice"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.sellingPrice}
                  onChange={formik.handleChange("sellingPrice")}
                  onBlur={formik.handleBlur("sellingPrice")}
                  readOnly
                />
                <label
                  htmlFor="floating_sellingPrice"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Giá bán (VNĐ)<span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.sellingPrice && formik.errors.sellingPrice}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  type="text"
                  name="floating_gateInRevenue"
                  id="floating_gateInRevenue"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.gateInRevenue}
                  onChange={formik.handleChange("gateInRevenue")}
                  onBlur={formik.handleBlur("gateInRevenue")}
                  readOnly
                />
                <label
                  htmlFor="floating_gateInRevenue"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Thu phí Gate In <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.gateInRevenue && formik.errors.gateInRevenue}
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full mt-6 lg:mt-0">
              <div className="relative z-0 group border border-gray-300 rounded-md">
                <textarea
                  name="floating_notes"
                  id="floating_notes"
                  className="block ml-2 py-2.5 px-0 w-full h-24 text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.notes}
                  onChange={formik.handleChange("notes")}
                  onBlur={formik.handleBlur("notes")}
                />
                <label
                  htmlFor="floating_notes"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Ghi chú
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.notes && formik.errors.notes}
              </div>
            </div>
          </div>
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
