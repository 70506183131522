import React, { useEffect } from "react";
import {
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { useDragDrop } from "../DragDropProvider";
import { Row } from "../Row";
import {
  Container,
  DropshadowContainer,
  RowContainer,
  RowDropshadow,
  Title,
  TitleContainer,
} from "./Column.styled";
import { ColumnType } from "../../../../../types/apiType";

type Props = {
  column: ColumnType;
  columnIndex: number;
  rowContainerNameArray: string;
};

const Column: React.FC<Props> = ({
  column,
  columnIndex,
  rowContainerNameArray,
}) => {
  const { rowDropshadowProps } = useDragDrop();

  return (
    <Draggable draggableId={column._id} index={columnIndex}>
      {(provided: DraggableProvided) => (
        <Container {...provided.draggableProps} ref={provided.innerRef}>
          {/* <TitleContainer>
            <Title {...provided.dragHandleProps}>{column.title}</Title>
          </TitleContainer> */}
          <Droppable droppableId={column._id} type="task">
            {(prov: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <RowContainer ref={prov.innerRef} {...prov.droppableProps}>
                {column.containers?.map((container, taskIndex) => (
                  <Row
                    key={container._id}
                    task={container}
                    index={taskIndex}
                    column={column}
                    rowContainerNameArray={rowContainerNameArray}
                  />
                ))}
                {prov.placeholder}

                <DropshadowContainer>
                  {snapshot.isDraggingOver && (
                    <RowDropshadow
                      margintop={rowDropshadowProps.marginTop}
                      height={rowDropshadowProps.height}
                    />
                  )}
                </DropshadowContainer>
              </RowContainer>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
