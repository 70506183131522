import { useEffect } from "react";
import NavItem from "./Navigation/NavItem";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { selectUser } from "../../redux/slices/User/UserSlice";
import { useSelector } from "react-redux";
export default function InvoiceManagement() {
  const location = useLocation();
  const userData = useSelector(selectUser);
  const { userAuth } = userData;
  const title = userAuth?.role === 0 ? "Quản lý mua vào" : "Quản lý bán ra";

  useEffect(() => {
    document.title = title;
  }, []);

  const isInvoiceRoute = location.pathname === ROUTES.INVOICE_MANAGEMENT;
  // if the current route is INVOICE_MANAGEMENT, redirect to the INVOICE route
  if (isInvoiceRoute && userAuth?.role === 0) {
    return <Navigate to={ROUTES.INVOICE} replace />;
  }
  if (isInvoiceRoute) {
    return <Navigate to={ROUTES.BOOKING_SALE_USER} replace />;
  }

  return (
    <>
      <div className="bg-blue-100 h-screen pt-4">
        <div className="flex flex-col bg-slate-50 mx-2 rounded-xl p-4 drop-shadow-sm">
          {/* Nav features */}
          <NavItem />
        </div>
        <div className="mt-2">
          <Outlet />
        </div>
      </div>
    </>
  );
}
