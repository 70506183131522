import { createAction, createSlice } from "@reduxjs/toolkit";
import { SelectCheckboxState } from "../../../types/reduxTypes";
import { RootState } from "../../store";

export const toggleItemAction = createAction(
  "select/toggle-item",
  function prepare(itemSelected: any) {
    return {
      payload: {
        itemSelected,
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const selectAllAction = createAction(
  "select/select-all",
  function prepare(allItem: any) {
    return {
      payload: {
        allItem,
      },
    };
  }
);

export const toggleItemServiceAction = createAction(
  "select/toggle-item-service"
);
export const clearSelectionAction = createAction("select/clear-selection");

const initialState: SelectCheckboxState = {
  selected: [],
  selectedOld: [],
};

//this is change a little bit in dataRoom
const selectSlices = createSlice({
  name: "selects",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(toggleItemAction, (state, action) => {
      const { itemSelected } = action.payload;
      //check exist
      const index = state.selected
        .map((item) => item._id?.toString())
        .indexOf(itemSelected._id?.toString());
      if (index === -1) {
        state.selected.push(itemSelected);
      } else {
        state.selected.splice(index, 1);
      }
    });
    builder.addCase(clearSelectionAction, (state, action) => {
      state.selected = [];
    });
    builder.addCase(selectAllAction, (state, action) => {
      const { allItem } = action.payload;
      if (state.selected.length === allItem.length) {
        state.selected = [];
      } else {
        state.selected = allItem;
      }
    });
  },
  reducers: {},
});

export const selectSelects = (state: RootState) => state.selects;

export default selectSlices.reducer;
