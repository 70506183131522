import React from "react";

interface SelectedItemProps {
  children: React.ReactNode;
  onClick?: () => void;
  isSelected: boolean;
}

const SelectedItem: React.FC<SelectedItemProps> = ({
  children,
  onClick,
  isSelected,
}) => {
  const defaultClasses = `border-2 hover:border-blue-600 p-3 rounded-lg`;
  const selectedClasses = `border-blue-600 border-b-4`;

  return (
    <div
      className={`${defaultClasses} ${isSelected ? selectedClasses : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default SelectedItem;
