import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { ClientApiProps } from "../../../types/apiType";
// Define the props type for ListItem
interface ListItemProps {
  data: Array<ClientApiProps> | null;
  openFormUpdate: (id: string) => void;
}

export const ListItem: React.FC<ListItemProps> = ({ data, openFormUpdate }) => {
  const handleOpenFormUpdate = (id: string | undefined) => {
    if (id !== undefined) {
      openFormUpdate(id);
    }
  };

  return (
    <>
      {data?.map((item, index) => (
        <tr className="bg-gray-50" key={item._id}>
          <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {index + 1}
          </td>
          <td className="px-1 py-1 whitespace-nowrap border border-slate-500">
            <div className="flex items-center">
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {item?.clientName}
                </div>
              </div>
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.email === "" ? "---" : item?.email}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item.phoneNumberBackup === "" ||
            item.phoneNumberBackup === undefined
              ? item.phoneNumber || ""
              : item.phoneNumberBackup || ""}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.companyName === "" ? "---" : item?.companyName}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.address === "" ? "---" : item?.address}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500">
            {item?.user?.currentName === "" ? "---" : item?.user?.currentName}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500 border border-slate-500 space-x-2">
            <div
              onClick={() => handleOpenFormUpdate(item._id)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <FaUserAlt
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span className="text-white">Cập nhật dữ liệu</span>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
