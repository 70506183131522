import React, { useState } from "react";
//utils
import { Reload } from "../../../components/Reload";
import { dataNumberOfLimit, dataSizeCont } from "../../../constants/dropdown";
//libraries
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
//icons
import { HiSearch } from "react-icons/hi";
import { AiFillDollarCircle } from "react-icons/ai";
// redux
import { selectSelects } from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import { useSelector } from "react-redux";
//types
import {
  ServiceSupplierApiProps,
  SupplierApiProps,
} from "../../../types/apiType";
import { dateConvertHelper, dateHelper } from "../../../utils/DateHelper";
interface SearchProps {
  handleSearch: (
    keySearch: string,
    contStatus: number,
    supplierId: string | undefined,
    serviceSupplierId: string | undefined,
    size: string | undefined,
    startDate: string,
    endDate: string
  ) => void;
  openFormSellMany: () => void;
  dataServiceSupplier: Array<ServiceSupplierApiProps>;
  dataSupplier: Array<SupplierApiProps>;
}
export const Search = (props: SearchProps) => {
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const [keySearch, setKeySearch] = useState("");
  const [numberOfLimit] = useState(5);
  const [size] = useState();
  const [serviceSupplierId] = useState();
  const [supplierId] = useState();
  const today = new Date();

  // Calculate the difference between today and the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffDays = today.getDay() - 1; // Assuming Monday is the first day of the week

  // Calculate the date range to cover the current week from Monday to today
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - diffDays);

  // Use the calculated start of the week in useState
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfWeek,
    today,
  ]);
  const [startDate, endDate] = dateRange;
  const { handleSearch, openFormSellMany, dataServiceSupplier, dataSupplier } =
    props;

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      numberOfLimit,
      size,
      serviceSupplierId,
      supplierId,
    },
    onSubmit: () => {},
  });

  // search event
  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (endDate && startDate !== null) {
      handleSearch(
        keySearch.trim(),
        formik.values.numberOfLimit,
        formik.values.supplierId,
        formik.values.serviceSupplierId,
        formik.values.size,
        dateConvertHelper(startDate),
        dateConvertHelper(endDate)
      );
    } else {
      handleSearch(
        keySearch.trim(),
        formik.values.numberOfLimit,
        formik.values.supplierId,
        formik.values.serviceSupplierId,
        formik.values.size,
        dateHelper(today),
        dateHelper(today)
      );
    }
  };
  const handleOpenFormSellMany = () => {
    openFormSellMany();
  };
  return (
    <div className="h-28 flex items-center w-full bg-slate-100 mt-3 overflow-hidden overflow-x-auto">
      <select
        id="ward"
        className="bg-white mx-4 block w-55 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        value={formik.values.numberOfLimit}
        onChange={formik.handleChange("numberOfLimit")}
        onBlur={formik.handleBlur("numberOfLimit")}
      >
        <option value={5}>{`5`}</option>
        {dataNumberOfLimit?.map((item, index) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <form className="w-full md:w-1/2">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Tìm
        </label>
        <div className="flex gap-4">
          <div className="relative w-65 flex-shrink-0">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <HiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-65 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline "
              placeholder="Nhập từ khóa ..."
              required
              onChange={(e) => setKeySearch(e.target.value)}
              value={keySearch}
            />
          </div>
          <select
            id="ward"
            className="bg-white ml-4 block w-55 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            value={formik.values.size}
            onChange={formik.handleChange("size")}
            onBlur={formik.handleBlur("size")}
          >
            <option value={undefined}>{`-- Chọn size --`}</option>
            {dataSizeCont?.map((item, index) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select
            id="ward"
            className="bg-white ml-4 block w-55 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            value={formik.values.supplierId}
            onChange={formik.handleChange("supplierId")}
            onBlur={formik.handleBlur("supplierId")}
          >
            <option value={undefined}>{`-- Chọn nhà cung cấp --`}</option>
            {dataSupplier?.map((item, index) => (
              <option value={item._id} key={item._id}>
                {item.codeSupplier}
              </option>
            ))}
          </select>
          <div>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              // isClearable={true}
              className=" bg-white ml-4 block w-52 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <button
            type="submit"
            className="text-white right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
            onClick={handleClickSearch}
          >
            Tìm
          </button>
          <Reload />

          {selected.length > 1 && (
            <>
              <div
                onClick={() => handleOpenFormSellMany()}
                className="ml-4 inline-flex flex-shrink-0 justify-center px-4 py-4 border-0 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <AiFillDollarCircle
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span className="text-white">Bán tất cả</span>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};
