import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { ContainerApiProps, CounterProps } from "../../../types/apiType";
import { NumericFormat } from "react-number-format";
import MyDatePicker from "../../../components/DatetimePicker";
import ClientDropDownByUser from "../../../components/ClientDropDownByUser";
import { SelectOptionProps } from "../../../types";
import {
  CTABookingCodeHelper,
  plusDateHelper,
  plusDateHelperWithParams,
} from "../../../utils/DateHelper";
import { getCounterLatestAction } from "../../../redux/slices/Counter/CounterSlice";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { v4 as uuidv4 } from "uuid";
interface FormProps {
  closeForm: () => void;
  updateData: (data: ContainerApiProps) => Promise<void>;
  counter: CounterProps;
}

export const FormSellMany = (props: FormProps) => {
  //redux
  const dispatch = useAppDispatch();
  const formSchema = Yup.object().shape({
    actualSellingPrice: Yup.string().test(
      "Phải là số dương!",
      "Giá trị phải lớn hơn 0!",
      (value) => {
        if (value !== undefined) {
          return parseFloat(value) > 0;
        }
      }
    ),
    isDeposit: Yup.boolean(),
    depositAmount: Yup.string().when("isDeposit", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("*Dữ liệu bắt buộc!")
          .test("Phải là số dương!", "Giá trị phải lớn hơn 0!", (value) => {
            if (value !== undefined) {
              return parseFloat(value) > 0;
            }
          }),
      otherwise: (schema) => schema,
    }),
    depositDuration: Yup.string().when("isDeposit", {
      is: true, // alternatively: (val) => val == true
      then: (schema) => schema.required("*Dữ liệu bắt buộc!"),
      otherwise: (schema) => schema,
    }),
    client: Yup.object()
      .required("*Dữ liệu bắt buộc!")
      .test(
        "Dữ liệu bắt buộc!",
        "Dữ liệu bắt buộc!",
        (value: SelectOptionProps) => value.value !== undefined
      ),
    storageTime: Yup.string().required("*Dữ liệu bắt buộc!"),
  });

  //declare value in fields
  const [client, setClient] = useState<SelectOptionProps>({
    value: "",
    label: "",
    _id: "",
  });

  const [depositAmount, setDepositAmount] = useState("0");
  const [actualSellingPrice, setActualSellingPrice] = useState("0");
  const [depositDuration, setDepositDuration] = useState("");
  const [isDeposit, setIsDeposit] = useState(false);
  const [autoIncrementId, setAutoIncrementId] = useState(0);
  const [storageTime, setStorageTime] = useState("");
  // get props to index components
  const { closeForm, updateData, counter } = props;
  //useRef
  const inputRef = useRef<HTMLInputElement | null>(null);
  //get dataUpdate

  // close form event
  const handleCloseForm = () => {
    closeForm();
  };

  const handleUpdateData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataUpdateNew: ContainerApiProps = {
      actualSellingPrice:
        typeof formik.values.actualSellingPrice == "string"
          ? formik.values.actualSellingPrice.replace(/,/g, "")
          : formik.values.actualSellingPrice,
      depositAmount: formik.values.isDeposit
        ? typeof formik.values.depositAmount == "string"
          ? formik.values.depositAmount.replace(/,/g, "")
          : formik.values.depositAmount
        : "0",
      buyer: formik.values.client?.value === "" ? null : formik.values?.client,
      contStatus: formik.values.isDeposit ? 1 : 2,
      depositDuration: formik.values.isDeposit
        ? formik.values.depositDuration
        : "",
      saleBookingNumber: CTABookingCodeHelper(
        formik.values.autoIncrementId.toString().padStart(3, "0")
      ),
      storageTime: formik.values.storageTime,
    };
    updateData(dataUpdateNew);
  };

  // check show button action
  const showButtonAction = () => {
    return (
      <button
        type="submit"
        onClick={(e) => handleUpdateData(e)}
        className="focus:outline-none text-white bg-green-600 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 disabled:cursor-not-allowed"
        disabled={!formik.isValid}
      >
        Xác nhận bán
      </button>
    );
  };
  //update
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      client,
      depositAmount,
      depositDuration,
      isDeposit,
      actualSellingPrice,
      autoIncrementId,
      storageTime,
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  });
  const focus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    focus();
    dispatch(getCounterLatestAction());
    setStorageTime(plusDateHelperWithParams(7));
  }, []);
  useEffect(() => {
    setDepositAmount(formik.values.depositAmount);
    setActualSellingPrice(formik.values.actualSellingPrice);
    setClient(formik.values.client);
    setDepositDuration(formik.values.depositDuration);
    setIsDeposit(formik.values.isDeposit);
    setAutoIncrementId(counter.autoIncrementId);
  }, [counter]);
  return (
    <>
      <div className="bg-black opacity-50 fixed w-full h-full top-0 z-40"></div>
      <div className="w-full sm:w-1/2 h-[540px] mb-2 p-4 bg-white fixed overflow-y-scroll top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 animated-image-slide z-50 border-2 border-state-500">
        <p className="font-sans text-2xl md:text-3xl">Bán nhiều container</p>
        <button
          className="w-full inline-flex justify-end"
          onClick={() => handleCloseForm()}
        >
          <AiOutlineClose className="text-3xl" />
        </button>

        <form>
          <div className="mb-10">
            <div className="flex flex-col w-full">
              <div className="flex flex-col flex-1 ">
                <label
                  htmlFor="small"
                  className="block mb-2 text-sm font-sm text-gray-500 dark:text-gray-500"
                >
                  Khách mua <span className="text-red-500">*</span>
                </label>
                <ClientDropDownByUser
                  value={formik.values.client}
                  onChange={(fieldName, value) =>
                    formik.setFieldValue(fieldName, value)
                  }
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.client}
                  touched={formik.touched.client}
                  isUpdating={client}
                />
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-10 mt-9">
            <div className="flex flex-col w-full">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <input
                  type="text"
                  name="autoIncrementId"
                  id="autoIncrementId"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={CTABookingCodeHelper(
                    formik.values.autoIncrementId.toString().padStart(3, "0")
                  )}
                  onChange={formik.handleChange("autoIncrementId")}
                  onBlur={formik.handleBlur("autoIncrementId")}
                  readOnly
                />
                <label
                  htmlFor="autoIncrementId"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Số booking sale <span className="text-red-500">*</span>
                </label>
              </div>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col justify-between mb-6">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  type="text"
                  name="floating_actualSellingPrice"
                  id="floating_actualSellingPrice"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.actualSellingPrice}
                  onChange={formik.handleChange("actualSellingPrice")}
                  onBlur={formik.handleBlur("actualSellingPrice")}
                />
                <label
                  htmlFor="floating_actualSellingPrice"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Giá bán thực tế <span className="text-red-500">*</span>
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.actualSellingPrice &&
                  formik.errors.actualSellingPrice}
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:-mt-5">
              <label
                htmlFor="floating_depositDuration"
                className="block text-sm font-sm text-gray-500 "
              >
                Hạn lưu bãi <span className="text-red-500">*</span>
              </label>
              <select
                id="ward"
                className="bg-white block w-55 p-[11px] text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                value={formik.values.storageTime}
                onChange={formik.handleChange("storageTime")}
                onBlur={formik.handleBlur("storageTime")}
              >
                <option value={plusDateHelperWithParams(7)} key={uuidv4()}>
                  7
                </option>
                <option value={plusDateHelperWithParams(14)} key={uuidv4()}>
                  14
                </option>
                <option value={plusDateHelperWithParams(30)} key={uuidv4()}>
                  30
                </option>
              </select>
            </div>
          </div>

          <div className="mb-6 mt-4">
            <label
              htmlFor="publish_isDeposit"
              className="relative inline-block w-11 h-6 items-center"
            >
              <div className="flex flex-row w-32">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={formik.values.isDeposit}
                  onChange={formik.handleChange("isDeposit")}
                  id={`publish_isDeposit`}
                />
                <div className="w-11 h-6 bg-gray-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                <span className="ml-4 text-sm text-gray-800">
                  {formik.values.isDeposit ? "Có cọc" : "Không cọc"}
                </span>
              </div>
            </label>
          </div>

          <div className="flex lg:flex-row flex-col justify-between mb-8">
            <div className="flex flex-col w-full lg:mr-1 mr-0">
              <div className="relative z-0 group border border-gray-300 rounded-md ">
                <NumericFormat
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  type="text"
                  name="floating_depositAmount"
                  id="floating_depositAmount"
                  className="block ml-2 py-2.5 px-0 w-full text-sm border-transparent text-gray-500 bg-transparent appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={formik.values.depositAmount}
                  onChange={formik.handleChange("depositAmount")}
                  onBlur={formik.handleBlur("depositAmount")}
                />
                <label
                  htmlFor="floating_depositAmount"
                  className="peer-focus:font-medium ml-2 absolute text-sm text-gray-500 dark:text-gray-500 duration-300 transform -translate-y-9 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9 "
                >
                  Tiền cọc mỗi container{" "}
                  {formik.values.isDeposit ? (
                    <span className="text-red-500">*</span>
                  ) : null}
                </label>
              </div>
              <div className="text-red-400 mb-2">
                {formik.touched.depositAmount && formik.errors.depositAmount}
              </div>
            </div>
            <div className="flex flex-col w-full lg:ml-1 ml-0 mt-6 lg:-mt-5">
              <label
                htmlFor="floating_depositDuration"
                className="block text-sm font-sm text-gray-500 "
              >
                Thời gian cọc đến ngày{" "}
                {formik.values.isDeposit ? (
                  <span className="text-red-500">*</span>
                ) : null}
              </label>
              <MyDatePicker
                value={formik.values.depositDuration}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                error={formik.errors.depositDuration}
                touched={formik.touched.depositDuration}
                isUpdating={depositDuration}
                nameField="depositDuration"
                maxDate={plusDateHelper()}
              />
            </div>
          </div>
          {showButtonAction()}
          <button
            type="button"
            className="focus:outline-none text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={() => handleCloseForm()}
          >
            Hủy
          </button>
        </form>
      </div>
    </>
  );
};
