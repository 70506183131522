import React, { useEffect } from "react";
// icons
import { AiFillDollarCircle } from "react-icons/ai";
import { BsFillCloudUploadFill, BsImages } from "react-icons/bs";
import { GiOpenGate } from "react-icons/gi";
import { FaPencilAlt } from "react-icons/fa";
//redux
import { useAppDispatch } from "../../../hooks/reduxHooks";
import {
  deleteAction,
  getAllAction,
} from "../../../redux/slices/Container/containerSlice";
import {
  selectSelects,
  toggleItemAction,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
import { useSelector } from "react-redux";
//types
import { ContainerApiProps, UserApiProps } from "../../../types/apiType";
//libraries
import Swal from "sweetalert2";
import { Socket } from "socket.io-client";
//utils
import {
  contStatusMNRHelper,
  containerSizeHelper,
  containerStatusHelper,
} from "../../../utils/StatusHelper";
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import { dateTimeGateInOutHelper } from "../../../utils/DateHelper";
import LoadingComponent from "../../../components/LoadingComponent";
import { v4 as uuidv4 } from "uuid";
// Define the props type for ListItem
interface ListItemProps {
  data: Array<ContainerApiProps> | null;
  openFormUpdate: (id: string) => void;
  openFormUpdateImages: (id: string) => void;
  openFormUpdateImagesAfterRepair: (id: string) => void;
  openFormUpdateImagesGateOut: (id: string) => void;
  openFormSellCont: (id: string) => void;
  openFormGateIn: (id: string) => void;
  openFormGateOut: (id: string) => void;
  openSlide: (id: string, currentNameField: string) => void;
  socket: Socket;
  userAuth?: UserApiProps;
  loadingImagesGateOut?: boolean;
  loadingImagesGateIn?: boolean;
  loadingImagesMNR?: boolean;
  containerUpdate?: ContainerApiProps;
}

export const ListItem: React.FC<ListItemProps> = ({
  data,
  openFormUpdate,
  socket,
  userAuth,
  openSlide,
  openFormUpdateImages,
  openFormUpdateImagesAfterRepair,
  openFormUpdateImagesGateOut,
  openFormSellCont,
  openFormGateIn,
  openFormGateOut,
  loadingImagesGateOut,
  loadingImagesGateIn,
  loadingImagesMNR,
  containerUpdate,
}) => {
  const dispatch = useAppDispatch();

  const getSelects = useSelector(selectSelects);
  const { selected } = getSelects;

  const handleOpenFormUpdate = (id: string | undefined) => {
    if (id !== undefined) {
      openFormUpdate(id);
    }
  };
  useEffect(() => {
    if (socket) {
      const invoiceDeleteReceiveHandler = (deletedID: string) => {
        // dispatch(deleteSocketAction(deletedID));
        const params = {
          keyword: "",
          offset: 0,
          limit: 5,
        };
        dispatch(getAllAction(params));
      };
      socket.on("delete-receive", invoiceDeleteReceiveHandler);
      return () => {
        // Clean up the event listener when the component unmounts
        socket.off("delete-receive", invoiceDeleteReceiveHandler);

        // Leave the "invoice" room
        socket.emit("leave-room", "container");
      };
    }
  }, []);
  // delete data event
  const handleDelete = (id: string | undefined) => {
    Swal.fire({
      title:
        "Xóa hóa đơn sẽ dẫn đến xóa các container đã được tạo tự động. Bạn có chắc muốn xóa dữ liệu này không?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed && id !== undefined) {
        const action = await dispatch(deleteAction(id));
        if (deleteAction.fulfilled.match(action)) {
          socket.emit("delete-data", {
            room: "container",
            deleteId: id,
          });
          ToastSuccess(action.payload.message);
        } else {
          ToastError(action.payload as string);
        }
      } else if (result.isDenied) {
        Swal.fire("Bạn vẫn chưa xóa!", "", "info");
      }
    });
  };
  const handleSelection = (itemSelected: ContainerApiProps) => {
    // dispatch(toggleItemAction({ itemSelected }));
    const { _id } = itemSelected;
    dispatch(toggleItemAction({ _id }));
  };
  const openSlideShow = (id: string, currentNameField: string) => {
    openSlide(id, currentNameField);
  };
  const openFormUpdateImagesForm = (id: string) => {
    openFormUpdateImages(id);
  };
  const openFormUpdateImagesAfterRepairForm = (id: string) => {
    openFormUpdateImagesAfterRepair(id);
  };
  const openFormUpdateImagesGateOutForm = (id: string) => {
    openFormUpdateImagesGateOut(id);
  };

  const handleOpenFormSellCont = (id: string | undefined) => {
    if (id !== undefined) {
      openFormSellCont(id);
    }
  };
  const handleOpenFormUpdateGateIn = (id: string | undefined) => {
    if (id !== undefined) {
      openFormGateIn(id);
    }
  };
  const handleOpenFormUpdateGateOut = (id: string | undefined) => {
    if (id !== undefined) {
      openFormGateOut(id);
    }
  };
  return (
    <>
      {data?.map((item, index) => (
        <tr className="bg-gray-50" key={uuidv4()}>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {index + 1}
          </td>
          {userAuth?.role === 0 ? (
            <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
              <div>
                {!item?.supplier?.label ? "---" : item?.supplier?.label}
              </div>
            </td>
          ) : null}

          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.lineOperation?.label ? "---" : item?.lineOperation?.label}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.serviceSupplier?.label
                ? "---"
                : item?.serviceSupplier?.label}
            </div>
            {/* <div>{!item?.client?.label ? "---" : item?.client?.label}</div> */}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.containerNumber ? "---" : item?.containerNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.containerCondition ? "---" : item?.containerCondition}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {containerSizeHelper(item?.size)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 divide-y">
            <div>
              {!item?.brandOfUnit?.label ? "---" : item?.brandOfUnit?.label}
            </div>
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.yearOfManufacture ? "---" : item?.yearOfManufacture}
          </td>

          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {loadingImagesGateIn && containerUpdate?._id === item._id ? (
              <LoadingComponent />
            ) : !item?.hasImage && !item?.images?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() => openFormUpdateImagesForm(item?._id!)}
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "images")}
              >
                <div className="bg-blue-600 p-2 rounded group relative w-max flex items-center gap-2 mt-2">
                  <BsImages className="w-5 h-5" />
                  Xem hình
                </div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() => openFormUpdateImagesForm(item?._id!)}
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 ">
            {loadingImagesMNR && containerUpdate?._id === item._id ? (
              <LoadingComponent />
            ) : !item?.hasImagesAfterRepair &&
              !item?.imagesAfterRepair?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() =>
                    openFormUpdateImagesAfterRepairForm(item?._id!)
                  }
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "imagesAfterRepair")}
              >
                <div className="bg-blue-600 p-2 mt-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white w-[102px] group cursor-pointer"
                  onClick={() =>
                    openFormUpdateImagesAfterRepairForm(item?._id!)
                  }
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {loadingImagesGateOut && containerUpdate?._id === item._id ? (
              <LoadingComponent />
            ) : !item?.hasImagesGateOut && !item?.imagesGateOut?.length ? (
              <div className="flex items-center justify-center gap-9">
                <div>Chưa có hình</div>
                <div
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white group cursor-pointer "
                  onClick={() => openFormUpdateImagesGateOutForm(item?._id!)}
                >
                  <BsFillCloudUploadFill className="w-5 h-5 cursor-pointer" />
                  Upload
                </div>
              </div>
            ) : (
              <div
                className="flex gap-4 divide-x text-white group cursor-pointer"
                onClick={() => openSlideShow(item?._id!, "imagesGateOut")}
              >
                <div className="bg-blue-600 mt-2 p-2 rounded group relative w-max flex items-center gap-2">
                  <BsImages className="w-5 h-5 " />
                  Xem hình
                </div>
                <div
                  onClick={() => openFormUpdateImagesGateOutForm(item?._id!)}
                  className="bg-blue-600 p-2 rounded flex items-center mt-2 gap-2 text-white w-[102px] group cursor-pointer"
                >
                  <BsFillCloudUploadFill className="w-5 h-5" />
                  Upload
                </div>
              </div>
            )}
          </td>

          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {containerStatusHelper(item?.contStatus)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              parseInt(!item?.sellingPrice ? "0" : item?.sellingPrice)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {new Intl.NumberFormat("de-DE").format(
              parseInt(!item?.gateInRevenue ? "0" : item?.gateInRevenue)
            )}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.listLifecycle !== undefined &&
            item.listLifecycle.at(-1)?.alreadyGateIn &&
            !item.listLifecycle.at(-1)?.alreadyGateOut &&
            dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateIn) !==
              false
              ? dateTimeGateInOutHelper(item.listLifecycle.at(-1)?.dateGateIn)
              : "Chưa cập nhật ngày Gate in"}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.listLifecycle !== undefined &&
              item.listLifecycle.at(-1)?.alreadyGateIn &&
              item.listLifecycle.at(-1)?.trunkNumberGateIn}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {item.listLifecycle !== undefined &&
              item.listLifecycle.at(-1)?.alreadyGateIn &&
              !item.listLifecycle.at(-1)?.alreadyGateOut &&
              item.listLifecycle.at(-1)?.noteGateIn}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {contStatusMNRHelper(item.contStatus, item.MNR?.alreadyMNR)}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.storageNumber ? "---" : item?.storageNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500">
            {!item?.saleBookingNumber ? "---" : item?.saleBookingNumber}
          </td>
          <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-800 border border-slate-500 space-x-2">
            {item.listLifecycle !== undefined &&
              item.listLifecycle.at(-1)?.alreadyGateIn &&
              !item.listLifecycle.at(-1)?.alreadyGateOut && (
                <div
                  onClick={() => handleOpenFormUpdateGateOut(item?._id)}
                  className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <GiOpenGate
                    className="-ml-1 mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="text-white">Xác nhận Gate Out</span>
                </div>
              )}
            {/* {item.listLifecycle !== undefined &&
              item.listLifecycle.at(-1)?.alreadyGateIn &&
              !item.listLifecycle.at(-1)?.alreadyGateOut &&
              (item.contStatus === 2 ||
                item.contStatus === 8 ||
                item.contStatus === 1) && (
                <div
                  onClick={() => handleOpenFormUpdateGateOut(item?._id)}
                  className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <GiOpenGate
                    className="-ml-1 mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="text-white">Xác nhận Gate Out</span>
                </div>
              )} */}
            <div
              onClick={() => handleOpenFormUpdate(item?._id)}
              className="inline-flex justify-center px-4 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <FaPencilAlt
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span className="text-white">Cập nhật container</span>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
