import React, { useEffect } from "react";
import Select, { ActionMeta } from "react-select";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useSelector } from "react-redux";
import {
  selectServiceSuppliers,
  getAllWithoutLimitAction,
} from "../../redux/slices/ServiceSupplier/ServiceSupplierSlice";
import { SelectOptionProps } from "../../types";
import { ServiceSupplierApiProps } from "../../types/apiType";
import { FormikErrors, FormikTouched } from "formik";

type CustomStyles = {
  container: (provided: any) => any;
  control: (provided: any, state: { isFocused: boolean }) => any;
  option: (provided: any, state: { isSelected: boolean }) => any;
};

const customStyles: CustomStyles = {
  container: (provided) => ({
    ...provided,
    borderRadius: "0.5rem",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.5rem",
    "&:hover": {
      borderColor: state.isFocused ? "#9CA3AF" : "#D1D5DB",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E5E7EB" : "#FFFFFF",
    color: state.isSelected ? "#111827" : "#374151",
    "&:hover": {
      backgroundColor: "#D1D5DB",
    },
  }),
};

interface SelectOptionDropDownProps {
  value: SelectOptionProps;
  onChange: (
    fieldName: string,
    value: SelectOptionProps | null,
    actionMeta: ActionMeta<SelectOptionProps>
  ) => void;
  onBlur: (fieldName: string, value: boolean) => void;
  isUpdating: SelectOptionProps | null;
  error?: FormikErrors<SelectOptionProps>;
  touched?: boolean | FormikTouched<SelectOptionProps> | undefined;
  isDisabled?: boolean;
  fieldNameUpdate: string;
}

export default function LineOperationDropDown(
  props: SelectOptionDropDownProps
) {
  const dispatch = useAppDispatch();
  const allServiceSuppliers = useSelector(selectServiceSuppliers);
  const { data } = allServiceSuppliers;

  useEffect(() => {
    const params = {
      keyword: "",
      offset: 0,
      limit: 100,
    };
    dispatch(getAllWithoutLimitAction(params));
  }, [dispatch]);

  const getData = data
    ?.filter(
      (item) =>
        item.codeServiceSupplier === "CTA" ||
        item.codeServiceSupplier === "TTL" ||
        item.codeServiceSupplier === "BEL"
    )
    .map((serviceSupplier: ServiceSupplierApiProps) => {
      return {
        label: serviceSupplier?.codeServiceSupplier,
        value: serviceSupplier?._id,
        _id: serviceSupplier?._id,
      };
    });

  const handleChange = (
    value: SelectOptionProps | null,
    actionMeta: ActionMeta<SelectOptionProps>
  ) => {
    props.onChange(props.fieldNameUpdate, value, actionMeta);
  };

  const handleBlur = () => {
    props.onBlur(props.fieldNameUpdate, true);
  };
  return (
    <div>
      <Select
        placeholder={<span className="text-sm text-gray-500">-- Chọn --</span>}
        options={getData}
        styles={customStyles}
        onChange={handleChange}
        onBlur={handleBlur}
        value={props.value}
        isMulti={false}
        isClearable={true}
        isDisabled={!props.isDisabled ? false : true}
      />
      {/* Display */}
      {props.error && props.touched && (
        <div className="text-red-400 ">{props.error.value}</div>
      )}
    </div>
  );
}
