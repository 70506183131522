import { createSlice } from "@reduxjs/toolkit";

// Define the type for your state
interface FormState {
  formStatus: boolean;
}

const initialState: FormState = {
  formStatus: false,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    openForm: (state) => {
      state.formStatus = true;
    },
    closeForm: (state) => {
      state.formStatus = false;
    },
  },
});

const { actions } = formSlice;
export const { openForm, closeForm } = actions;
export const selectForm = (state: { form: FormState }) => state?.form;
export default formSlice.reducer;
