import React, { useState, useEffect } from "react";
import Select from "react-select";
const customStyles = {
  container: (provided) => ({
    ...provided,
    borderRadius: "0.5rem",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0.5rem",
    "&:hover": {
      borderColor: state.isFocused ? "#9CA3AF" : "#D1D5DB",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#E5E7EB" : "#FFFFFF",
    color: state.isSelected ? "#111827" : "#374151",
    "&:hover": {
      backgroundColor: "#D1D5DB",
    },
  }),
};

export default function RoleDropdown(props) {
  const [roleValue, setRoleValue] = useState(props?.value?.label);

  const dataRole = [
    { name: "Nhân viên", id: 1 },
    { name: "Kế toán", id: 2 },
    { name: "Depot", id: 3 },
    { name: "Sửa chữa", id: 4 },
  ];

  const allRole = dataRole?.map((role) => {
    return {
      label: role?.name,
      value: role?.id,
    };
  });
  useEffect(() => {
    if (props.isUpdating) {
      setRoleValue(props.isUpdating);
    }
  }, [props.isUpdating]);

  const handleChange = (value) => {
    props.onChange("role", value);
    setRoleValue(value);
  };
  const handleBlur = () => {
    props.onBlur("role", true);
  };
  return (
    <div>
      <Select
        options={allRole}
        styles={customStyles}
        placeholder={<span className="text-sm text-gray-500">-- Chọn --</span>}
        onChange={handleChange}
        onBlur={handleBlur}
        value={roleValue}
        // value={props?.value?.label}
      />
      {/* Display */}
      {props?.error && props.touched && (
        <div className="text-red-400 ">{props?.error}</div>
      )}
    </div>
  );
}
