import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserState } from "../../types/reduxTypes";
import { useAppDispatch } from "../../hooks/reduxHooks";
import LogoutConfirmation from "../../components/Logout/LogoutConfirmation";

interface AdminProtectRouteProps {
  user: UserState;
}

const OutletProtectRoute: React.FC<AdminProtectRouteProps> = ({ user }) => {
  const { userAuth } = user;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // if (userAuth?.role !== 0) {
  //   LogoutConfirmation(
  //     navigate,
  //     dispatch,
  //     "Chỉ có admin mới thực hiện thao tác này!"
  //   );
  //   // Return a placeholder or loading component, for example
  //   return <div>Loading...</div>;
  // } else 
  if (userAuth) {
    return <Outlet />;
  } else {
    return <div>Unauthorized</div>;
  }
};

export default OutletProtectRoute;
