import {
  ContainerApiProps,
  UpdateManyContainerProps,
} from "../../types/apiType";

export const updateMessageNotification = (
  userName: string | undefined,
  containerNumber: string,
  supplierName: string,
  serviceSupplierName: string,
  label: string
) => {
  if (containerNumber) {
    return `${userName} vừa ${label} ${containerNumber} tại ${serviceSupplierName}`;
  } else {
    return `${userName} vừa ${label} ${supplierName} tại ${serviceSupplierName}`;
  }
};

export const updateMessageWithBookingNumberNotification = (
  userName: string | undefined,
  containerNumber: string,
  supplierName: string,
  serviceSupplierName: string,
  label: string,
  bookingNumber: string
) => {
  if (containerNumber) {
    return `${userName} vừa ${label} ${containerNumber} tại ${serviceSupplierName} của hóa đơn ${bookingNumber}`;
  } else {
    return `${userName} vừa ${label} ${supplierName} tại ${serviceSupplierName}`;
  }
};

export const updateMessageGateOutNotification = (
  userName: string | undefined,
  containerNumber: string,
  supplierName: string,
  serviceSupplierName: string,
  label: string,
  trunkNumberGateOut: string
) => {
  if (containerNumber) {
    return `${userName} vừa ${label} ${containerNumber} tại ${serviceSupplierName} với xe kéo ${trunkNumberGateOut}`;
  } else {
    return `${userName} vừa ${label} ${supplierName} tại ${serviceSupplierName} với xe kéo  ${trunkNumberGateOut}`;
  }
};

export const updateMessageWithStatusNotification = (
  userName: string | undefined,
  containerNumber: string,
  supplierName: string,
  serviceSupplierName: string,
  label: string,
  contStatus: string
) => {
  if (containerNumber) {
    return `${userName} vừa ${label} ${containerNumber} tại ${serviceSupplierName}. Trạng thái hiện tại của container là ${contStatus}`;
  } else {
    return `${userName} vừa ${label} ${supplierName} tại ${serviceSupplierName}. Trạng thái hiện tại của container là ${contStatus}`;
  }
};

export const updateMessageInvoiceNotification = (
  userName: string | undefined,
  supplierName: string,
  serviceSupplierName: string,
  label: string,
  numberOfContainer: number
) => {
  return `${userName} vừa ${label} ${numberOfContainer} container ${supplierName} tại ${serviceSupplierName}`;
};

export const updateMessageBookingSaleNotification = (
  userName: string | undefined,
  supplierName: string,
  serviceSupplierName: string,
  label: string,
  numberOfContainer: number,
  bookingManySaleNumber: string
) => {
  return `${userName} vừa ${label} ${bookingManySaleNumber} với  ${numberOfContainer} container ${supplierName} tại ${serviceSupplierName}.`;
};

export const updateMessageManyContainersNotification = (
  userName: string | undefined,
  listIdContainer: Array<UpdateManyContainerProps>,
  label: string
) => {
  // return a string containing the values of props in object, concatenated
  function concatValues(
    object: UpdateManyContainerProps,
    props: Array<string>
  ) {
    const values = props.map((prop: string) => {
      if (prop === "containerNumber") {
        // ? `<b>${object[prop]}</b>`
        return object[prop] !== undefined ? object[prop] : "ASIS";
      } else {
        return object[prop].label;
      }
    });
    return values.join(" - ");
  }

  // return an object with it's Id renamed and it's props concatenated
  function transform(object: UpdateManyContainerProps, props: Array<string>) {
    return { concatName: concatValues(object, props) };
  }

  const myPropertyList = ["containerNumber", "supplier", "serviceSupplier"];
  const arrayB = listIdContainer.map((item) => transform(item, myPropertyList));

  // Extract concatName values from arrayB
  const concatNames = arrayB.map((item) => item.concatName).join(" | ");

  return `${userName} vừa ${label} ${listIdContainer.length} container: ${concatNames}`;
};

export const updateMessageCostNotification = (
  userName: string | undefined,
  containerNumber: string,
  supplierName: string,
  serviceSupplierName: string,
  label: string,
  seller: string
) => {
  if (containerNumber) {
    return `${userName} vừa ${label} ${containerNumber} tại ${serviceSupplierName} của ${seller}`;
  } else {
    return `${userName} vừa ${label} ${supplierName} tại ${serviceSupplierName} của ${seller}`;
  }
};
