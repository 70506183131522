import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoConta from "../../../img/logo-conta.png";
import { navigationAdmin } from "../../../constants/navigation";
import NavItem from "../NavItem";
import boss from "../../../img/boss.png";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import LogoutConfirmation from "../../Logout/LogoutConfirmation";
import NotificationListComponent from "../../NotificationListComponent";
import NotificationMobile from "../../NotificationListComponent/NotificationMobile";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const AdminNavbar = memo(function AdminNavbar({
  isLogin,
  notificationData,
  showRedDotFirstTime,
  totalResult,
  totalUnRead,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const userNavigation = [
    { title: "Hồ sơ của bạn", href: `/profile/${isLogin?._id}` },
  ];
  const [listNotification, setListNotification] = useState(notificationData);
  const dispatch = useAppDispatch();

  useMemo(() => {
    setListNotification(notificationData);
  }, [notificationData]);

  const [showRedDot, setShowRedDot] = useState(showRedDotFirstTime);

  useEffect(() => {
    setShowRedDot(notificationData.some((item) => item.status === false));
  }, [notificationData]);

  const handleLogout = () => {
    LogoutConfirmation(navigate, dispatch, "Bạn có chắc là muốn đăng xuất?");
    // Return a placeholder or loading component, for example
    return <div>Loading...</div>;
  };

  // const handleSeeMoreClick = (offset) => {
  //   const updatedOffset = offset + limitNotification;
  //   const params1 = {
  //     keyword: "",
  //     limitNotification,
  //     offset: updatedOffset,
  //   };
  //   setOffSetValue(updatedOffset);
  //   dispatch(getAllNotificationAction(params1));
  // };

  // const handleChangeStatus = (id) => {
  //   dispatch(updateStatusNotificationAction(id));
  // };

  // const handleReadAll = () => {
  //   dispatch(updateAllStatusNotificationAction());
  // };
  const [locationCurrent, setLocationCurrent] = useState("");
  useEffect(() => {
    setLocationCurrent(`/${location.pathname.split("/")[1]}`);
  }, [location.pathname]);
  return (
    <Disclosure as="nav" className="background-color-base">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 ">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 lg:hidden flex items-center">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <AiOutlineClose className="block h-6 w-6 " />
                    ) : (
                      <AiOutlineMenu className="block h-6 w-6 " />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  {/* Logo */}
                  <img
                    src={logoConta}
                    className="hidden lg:block lg:w-16 lg:h-16 lg:py-2 lg:rounded-xl"
                    alt="logo"
                  />
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                  <NavItem navigationArr={navigationAdmin} />
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden lg:flex-shrink-0 lg:flex lg:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative z-10">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full bg-white"
                              src={boss}
                              alt="Admin avatar"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {userNavigation.map((item, index) => (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.title}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="hidden lg:flex-shrink-0 lg:flex lg:items-center">
                  {/* Notifications */}
                  {/* <Menu as="div" className="ml-3 relative z-10">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full bg-white"
                              src={bell}
                              // onClick={handleLinkClick}
                              alt="bell avatar"
                            />
                          </Menu.Button>
                        </div>

                        {showRedDot && (
                          <span className="absolute top-0 right-0 h-3 w-3 bg-red-500 rounded-full"></span>
                        )}
                        {open && (
                          <div className="custom-triangle custom-triangle-5 absolute"></div>
                        )}
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute top-10 right-0 w-[512px] max-h-[360px] overflow-hidden overflow-y-auto rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div className="w-full max-w-lg p-3 bg-white border border-gray-200 rounded-md shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                              <div className="flex items-center justify-between mb-4">
                                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                                  Thông báo từ hệ thống
                                </h5>
                                {showRedDot && (
                                  <div
                                    className="cursor-pointer text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                                    onClick={() => handleReadAll()}
                                  >
                                    Đánh dấu là đã đọc
                                  </div>
                                )}
                              </div>
                              {listNotification.map((item, index) => (
                                <Menu.Item key={index}>
                                  {({ active }) => (
                                    <>
                                      <div className="flow-root">
                                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                          <li
                                            className={`py-3 sm:py-4 hover:bg-gray-200 rounded-lg p-2 ${
                                              item.status
                                                ? "hover:cursor-default"
                                                : "hover:cursor-pointer"
                                            }`}
                                            onClick={() => {
                                              if (item.status === false) {
                                                handleChangeStatus(item._id);
                                              }
                                            }}
                                          >
                                            <div className="flex items-center">
                                              <div className="flex-shrink-0">
                                                {item.status ? (
                                                  <MdMarkEmailRead className="w-8 h-8 rounded-full" />
                                                ) : (
                                                  <MdMarkEmailUnread className="w-8 h-8 rounded-full" />
                                                )}
                                              </div>
                                              <div className="flex-1 min-w-0 ms-4 ">
                                                <p className="text-sm font-medium text-gray-900 wrap dark:text-white">
                                                  {item.description}
                                                </p>
                                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                  {dateTimeGateInOutHelper(
                                                    item.createdAt
                                                  )}
                                                </p>
                                              </div>
                                              <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                {item.status ? null : (
                                                  <span className="inline-flex items-center justify-center w-2 h-2 ms-2 text-xs font-semibold text-[#0866ff] bg-[#0866ff] rounded-full"></span>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </>
                                  )}
                                </Menu.Item>
                              ))}
                              {totalResult !== undefined &&
                                offSetValue + limitNotification <
                                  totalResult && (
                                  <div
                                    onClick={() =>
                                      handleSeeMoreClick(offSetValue)
                                    }
                                    className={
                                      "block py-2 text-sm text-gray-700 cursor-pointer hover:text-blue-500"
                                    }
                                  >
                                    Xem thêm {">>>"}
                                  </div>
                                )}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu> */}
                  <NotificationListComponent
                    showRedDot={showRedDot}
                    listNotification={listNotification}
                    totalResult={totalResult}
                    totalUnRead={totalUnRead}
                  />
                </div>
                <div className="flex-shrink-0 lg:ml-4">
                  {/* Logout */}
                  <button
                    onClick={handleLogout}
                    // onClick={() => dispatch(logoutAction())}
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-red-500"
                  >
                    <HiOutlineLogout
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Log out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigationAdmin.map((item, index) => (
                <Link
                  to={item.href}
                  key={index}
                  className={classNames(
                    locationCurrent === item.href
                      ? "bg-gray-900 text-white"
                      : "text-white hover:bg-gray-700 hover:text-white",
                    "block py-2 px-3 rounded-md text-base font-medium"
                  )}
                  // className={classNames(
                  //   item.current
                  //     ? "bg-gray-900 text-white"
                  //     : "text-white hover:bg-gray-700 hover:text-white",
                  //   "block py-2 px-3 rounded-md text-base font-medium"
                  // )}
                >
                  {item.title}
                </Link>
              ))}
            </div>
            {/* Mobile */}
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center justify-between px-5 sm:px-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {/* Image */}
                    <img
                      className="h-10 w-10 rounded-full"
                      src={boss}
                      alt={isLogin?.firstName}
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {isLogin?.currentName}
                    </div>
                    {/* <div className="text-base font-medium text-white">
                      {isLogin?.email}
                    </div> */}
                  </div>
                </div>
                <NotificationMobile
                  showRedDot={showRedDot}
                  listNotification={listNotification}
                  totalResult={totalResult}
                  totalUnRead={totalUnRead}
                />
              </div>
              <div className="px-2 mt-3 space-y-1 sm:px-3">
                {userNavigation.map((item, index) => (
                  <Link
                    to={item.href}
                    key={index}
                    className="block px-3 py-2 rounded-md text-sm font-medium text-white hover:text-white hover:bg-gray-700"
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
});
