import React, { useState } from "react";
//icons
import { FaPencilAlt } from "react-icons/fa";
import { HiSearch } from "react-icons/hi";
//hooks
import { useAppDispatch } from "../../../hooks/reduxHooks";
//types
import {
  ContainerApiParams,
  ContainerApiProps,
  UserApiProps,
} from "../../../types/apiType";
//components
import ToastSuccess from "../../../components/Toast/success";
import ToastError from "../../../components/Toast/error";
import { Reload } from "../../../components/Reload";
import {
  dataNumberOfLimit,
  dataSearchContStatus,
} from "../../../constants/dropdown";
//redux
import { useSelector } from "react-redux";
import {
  getAllAction,
  updateManyAction,
} from "../../../redux/slices/Container/containerSlice";
import {
  clearSelectionAction,
  selectSelects,
} from "../../../redux/slices/SelectCheckbox/SelectCheckboxSlice";
//libraries
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import { dateHelper, dateTimeSearchHelper } from "../../../utils/DateHelper";
import { Socket } from "socket.io-client";
import useSocketEventHandler from "../../../hooks/useSocketHandler";
import { getAllNotificationAction } from "../../../redux/slices/User/UserSlice";
import { paramsNotification } from "../../../utils/paramsHelper";
import Swal from "sweetalert2";
import { updateMessageManyContainersNotification } from "../../../constants/messageNotification";

interface SearchProps {
  handleSearch: (
    // keySearch: string,
    // contStatus: number,
    // numberOfLimit: number,
    startDate: string,
    endDate: string
  ) => void;
  socket: Socket;
  userAuth?: UserApiProps;
}
export const Search = (props: SearchProps) => {
  const dispatch = useAppDispatch();
  const selectData = useSelector(selectSelects);
  const { selected } = selectData;
  const [keySearch, setKeySearch] = useState("");
  const [contStatus] = useState(5);
  const [numberOfLimit] = useState(5);
  const [getByDate, setGetByDate] = useState<Date>(new Date());
  const [dateTimeSearch, setDateTimeSearch] = useState(dateTimeSearchHelper());

  const { handleSearch, socket, userAuth } = props;

  const today = new Date();

  // Calculate the difference between today and the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diffDays = today.getDay() - 1; // Assuming Monday is the first day of the week

  // Calculate the date range to cover the current week from Monday to today
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - diffDays);

  // Use the calculated start of the week in useState
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfWeek,
    today,
  ]);
  const [startDate, endDate] = dateRange;

  const handleUpdate = (msg: any) => {
    dispatch(getAllNotificationAction(paramsNotification));
  };

  useSocketEventHandler(socket, "update-receive", handleUpdate);

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contStatus,
      numberOfLimit,
    },
    onSubmit: () => {},
  });

  // search event
  const handleClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (endDate && startDate !== null) {
      handleSearch(
        // keySearch.trim(),
        // formik.values.contStatus,
        // formik.values.numberOfLimit,
        dateHelper(startDate),
        dateHelper(endDate)
      );
    } else {
      handleSearch(
        // keySearch.trim(),
        // formik.values.contStatus,
        // formik.values.numberOfLimit,
        dateHelper(today),
        dateHelper(today)
      );
    }
  };

  const handleOpenFormUpdateMany = async () => {
    Swal.fire({
      title: "Bạn có chắc là muốn xác nhận thanh toán đủ nhiều container?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(clearSelectionAction());
        const dataUpdateMany: ContainerApiProps = {
          containerIds: selected,
          contStatus: 2,
          isReceipt: true,
        };
        const action = await dispatch(updateManyAction(dataUpdateMany));

        if (updateManyAction.fulfilled.match(action)) {
          socket.emit("update-data", {
            room: "container",
            updateData: updateMessageManyContainersNotification(
              userAuth?.currentName,
              action.payload.listId,
              "thanh toán đủ"
            ),
          });
          dispatch(getAllNotificationAction(paramsNotification));
          ToastSuccess(action.payload.message);
        } else {
          ToastError(action.payload as string, "Lỗi hệ thống");
        }
      } else if (result.isDenied) {
        Swal.fire("Khách vẫn chưa thanh toán!", "", "info");
      }
    });
  };

  return (
    <div className="h-28 flex items-center w-full bg-slate-100 mt-3 overflow-hidden overflow-x-auto">
      {/* <select
        id="ward"
        className="bg-white mx-4 block w-55 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        value={formik.values.numberOfLimit}
        onChange={formik.handleChange("numberOfLimit")}
        onBlur={formik.handleBlur("numberOfLimit")}
      >
        <option value={5}>{`5`}</option>
        {dataNumberOfLimit?.map((item, index) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <form className="">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Tìm
        </label>
        <div className="relative w-64 flex-shrink-0">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-64 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline "
            placeholder="Nhập từ khóa ..."
            required
            onChange={(e) => setKeySearch(e.target.value)}
            value={keySearch}
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
            onClick={handleClickSearch}
          >
            Tìm
          </button>
        </div>
      </form>
      <select
        id="ward"
        className="bg-white ml-4 block w-55 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        value={formik.values.contStatus}
        onChange={formik.handleChange("contStatus")}
        onBlur={formik.handleBlur("contStatus")}
      >
        <option value="5">{`-- Chọn trạng thái container --`}</option>
        {dataSearchContStatus?.map((item, index) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select> */}
      <div className="flex">
        <DatePicker
          dateFormat="dd-MM-yyyy"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          // isClearable={true}
          className=" bg-white ml-4 block w-52 p-4 text-sm text-gray-500 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        />
        <button
          type="submit"
          className="ml-4 text-white bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 "
          onClick={handleClickSearch}
        >
          Tìm
        </button>
      </div>
      {selected.length > 1 && (
        <>
          <div
            onClick={() => handleOpenFormUpdateMany()}
            className="inline-flex flex-shrink-0 justify-center px-4 py-4 mx-4 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            <FaPencilAlt
              className="-ml-1 mr-2 h-5 w-5 text-white"
              aria-hidden="true"
            />
            <span className="text-white">Thanh toán đủ container đã chọn</span>
          </div>
        </>
      )}

      {/* <Reload /> */}
    </div>
  );
};
