import {
  AnalyticParams,
  InvoiceApiParams,
  InvoiceApiProps,
} from "../types/apiType";
import axiosClient from "./axiosClient";

const module = "invoice";

const invoicesApi = {
  create: (body: InvoiceApiProps) => {
    const url = `/${module}/`;
    return axiosClient.post(url, body);
  },
  update: (id: string | undefined, body: InvoiceApiProps) => {
    const url = `/${module}/${id}`;
    return axiosClient.put(url, body);
  },
  delete: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.delete(url);
  },
  getByID: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.get(url);
  },
  getAll: (params: InvoiceApiParams) => {
    const url = `/${module}/search`;
    return axiosClient.get(url, { params });
  },
  getAllWithoutLimit: (params: AnalyticParams) => {
    const url = `/${module}/getAll`;
    return axiosClient.get(url, { params });
  },
};

export default invoicesApi;
