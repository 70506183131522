import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
//libraries
import { Navbar } from "./components/Navigation/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
//layout
import Login from "./pages/Login";
//constants
import * as ROUTES from "./constants/routes";
//redux
import {
  getAllNotificationAction,
  selectUser,
} from "./redux/slices/User/UserSlice";
import AdminProtectRoute from "./utils/ProtectRoutes/AdminProtectRoute";
import { UserList } from "./pages/Users/UserList";
import OutletProtectRoute from "./utils/ProtectRoutes/OutletProtectRoute";
import PartnerManagement from "./pages/PartnerManagement";
import { Supplier } from "./pages/PartnerManagement/Supplier";
import { ServiceSupplier } from "./pages/PartnerManagement/ServiceSupplier";
import Transport from "./pages/PartnerManagement/Transport";
import { ClientAdmin } from "./pages/PartnerManagement/ClientAdmin";
import { ClientUser } from "./pages/UserPages/ClientUser";
import PrivateProtectRoute from "./utils/ProtectRoutes/PrivateProtectRoute";
import { ProfileUser } from "./pages/Profile";
import { Invoice } from "./pages/Invoice";
import io from "socket.io-client";
import InvoiceManagement from "./pages/Invoice/InvoiceManagement";
import InvoiceAnalyst from "./pages/Invoice/InvoiceAnalyst";
import ContainerManagement from "./pages/Container";
import { ListContainer } from "./pages/Container/ListContainer";
import ContainerAnalyst from "./pages/Container/ContainerAnalyst";
import { ContainersSold } from "./pages/Container/ContainersSold";
import OutletProtectUserRoute from "./utils/ProtectRoutes/OutletProtectUserRoute";
import SellerContainer from "./pages/SellerContainer";
import { ListUserContainer } from "./pages/SellerContainer/ListUserContainer";
import { ContainersUserSold } from "./pages/SellerContainer/ContainersUserSold";
import ContainerAnalytic from "./pages/SellerContainer/ContainerAnalyst";
import { CostManagement } from "./pages/SellerContainer/CostManagement";
import { ContainerConta } from "./pages/Depot/ContainerConta";
import Depot from "./pages/Depot";
import OutletProtectDepotRoute from "./utils/ProtectRoutes/OutletProtectDepotRoute";
import { GateIn } from "./pages/Depot/GateIn";
import { GateOut } from "./pages/Depot/GateOut";
import RevenueDepot from "./pages/Depot/RevenueDepot";
import { QueryContainer } from "./pages/QueryContainer";
import OutletProtectAccountantRoute from "./utils/ProtectRoutes/OutletProtectAccountantRoute";
import { MNR } from "./pages/Depot/MNR";
import OutletProtectMNRRoute from "./utils/ProtectRoutes/OutletProtectMNRRoute";
import { BrandOfUnit } from "./pages/PartnerManagement/BrandOfUnit";
import ContainerInConta from "./pages/Depot/ContainerInConta";
import ContainerInContaLocationB from "./pages/Depot/ContainerInContaLocationB";
import NotFoundPage from "./components/NotFoundPage";
import { BookingSale } from "./pages/BookingSale/Admin";
import { BookingSaleByUser } from "./pages/BookingSale/User";
const socket = io(process.env.REACT_APP_URL!);
function App() {
  const user = useSelector(selectUser);
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route
            path={ROUTES.USERS_MANAGEMENT}
            element={
              <AdminProtectRoute user={user}>
                <UserList socket={socket} />
              </AdminProtectRoute>
            }
          />
          <Route
            path={ROUTES.QUERY_CONT}
            element={
              <PrivateProtectRoute user={user}>
                <QueryContainer socket={socket} />
              </PrivateProtectRoute>
            }
          />

          <Route element={<OutletProtectRoute user={user} />}>
            <Route
              path={ROUTES.INVOICE_MANAGEMENT}
              element={<InvoiceManagement />}
            >
              <Route
                path={ROUTES.INVOICE}
                element={<Invoice socket={socket} />}
              />
              <Route
                path={ROUTES.INVOICE_ANALYTIC}
                element={<InvoiceAnalyst />}
              />
              <Route
                path={ROUTES.BOOKING_SALE}
                element={<BookingSale socket={socket} />}
              />
              <Route
                path={ROUTES.BOOKING_SALE_USER}
                element={<BookingSaleByUser socket={socket} />}
              />
            </Route>
          </Route>

          {/* Admin routes */}
          <Route element={<OutletProtectRoute user={user} />}>
            <Route
              path={ROUTES.CONTAINER_MANAGEMENT}
              element={<ContainerManagement />}
            >
              <Route
                path={ROUTES.CONTAINER}
                element={<ListContainer socket={socket} />}
              />
              <Route
                path={ROUTES.CONTAINER_SOLD}
                element={<ContainersSold socket={socket} />}
              />
              <Route
                path={ROUTES.COST_CONTAINER}
                element={<CostManagement socket={socket} />}
              />
              <Route
                path={ROUTES.CONTAINER_ANALYTIC}
                element={<ContainerAnalyst socket={socket} />}
              />
              <Route
                path={ROUTES.CONTAINER_IN_CONTA}
                element={<ContainerInConta />}
              />
              <Route
                path={ROUTES.CONTAINER_IN_CONTA_B}
                element={<ContainerInContaLocationB />}
              />
            </Route>
          </Route>

          {/* User routes */}
          <Route element={<OutletProtectUserRoute user={user} />}>
            <Route
              path={ROUTES.USER_CONTAINER_MANAGEMENT}
              element={<SellerContainer />}
            >
              <Route
                path={ROUTES.USER_CONTAINER}
                element={<ListUserContainer socket={socket} />}
              />
              <Route
                path={ROUTES.USER_CONTAINER_SOLD}
                element={<ContainersUserSold socket={socket} />}
              />
              <Route
                path={ROUTES.USER_CONTAINER_ANALYTIC}
                element={<ContainerAnalytic socket={socket} />}
              />
              <Route
                path={ROUTES.CONTAINER_IN_CONTA}
                element={<ContainerInConta />}
              />
              <Route
                path={ROUTES.CONTAINER_IN_CONTA_B}
                element={<ContainerInContaLocationB />}
              />
            </Route>
          </Route>

          <Route element={<OutletProtectAccountantRoute user={user} />}>
            <Route
              path={ROUTES.USER_CONTAINER_MANAGEMENT}
              element={<SellerContainer />}
            >
              <Route
                path={ROUTES.COST_CONTAINER}
                element={<CostManagement socket={socket} />}
              />
            </Route>
          </Route>

          <Route element={<OutletProtectMNRRoute user={user} />}>
            <Route path={ROUTES.CONTA_DEPOT} element={<Depot />}>
              <Route
                path={ROUTES.CONTA_DEPOT_MNR}
                element={<MNR socket={socket} />}
              />
            </Route>
          </Route>

          {/* Routes depot */}
          <Route element={<OutletProtectDepotRoute user={user} />}>
            <Route path={ROUTES.CONTA_DEPOT} element={<Depot />}>
              <Route
                path={ROUTES.CONT_IN_CONTA}
                element={<ContainerConta socket={socket} />}
              />
              <Route
                path={ROUTES.GATE_IN}
                element={<GateIn socket={socket} />}
              />
              <Route
                path={ROUTES.GATE_OUT}
                element={<GateOut socket={socket} />}
              />
              <Route
                path={ROUTES.CONTA_DEPOT_REVENUE}
                element={<RevenueDepot socket={socket} />}
              />
              {/* <Route
                path={ROUTES.CONTAINER_IN_CONTA}
                element={<ContainerInConta />}
              /> */}
              {/* <Route path={ROUTES.CONTA_DEPOT_MNR} element={<MNR />} /> */}
            </Route>
          </Route>

          <Route
            path={ROUTES.PROFILE}
            element={
              <PrivateProtectRoute user={user}>
                <ProfileUser socket={socket} />
              </PrivateProtectRoute>
            }
          />
          <Route
            path={ROUTES.CLIENT_USER}
            element={
              <PrivateProtectRoute user={user}>
                <ClientUser socket={socket} />
              </PrivateProtectRoute>
            }
          />

          {/* Partner route */}
          <Route element={<OutletProtectRoute user={user} />}>
            <Route
              path={ROUTES.PARTNER_MANAGEMENT}
              element={<PartnerManagement />}
            >
              <Route
                path={ROUTES.SUPPLIER}
                element={<Supplier socket={socket} />}
              />
              <Route
                path={ROUTES.SERVICE_SUPPLIER}
                element={<ServiceSupplier socket={socket} />}
              />
              <Route
                path={ROUTES.BRAND_OF_UNIT}
                element={<BrandOfUnit socket={socket} />}
              />
              <Route path={ROUTES.TRANSPORT} element={<Transport />} />
              {user.userAuth?.isBoss ? (
                <Route
                  path={ROUTES.CLIENT}
                  element={<ClientAdmin socket={socket} />}
                />
              ) : (
                <Route path={ROUTES.CLIENT} element={<NotFoundPage />} />
              )}
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
