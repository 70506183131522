import { ApiParams, ServiceSupplierApiProps } from "../types/apiType";
import axiosClient from "./axiosClient";

const module = "service-supplier";

const serviceSuppliersApi = {
  create: (body: ServiceSupplierApiProps) => {
    const url = `/${module}/`;
    return axiosClient.post(url, body);
  },
  update: (id: string | undefined, body: ServiceSupplierApiProps) => {
    const url = `/${module}/${id}`;
    return axiosClient.put(url, body);
  },
  delete: (id: string) => {
    const url = `/${module}/delete/${id}`;
    return axiosClient.delete(url);
  },
  getByID: (id: string) => {
    const url = `/${module}/${id}`;
    return axiosClient.get(url);
  },
  getAll: (params: ApiParams) => {
    const url = `/${module}/search`;
    return axiosClient.get(url, { params });
  },
  getAllWithoutLimit: () => {
    const url = `/${module}/getAll`;
    return axiosClient.get(url);
  },
};

export default serviceSuppliersApi;
