//authentication
export const HOME = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const UPDATE_PASSWORD = "/update-password";
export const RESET_PASSWORD_TOKEN = "/password-reset-token";
export const RESET_PASSWORD = "/reset-password/:token";
export const VERIFIED_ACCOUNT = "/verify-account/:token";
//posts management
export const POSTS = "/posts";
export const POSTS_DETAIL = "/posts/:postId";
export const CREATE_POST = "/create-post";
//users management
export const USERS = "/users";
export const USERS_MANAGEMENT = "/users-management";
export const QUERY_CONT = "/query-container";
export const PROFILE = "/profile/:id";
//categories management
export const ADD_CATEGORY = "/add-category";
export const CATEGORY_LIST = "/category-list";

//Xomtro
export const XOMTRO = "/xomtro";
export const ROOM = "room";
export const UTILITY_MANAGEMENT = "utility-management";
export const TENANTS = "tenants";
export const RENTERS = "renters";
export const CONTRACTS = "contracts";
export const REVENUE = "revenue";

export const XOMTRO_ROOM = "/xomtro/room";
export const XOMTRO_UTILITY = "/xomtro/utility-management";

//invoice
export const INVOICE_MANAGEMENT = "/invoice-management";
export const INVOICE = "invoice";
export const INVOICE_ANALYTIC = "invoice-analytics";
export const BOOKING_SALE = "booking-sale";
export const BOOKING_SALE_USER = "booking-sale-user";
//container
export const CONTAINER_MANAGEMENT = "/container-management";
export const CONTAINER = "container";
export const CONTAINER_ANALYTIC = "container-analytics";
export const CONTAINER_SOLD = "container-sold";

//user-container
export const USER_CONTAINER_MANAGEMENT = "/user-container-management";
export const USER_CONTAINER = "user-container";
export const USER_CONTAINER_ANALYTIC = "user-container-analytics";
export const USER_CONTAINER_SOLD = "user-container-sold";
export const COST_CONTAINER = "cost-container";
//conta depot
export const CONTA_DEPOT = "/conta-depot";
export const CONT_IN_CONTA = "container-conta";
export const GATE_IN = "gate-in";
export const GATE_OUT = "gate-out";
export const CONTA_DEPOT_REVENUE = "depot-revenue";
export const CONTA_DEPOT_MNR = "MNR";
export const CONTAINER_IN_CONTA = "container-in-conta";
export const CONTAINER_IN_CONTA_B = "container-in-conta-b";

export const CLIENT_USER = "/client-user";

//partner management
export const PARTNER_MANAGEMENT = "/partner";
export const SUPPLIER = "supplier";
export const SERVICE_SUPPLIER = "service-supplier";
export const BRAND_OF_UNIT = "brand-of-unit";
export const TRANSPORT = "transport";
export const CLIENT = "client-admin";
